const geometry = (xm, ym, data, heights, widths, y1, y2, x1, x2, pos, lastVisibleColumn, lastVisibleRow) => {
  x2 = Math.min(x2, xm)
  y2 = Math.min(y2, ym)
  for (let k = y1; k < y2; k++) {
    const rowIndex = k
    const height = heights[rowIndex]
    pos.x = pos.startX
    for (let i = x1; i < x2; i++) {
      const columnIndex = i
      const width = widths[columnIndex]

      const key = `${data.id}:${columnIndex}-${rowIndex}`
      const node = window[key]
      if (node) {
        let nodeHeight = height
        let nodeWidth = width
        const spanx = Number(node.attributes.spanx.value)
        const spany = Number(node.attributes.spany.value)
        for (let x = 1; x < spanx; x++)
          nodeWidth += widths[columnIndex + x] - 1 || 0
        for (let y = 1; y < spany; y++)
          nodeHeight += heights[rowIndex + y] - 1 || 0

        node.style.top = pos.y + 'px'
        document.dir === "rtl"
          ? node.style.right = pos.x + 'px'
          : node.style.left = pos.x + 'px'
        node.style.width = nodeWidth + 'px'
        node.style.height = nodeHeight + 'px'

        node.style.maxWidth = null
        node.style.minWidth = null
        node.style.maxHeight = null
        node.style.minHeight = null
      }

      pos.x += width - 1
    }
    pos.y += height - 1
  }
}

const geometry_rh = (xm, ym, data, heights, widths, y1, y2, x1, x2, pos, lastVisibleColumn, lastVisibleRow) => {
  x2 = Math.min(x2, xm)
  y2 = Math.min(y2, ym)
  for (let k = y1; k < y2; k++) {
    const rowIndex = k
    const height = heights[rowIndex]
    pos.x = pos.startX
    for (let i = x1; i < x2; i++) {
      const columnIndex = i
      const width = widths[columnIndex]

      const key = `${data.id}:${columnIndex}-${rowIndex}`
      const node = window[key]
      if (node) {
        let nodeHeight = height
        let nodeWidth = width
        const spanx = Number(node.attributes.spanx.value)
        const spany = Number(node.attributes.spany.value)
        for (let x = 1; x < spanx; x++)
          nodeWidth += widths[columnIndex + x] - 1 || 0
        for (let y = 1; y < spany; y++)
          nodeHeight += heights[rowIndex + y] - 1 || 0

        node.style.top = pos.y + 'px'
        document.dir === "rtl"
          ? node.style.right = `calc(${pos.x + nodeWidth}px - 0.25em)`
          : node.style.left = `calc(${pos.x + nodeWidth}px - 0.25em)`
        node.style.width = '0.5em'
        node.style.height = nodeHeight + 'px'

        node.style.maxWidth = null
        node.style.minWidth = null
        node.style.maxHeight = null
        node.style.minHeight = null
      }

      pos.x += width - 1
    }
    pos.y += height - 1
  }
}

export { geometry, geometry_rh }