type ApiResponseInterface<SuccessResponse> = SuccessResponse | { error: true };

class ApiClass {
  private async baseCall(path: string, config: Record<any, any>) {
    try {
      return await fetch(process.env.REACT_APP_SURVEY_BACK + path, {
        headers: {
          "Content-Type": "application/json",
        },
        ...config,
      }).then((d) => d.json());
    } catch (error) {
      return { error: true };
    }
  }

  private async authRequest<SuccessResponse>(
    path: string,
    payload: Record<any, any>,
  ): Promise<ApiResponseInterface<SuccessResponse>> {
    try {
      const loginPath = process.env.REACT_APP_SURVEY_AUTH_API;
      if (!loginPath) throw new Error();
      return await fetch(loginPath + path, {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        method: "POST",
      }).then((d) => d.json());
    } catch (error) {
      return { error: true };
    }
  }

  async userInfo<SuccessResponse>(token: string): Promise<ApiResponseInterface<SuccessResponse>> {
    return await this.authRequest("/user", { token });
  }

  async login<SuccessResponse>(login: string, password: string): Promise<ApiResponseInterface<SuccessResponse>> {
    return await this.authRequest("/login", { login, password });
  }

  async get<SuccessResponse>(path: string): Promise<ApiResponseInterface<SuccessResponse>> {
    return await this.baseCall(path, { method: "GET" });
  }

  async post<SuccessResponse>(path: string, body: Record<any, any>): Promise<ApiResponseInterface<SuccessResponse>> {
    return await this.baseCall(path, {
      body: JSON.stringify(body),
      method: "POST",
    });
  }
}

export const api = new ApiClass();
