import React from "react";
import IconButton from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";

import { ReactComponent as IconEmpty } from "@mdi/svg/svg/select-all.svg";

import Menu from "@mui/material/Menu";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";

import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import withStyles from "@mui/styles/withStyles";
import { useTheme } from "@mui/material/styles";

import Tooltip from "@mui/material/Tooltip";

const StyledButton = withStyles(
  (theme) => ({
    root: {
      backgroundClip: "content-box",
      padding: "0 0.25em",
      width: "auto",
      height: "auto",
      color: "inherit",
      border: "none",
      minWidth: "auto",
      textTransform: "none",
      display: "flex",
      gap: "0.25em",

      "&.Mui-disabled": {
        padding: "0 0.25em",
        width: "auto",
        height: "auto",
        border: "none",
        minWidth: "auto",
        textTransform: "none",
      },
    },
  }),
  { withTheme: true },
)(IconButton);

const StyledButtonGroup = withStyles(
  (theme) => ({
    root: {
      minHeight: "2.5em",
      display: "flex",
      boxShadow: "none",
      alignItems: "center",
      padding: "0.25em",
      borderRight: "none",
      borderLeft: "none",
      borderRadius: 0,
      borderTop: 0,
      borderBottom: `1px solid ${theme.palette.divider}`,
      background: `${theme.palette.toolbar.background}`,
      overflow: "hidden",
    },
  }),
  { withTheme: true },
)(Paper);

const Icon = (color, icon, size, htmlColor, additionalStyle, props, viewBox) => {
  const style = {
    ...(additionalStyle || {}),
    width: size || 24,
    height: size || 24,
  };
  const prop = props || [];
  return htmlColor ? (
    <SvgIcon {...prop} htmlColor={htmlColor} component={icon} style={style} viewBox={viewBox} />
  ) : (
    <SvgIcon {...prop} color={color} component={icon} style={style} viewBox={viewBox} />
  );
};

const Icon512 = (color, icon, size, htmlColor, additionalStyle, props) => {
  const style = {
    ...(additionalStyle || {}),
    width: size || 32,
    height: size || 32,
  };
  const prop = props || [];
  return htmlColor ? (
    <SvgIcon {...prop} htmlColor={htmlColor} component={icon} style={style} viewBox={"0 0 512 512"} />
  ) : (
    <SvgIcon {...prop} color={color} component={icon} style={style} viewBox={"0 0 512 512"} />
  );
};

const ButtonNoBind = (key, color, icon, handler, enabled) => (
  <StyledButton key={key} fullWidth={false} onClick={handler && handler.bind(this, key)} disabled={enabled === false}>
    {Icon(enabled === false ? "disabled" : color, icon)}
  </StyledButton>
);

const Button = (color, icon, handler, size, htmlColor, text) => (
  <StyledButton fullWidth={false} onClick={handler && handler.bind(this)}>
    {icon && Icon(color, icon, size, htmlColor)}
    {text}
  </StyledButton>
);

const ToolButton = (icon, handler, enabled, tooltip, key, color, text, placement, disablePointerEvents) => (
  <TooltipR text={tooltip} disablePointerEvents={disablePointerEvents} key={key} placement={placement}>
    <span>
      <StyledButton
        color={"primary"}
        disabled={enabled === false || enabled == null}
        fullWidth={false}
        onClick={handler && handler.bind(this)}
      >
        {icon && Icon(enabled === false || enabled == null ? "disabled" : color || "action", icon, null, null, {})}
        {text}
      </StyledButton>
    </span>
  </TooltipR>
);

const ToolButtonIcon = (Icon, handler, enabled, tooltip, key, color, text, style) => (
  <TooltipR text={tooltip} key={key}>
    <span>
      <StyledButton
        style={style}
        color={"primary"}
        disabled={enabled === false || enabled == null}
        fullWidth={false}
        onClick={handler && handler.bind(this)}
      >
        {Icon}
        {text}
      </StyledButton>
    </span>
  </TooltipR>
);

const ToolButtonCustom = (component, handler, enabled, tooltip, key, color, text) => (
  <TooltipR text={tooltip} key={key}>
    <span>
      <StyledButton
        color={"primary"}
        disabled={enabled === false || enabled == null}
        fullWidth={false}
        onClick={handler && handler.bind(this)}
      >
        {component}
        {text}
      </StyledButton>
    </span>
  </TooltipR>
);

const ToolButtonMenu = (icon, enabled, tooltip, menuItems, theme, key) => (
  <PopupState variant="popover" popupId="demo-popup-menu" key={key}>
    {(popupState) => (
      <React.Fragment>
        <TooltipR text={tooltip}>
          <span>
            <StyledButton {...bindTrigger(popupState)} disabled={enabled === false} fullWidth={false}>
              {Icon(enabled === false || enabled == null ? "disabled" : "action", icon)}
            </StyledButton>
          </span>
        </TooltipR>
        {popupState.isOpen && (
          <Menu
            style={theme?.typography.text}
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            autoFocus={false}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {menuItems(popupState)}
          </Menu>
        )}
      </React.Fragment>
    )}
  </PopupState>
);

const ButtonSeparator = (key) => (
  <StyledButton key={key} disabled style={{ border: "none", flexGrow: 1 }}>
    {Icon(null, IconEmpty, null, "transparent")}
  </StyledButton>
);

const _tooltip = (component, tooltip) => (
  <Tooltip title={tooltip} arrow>
    {component}
  </Tooltip>
);

const Button2 = (key, color, icon, handler) => (
  <StyledButton key={key} fullWidth={false} onClick={handler}>
    {icon}
  </StyledButton>
);

const ButtonEmpty = (key, width) => (
  <StyledButton key={key} disabled style={{ border: "none", width: width || "1em" }}>
    {Icon(null, IconEmpty, null, "transparent")}
  </StyledButton>
);

const popover = (text, theme, disablePointerEvents) => {
  const style = {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeight,
    fontSize: theme.typography.fontSize,
    lineHeight: theme.typography.lineHeight,
    letterSpacing: theme.typography.letterSpacing,
    pointerEvents: disablePointerEvents ? "none" : "auto",
  };
  return (
    <Popover
      id="popover-basic"
      style={{
        zIndex: 2000,
        borderRadius: 0,
        border: `1px solid ${theme.palette.warning.main}`,
        ...style,
      }}
    >
      <Popover.Content style={{ padding: "0.25em" }}>{text}</Popover.Content>
    </Popover>
  );
};

const TooltipR = (props) => {
  const theme = useTheme();
  return !props.text ? (
    props.children
  ) : (
    <OverlayTrigger
      trigger={["hover", "hover"]}
      placement={props.placement || "bottom"}
      overlay={popover(props.text, theme, props.disablePointerEvents)}
    >
      {props.children}
    </OverlayTrigger>
  );
};

const CardContentStyle = (theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    padding: "1em",
    "&:last-child": {
      paddingBottom: "1em",
    },
  },
});
const CardContentStyled = withStyles(CardContentStyle, { withTheme: true })(CardContent);

export {
  CardContentStyled,
  ToolButton,
  Icon,
  Icon512,
  Button,
  Button2,
  ButtonNoBind,
  ButtonSeparator,
  StyledButtonGroup,
  StyledButton,
  ButtonEmpty,
  _tooltip,
  TooltipR,
  ToolButtonMenu,
  ToolButtonCustom,
  ToolButtonIcon,
};
