import React, { Component } from 'react'
import { Trans } from '@lingui/react';

import { Paper } from '@mui/material'
import { Divider } from '@mui/material'

import { withConfirmationDialog } from './../../../shared/ui/ConfirmationDialog'

import CircularProgress from '@mui/material/CircularProgress'
import SortableTree from './../../../shared/ui/tree'
import BitArray from './../../../shared/utils/BitArray'

import ItemDialog from './../../../components/itemDialog'
import { staticSelect } from './../../../components/itemDialog/schemas'

import { Icon, ToolButton, ToolButtonIcon, ToolButtonCustom, ButtonSeparator, StyledButtonGroup } from './../../../shared/ui/ToolBar'

import { ReactComponent as IconSelectAll } from '@mdi/svg/svg/select-all.svg'
import { ReactComponent as IconSelectNone } from '@mdi/svg/svg/select-off.svg'
import { ReactComponent as IconSelectInv } from '@mdi/svg/svg/select-compare.svg'
import { ReactComponent as IconCheck } from '@mdi/svg/svg/check.svg'
import { ReactComponent as IconCancel } from '@mdi/svg/svg/window-close.svg'

import { ReactComponent as IconSortAsc } from '@mdi/svg/svg/sort-reverse-variant.svg'
import { ReactComponent as IconSortDesc } from '@mdi/svg/svg/sort-variant.svg'
import { ReactComponent as IconSortRemove } from '@mdi/svg/svg/sort-variant-remove.svg'
import { ReactComponent as IconLock } from '@mdi/svg/svg/arrow-horizontal-lock.svg'
import { ReactComponent as IconFilterAxis } from '@mdi/svg/svg/image-filter-center-focus.svg'

import { ReactComponent as IconFilterRemove } from '@mdi/svg/svg/filter-remove-outline.svg'
import { ReactComponent as IconFindReplace } from '@mdi/svg/svg/find-replace.svg'

import { ReactComponent as IconCalendarDate } from '@mdi/svg/svg/calendar-blank.svg'
import { ReactComponent as IconEye } from '@mdi/svg/svg/eye-outline.svg'
import { ReactComponent as IconEyeOff } from '@mdi/svg/svg/eye-off-outline.svg'

import { withTheme } from '@mui/styles'
import styled from 'styled-components'

import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import Input from '@mui/material/Input'

const InputWrapper = withTheme(styled('div')`
  ${({ theme }) => `
  padding: ${theme.spacing(1, 1 / 3)};
  margin: 1px;
  border: 1px solid transparent;
  background-color: ${theme.palette.background.paper};
  border-radius: 0;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${theme.palette.divider};

  &:hover {
    border-color: ${theme.palette.primary.light};
  }

  & input {
    box-sizing: border-box;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    padding: 0;
    outline: 0;
  }
  `}
`)
  
class DimensionValuesTree extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isReplaceMode: false,
            isOk: false,
            isTags: !!props.isTags,
            tagsVisibility: true,
            loading: false,
            filter: '',
            multipleValues: false,

            current: props.current?.current || null,
            statuses: [{ id: null, name: "" }],
            treeData: [],
            selectedIds: [],
            visible: [],
            checkedData: [],
            images: props.images,
            levels: null,
            bitArray: null,
            textFilterRef: { current: null },
            textReplaceRef: { current: null },
            replace: null,
            replaceValue: { text: '', cs: false, useRegExp: false, mathWholeWord: false, replace: '' }
        }
        this.updateTreeData = this.updateTreeData.bind(this)
        this.api = this.props.api
        this.requestApi = this.props.requestApi;
        this.embedded = this.props.embedded === true

        this.props.onResult && ( this.props.onResult.current = () => {
            this.handleOk()
            return true
        })
    }

    updateTreeData(treeData) {
        this.setState({ treeData })
    }

    isValid = () => (this.api && (this.props.dim?.id || (this.props.facts && this.props.factsItem)))

    getNode(parent) {
        if (!this.isValid())
            return

        this.setState({ loading: true })

        if (this.state.isTags) {
          const bitArray = new BitArray(this.props.dim.properties.unique)
          this.props.treeData.forEach(data => data.selected && bitArray.toggle(data.id));
          const selectedIds = this.props.treeData.filter(data => data.selected).map(data => data.id);
          this.setState({ bitArray, selectedIds, multipleValues: true, treeData: this.props.treeData, loading: false })
          return;
        }

        if (this.props.facts) {
            const multipleValues = true
            const bitArray = new BitArray(this.props.facts.length)
            this.props.facts.forEach((node, ndx) => {
                bitArray.set(ndx, node.properties.visible)
            })
            const data = this.getFactTreeData('')
            this.setState({ bitArray, levels: 1, multipleValues, filter: '', treeData: data })
            this.setState({ loading: false })
            return
        }

        this.api.dimensionValues(this.props.dim.id, 0, 100, this.state.filter).then(res => {
            const data = this.getTreeData(res)
            const bitArray = new BitArray(this.props.dim.properties.unique)
            const v = this.props.dim.properties.filter || []
            if (v.length)
                bitArray.values = v
            if (this.props.dim.properties.inverse != true)
                bitArray.not()

            this.setState({ bitArray, levels: 1, multipleValues: !(this.props.dim.properties.multipleValues === false), filter: '', treeData: data })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    componentDidUpdate(prevProps) {
        if ((!prevProps.dim || this.props.dim === prevProps.dim) && (!prevProps.facts || this.props.facts === prevProps.facts))
            return

        if (this.state.isTags) return;

        if (this.props.facts) {
            const bitArray = new BitArray(this.props.facts.length)
            this.props.facts.forEach((node, ndx) => {
                bitArray.set(ndx, node.properties.visible)
            })
            this.setState({ bitArray })
            return
        }

        const bitArray = new BitArray(this.props.dim.properties.unique)
        bitArray.values = this.props.dim.properties.filter || []
        if (this.props.dim.properties.inverse != true)
            bitArray.not()

        this.setState({ bitArray })
    }

    getFactTreeData = (filter) => {
        const f = (filter && filter != '') ? filter.toLowerCase() : null
        return this.props.facts.reduce((result, node, ndx) => {
            const title = (node.properties.title || node.properties.name || '').toLowerCase()
            if (!f || title.includes(f))
                result.push({
                    icon: () => node.properties.icon,
                    name: () => title,
                    actual: () => node.properties.actual !== false,
                    iconType: node.type,
                    noChildren: true,
                    id: ndx
                })
            return result
        }, [])
    }

    getTreeData = (res) => {
        const data = res?.values?.map(i => (
            {
                name: () => i.value,
                icon: () => null,
                actual: () => true,
                noChildren: true,
                id: i.index
            }
        ))
        return data
    }

    componentDidMount() {
        this.getNode()
    }

    componentWillUnmount() {
    }

    handleCancel = () => {
        this.props.popupState.close()
    }

    toggleTagsVisibility = () => {
      this.props.toggleSelectedTagsVisibility?.();
      this.setState({ tagsVisibility: !this.state.tagsVisibility })
    }

    handleOk = (action) => {
        if (!this.isValid())
            return

        if (this.state.isTags) {
          this.props.popupState && this.props.popupState.close();
          this.props.onTagsChange?.(this.state.selectedIds);
          return;
        }

        if (this.state.isReplaceMode === true) {
            const v =Object.assign({}, this.state.textReplaceRef?.current || {})
            this.api.findAndReplace?.(this.props.dim.id, v)
            this.handleCancel()
            return
        }

        this.setState({ loading: true })

        if (this.props.facts) {
            const v = this.props.facts.reduce((v, node, ndx) => {
                const visible = this.state.bitArray.get(ndx)
                if (visible)
                    v.push(node.id)
                // for real-time result
                node.properties.visible = visible
                return v
            }, [])
            this.api.setFactsVisibility(this.props.factsItem.id, v).then(res => {
            }).finally(() => {
                this.setState({ loading: false })
                this.props.popupState && this.props.popupState.close()
                this.props.onApplyFilter && this.props.onApplyFilter()
            })
            return
        }

        const f = this.state.bitArray.copy()
        if (this.props.dim.properties.inverse != true)
            f.not()

        this.props.dim.properties.filter = f.values
        this.props.dim.properties.textFilter = Object.assign({}, this.state.textFilterRef?.current || {})
        if (action === "clear") this.props.additionalFilters?.onClearFilters?.(this.props.dim);

        if (this.embedded) {
            this.api.set(this.props.dim.id, this.props.dim.properties)
        } else {
            this.api.set(this.props.dim.id, this.props.dim.properties).then(res => {
            }).finally(() => {
                this.setState({ loading: false })
                this.props.popupState && this.props.popupState.close()
                this.props.onApplyFilter && this.props.onApplyFilter()
            })
        }
    }

    nodeClicked = (event, rowInfo) => {
        const { multipleValues } = this.state
        const nodeId = rowInfo.node.id;

        const bitArray = this.state.bitArray.copy()
        if (!multipleValues)
            bitArray.reset()
        bitArray.toggle(nodeId)
        this.state.selectedIds.find(id => id === nodeId)
          ? this.setState({ selectedIds: this.state.selectedIds.filter(id => id !== nodeId) })
          : this.state.selectedIds.push(nodeId);
        this.setState({ bitArray })
    }

    handleSelectAll = () => {
        const bitArray = this.state.bitArray.copy()
        if (this.props.treeData?.length > 0) bitArray.toggle(this.props.treeData[0].id);
        bitArray.fill(true)
        this.setState({ bitArray, selectedIds: this.state.treeData.map(data => data.id) })
    }

    handleSelectNone = () => {
        const bitArray = this.state.bitArray.copy()
        bitArray.fill(false)
        this.setState({ bitArray, selectedIds: [] })
    }

    handleInversion = () => {
        const bitArray = this.state.bitArray.copy()
        bitArray.not()
        const selectedIds = this.state.treeData.filter(data => !this.state.selectedIds.includes(data.id)).map(data => data.id);
        this.setState({ bitArray, selectedIds })
    }

    handleFilterClear = () => {
        this.handleFilter('')
    }

    handleFilterChange = (event) => {
        const value = event.target.value
        this.handleFilter(value)
    }

    handleRemoveTextFilter = () => {
        if (this.state.textFilterRef.current) {
            this.state.textFilterRef.current.text = ''
            this.state.textFilterRef.current.value = null
            this.state.textFilterRef.current.operator = 'ANY'
            this.handleOk("clear")
        }
    }

    handleFilter = (filter) => {
        if (!this.isValid())
            return

        if (this.props.facts) {
            const treeData = this.getFactTreeData(filter)
            this.setState({ treeData, filter })
            return
        }

        this.setState({ filter, loading: true })
        
        if (this.state.isTags) {
          const filteredData = this.props.treeData.filter(data => data.name().toLowerCase().includes(filter.toLowerCase()));
          this.setState({ treeData: filteredData, loading: false });
          return;
        }

        this.api.dimensionValues(this.props.dim.id, 0, 100, filter).then(res => {
            const treeData = this.getTreeData(res)
            this.setState({ treeData })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    DictionaryContext = () => {
        const { treeData, filter, multipleValues } = this.state
        return (<React.Fragment>
            <InputWrapper>
                <Input inputRef={input => input && input.focus()}
                    placeholder="Filter"
                    fullWidth={true}
                    disableUnderline={true}
                    autoFocus={true}
                    value={filter} onChange={this.handleFilterChange}
                    startAdornment={<SearchIcon color='primary' style={{ fontSize: '1.5em' }} />}
                    endAdornment={
                        (filter && filter !== '') ?
                            ToolButtonCustom(<CloseIcon style={{ fontSize: '1.5em' }} color={'error'} />, this.handleFilterClear, true, 'Clear', 1)
                            : null
                    }
                />
            </InputWrapper>
            {this.state.loading ?
                (
                    <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress color='primary' size={'32px'} />
                    </div>
                ) :
                (<div style={{ margin: '0.5em', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <SortableTree
                    rowHeight={21}
                    treeData={treeData}
                    onChange={this.updateTreeData}
                    getNodeKey={({ node }) => node.id}
                    generateNodeProps={rowInfo => (
                        {
                            onClick: (event) => this.nodeClicked(event, rowInfo),
                            selected: this.state.bitArray.get(rowInfo.node.id),
                            isShowSelectedIcon: true,
                            isShowExpandIcon: false
                        }
                    )}
                />
                </div>
                )
            }
        </React.Fragment>
        )
    }

    TextReplaceContext = () => {
        const { treeData, filter, multipleValues } = this.state

        const schema = {
            props: [
                {
                    value: 'text',
                    type: 'text',
                    label: 'Найти',
                    autoFocus: true,
                    data: { onEnter: this.handleOk.bind(this) },
                },
                {
                    value: 'replace',
                    type: 'text',
                    label: 'Заменить',
                    data: { onEnter: this.handleOk.bind(this) },
                },
                {
                    value: 'useRegExp',
                    type: 'switch',
                    label: 'Регулярное выражение',
                },
                {
                    value: 'cs',
                    type: 'switch',
                    label: 'С учетом регистра',
                    visible: (value, schema_query) => {
                        return value.useRegExp !== true
                    },
                },
                {
                    value: 'mathWholeWord',
                    type: 'switch',
                    label: 'Слово целиком',
                    visible: (value, schema_query) => {
                        return value.useRegExp !== true
                    },
                },
            ],
        }

        return (
            <ItemDialog schema={schema} prop={this.state.replaceValue} currentValue={this.state.textReplaceRef} isEmbeded={true} type={''} onOk={null} onClose={null} onChanged={(value) => this.setState({ replace: value.text }) } />
        )
    }

    TextContext = () => {
        const { isReplaceMode } = this.state

        const value = this.props.dim?.properties?.textFilter ? { ...this.props.dim?.properties?.textFilter } : { text: '', cs: false, useRegExp: false, mathWholeWord: false, operator: 'ANY' }
        !value.operator && (value.operator = 'ANY')

        const additionalFilters = this.props.additionalFilters?.filters?.(this.props.dim, value);

        const schema = {
            data: (props, selfOrParentId, value, set_schema_query) => {
                const r = {}
                r.list = [
                  { current: { id: 'ANY', properties: { title: 'Any', allowText: false } } },
                  { current: { id: '=', properties: { title: 'Equal' } } },
                  { current: { id: '<>', properties: { title: 'Not equal' } } },
                  { current: { id: '>', properties: { title: 'More' } } },
                  { current: { id: '>=', properties: { title: 'More or Equal' } } },
                  { current: { id: '<', properties: { title: 'Less' } } },
                  { current: { id: '<=', properties: { title: 'Less or Equal' } } },
                  { current: { id: 'LIKE %', properties: { title: 'Starts with' } } },
                  { current: { id: 'NOT LIKE %', properties: { title: 'Not starts with' } } },
                  { current: { id: '% LIKE', properties: { title: 'Ends with' } } },
                  { current: { id: 'NOT % LIKE', properties: { title: 'Not ends with' } } },
                  { current: { id: 'LIKE', properties: { title: 'Contains' } } },
                  { current: { id: 'NOT LIKE', properties: { title: 'Not contains' } } },
                  { current: { id: 'IS NULL', properties: { title: 'Empty', allowText: false } } },
                  { current: { id: 'IS NOT NULL', properties: { title: 'Not Empty', allowText: false } } },
                ]
                set_schema_query(r)
            },            
            props: [
                { ...staticSelect("operator", <Trans id="table.condition" />, 'list'), data: { autocompleteProps: { disableClearable: true } } }, // readOnly: true
                {
                    value: 'text',
                    type: 'text',
                    label: isReplaceMode ? <Trans id="table.find" /> : <Trans id="table.text" />,
                    autoFocus: true,
                    data: { onEnter: this.handleOk.bind(this), disableClean: true },
                    visible: (value, schema_query) => {
                        const operator = schema_query.list.find(i => i.current.id === value.operator)
                        return operator && operator.current.properties.allowText !== false
                    },
                },
                {
                  value: 'cs',
                  type: 'boolean',
                  label: <Trans id="table.case_sensitive" />,
                  visible: (value, schema_query) => {
                      const operator = schema_query.list.find(i => i.current.id === value.operator)
                      return operator && operator.current.properties.allowText !== false && value.useRegExp !== true
                  },
                  data: { disabled: false }
                },
                ...(Array.isArray(additionalFilters) ? additionalFilters : []),
            ],
        }

        return (
            <ItemDialog schema={schema} prop={{ current: { properties: value }}} currentValue={this.state.textFilterRef} isEmbeded={true} type={''} onOk={null} onClose={null} />
        )
    }

    BooleanContext = () => {
        const value = this.props.dim?.properties?.textFilter ? { ...this.props.dim?.properties?.textFilter } : {  }
        const additionalFilters = this.props.additionalFilters?.filters?.(this.props.dim, value);
        const select = [{id: true, name: "True"}, {id: false, name: "False"}]

        const schema = {
            props: [
                {
                    value: "value",
                    label: "Boolean",
                    type: "fSelectCheckbox",
                    data: { initialValue: !this.props.dim?.properties?.textFilter?.value, select }
                },
                ...(Array.isArray(additionalFilters) ? additionalFilters : []),
            ],
        }

        return (
            <ItemDialog schema={schema} prop={{ current: { properties: value }}} currentValue={this.state.textFilterRef} isEmbeded={true} type={''} onOk={null} onClose={null} />
        )
    }

    DateTimeContext = () => {
        const { treeData, filter, multipleValues } = this.state
        const type = this.props.dim.properties.type === 'DateTime' ? 'DateTime' : 'Date'
        const schema = {
            data: (props, selfOrParentId, value, set_schema_query) => {
                const r = {}
                r.list = [
                  { current: { id: 'ANY', properties: { title: 'Any', allowText1: false, allowText2: false } } },
                  { current: { id: '=', properties: { title: 'Equal', allowText2: false } } },
                  { current: { id: '<>', properties: { title: 'Not equal' , allowText2: false } } },
                  { current: { id: '>', properties: { title: 'More', allowText2: false } } },
                  { current: { id: '>=', properties: { title: 'More or Equal', allowText2: false } } },
                  { current: { id: '<', properties: { title: 'Less', allowText2: false } } },
                  { current: { id: '<=', properties: { title: 'Less or Equal', allowText2: false } } },
                  { current: { id: 'BETWEEN', properties: { title: 'Between' } } },
                  { current: { id: 'NOT BETWEEN', properties: { title: 'Not between' } } },
                  { current: { id: 'IS NULL', properties: { title: 'Not contains', allowText1: false, allowText2: false } } },
                  { current: { id: 'IS NOT NULL', properties: { title: 'Not Empty', allowText1: false, allowText2: false } } },
                ]
                set_schema_query(r)
            },            
            props: [
                { ...staticSelect("operator", <Trans id="table.condition" />, 'list'), data: { autocompleteProps: { disableClearable: true } } }, // readOnly: true
                {
                    value: 'start',
                    type: "Date",
                    label: (value, schema_query) => {
                        const operator = schema_query.list.find(i => i.current.id === value.operator)
                        const m = {
                            '=': 'Дата',
                            '<>': 'Дата',
                            '>': 'После',
                            '>=': 'После или равно',
                            '<': 'До',
                            '<=': 'До или равно',
                            'BETWEEN': 'После',
                            'NOT BETWEEN': 'До',
                        }
                        return m[operator?.current?.id]
                    },
                    autoFocus: true,
                    data: { onEnter: this.handleOk.bind(this) },
                    visible: (value, schema_query) => {
                        const operator = schema_query.list.find(i => i.current.id === value.operator)
                        return operator && (operator.current.properties.allowText1 !== false)
                    },
                },
                {
                    value: 'end',
                    type: "Date",
                    label: (value, schema_query) => {
                        const operator = schema_query.list.find(i => i.current.id === value.operator)
                        const m = {
                            'BETWEEN': 'До',
                            'NOT BETWEEN': 'После',
                        }
                        return m[operator?.current?.id]
                    },
                    data: { onEnter: this.handleOk.bind(this) },
                    visible: (value, schema_query) => {
                        const operator = schema_query.list.find(i => i.current.id === value.operator)
                        return operator && (operator.current.properties.allowText2 !== false)
                    },
                },
            ],
        }
        const value = this.props.dim?.properties?.textFilter || { start: null, end: null, operator: 'ANY' }
        !value.operator && (value.operator = 'ANY')

        return (
            <ItemDialog schema={schema} prop={{ current: { properties: value }}} currentValue={this.state.textFilterRef} isEmbeded={true} type={''} onOk={null} onClose={null} />
        )
    }

    render() {
        const { treeData, filter, multipleValues, isReplaceMode } = this.state
        const sortOrder = this.props.dim.properties.sortOrder
        const isFixed = this.props.dim.properties.axis === 'fixed'
        const isFilterAxis = this.props.dim.properties.axis === 'filter'

        const isText = this.props.dim.properties.type === 'Memo'
        const isBoolean = this.props.dim.properties.type === 'Boolean'
        const isDateTime = this.props.dim.properties.type === 'DateTime'
        const isDate = this.props.dim.properties.type === 'Date'
        const isDictionary = this.props.dim.properties.type === 'Dictionary'

        const sortAction = (id, icon, text) => ToolButtonIcon(Icon(sortOrder === id ? 'primary' : 'action', icon), () => this.props.onChangeOrder?.(id), true, text, id)

        const dictionaryButtons = () => (
            <React.Fragment>
                {this.state.isTags && ToolButton(this.state.tagsVisibility ? IconEyeOff : IconEye, this.toggleTagsVisibility, true, <Trans id="table.toggle_tags_visibility" />, 0)}
                {multipleValues && ToolButton(IconSelectAll, this.handleSelectAll, true, <Trans id="table.select_all" />, 1)}
                {multipleValues && ToolButton(IconSelectInv, this.handleInversion, true, <Trans id="table.select_alternative" />, 2)}
                {multipleValues && ToolButton(IconSelectNone, this.handleSelectNone, true, <Trans id="table.clear_selection" />, 3)}
            </React.Fragment>
        )
        const textButtons = () => (
            <React.Fragment>
                    {!this.state.isReplaceMode && ToolButton(IconFilterRemove, this.handleRemoveTextFilter, true, <Trans id="table.reset_filters" />, 4)}
            </React.Fragment>
        )

        const buttons = () => {
            return isDictionary ? dictionaryButtons() : textButtons()
        }

        const dateTimeTypeButtons = () => ([
            ToolButtonIcon(Icon('action', IconCalendarDate), () => {}, true, 'Только даты', 1),
            <Divider key='-1' orientation={'vertical'} flexItem={true} style={{ margin: '0 0.25em' }} />
        ])

        const sortToolbar = () => ([
            sortAction('ASC', IconSortAsc, <Trans id="table.ascending_sorting" />),
            sortAction('DESC', IconSortDesc, <Trans id="table.descending_sorting" />),
            sortAction('none', IconSortRemove, <Trans id="table.without_sorting" />),
            <Divider key='-2' orientation={'vertical'} flexItem={true} style={{ margin: '0 0.25em' }} />
        ])

        const topBar = () => ([
            <div key='sort' style={{ display: 'flex', padding: '0.25em' }}>
                {!this.props.disableSearchAndReplaceButton && isText ?  ToolButtonIcon(Icon(isReplaceMode ? 'primary' : 'action', IconFindReplace), () => this.setState({ isReplaceMode: !isReplaceMode }), true, <Trans id="table.find_and_replace" />, 'findReplace') : null}
                {!this.props.disableSearchAndReplaceButton && isText ?  <Divider key='-1' orientation={'vertical'} flexItem={true} style={{ margin: '0 0.25em' }} /> : null}

                {!isFilterAxis && sortToolbar()}
                {isDateTime && dateTimeTypeButtons()}

                {ToolButtonIcon(Icon(isFixed ? 'primary' : 'action', IconLock), () => this.props.onChangeFixed?.(!isFixed), true, isFixed ? <Trans id="table.move_in_data" /> : <Trans id="table.froze" />, 'froze')}
                {!this.props.disableMoveFiltersButton && ToolButtonIcon(Icon(isFilterAxis ? 'primary' : 'action', IconFilterAxis), () => this.props.onChangeFilterAxis?.(!isFilterAxis), true, isFilterAxis ? <Trans id="table.move_in_data" /> : <Trans id="table.move_to_filter" />, 'moveToFilter')}
            </div>,
            <Divider key='-1' orientation={'horizontal'} style={{ margin: '0.25em 0' }} />
        ])

        const bottomBar = () => (
            <StyledButtonGroup style={{ borderTopWidth: 1 }}>
                {buttons()}
                {ButtonSeparator(-1)}
                {!this.embedded && ToolButton(IconCancel, this.handleCancel, true, <Trans id="common.cancel" />, 4, 'error')}
                {!this.embedded && ToolButton(IconCheck, this.handleOk, (isReplaceMode ? this.state.replace?.length > 0 : true), <Trans id="common.apply" />, 5, 'primary')}
            </StyledButtonGroup>
        )

        return (
            <Paper square elevation={this.embedded ? 0 : 1} style={{ minWidth: '200px', flexGrow: 1, minHeight: isDictionary ? '400px' : null, height: isDictionary ? '400px' : null, display: 'flex', flexDirection: 'column' }}>
                {!this.embedded && !this.props.hideTopBar && topBar()}
                {isDictionary && this.DictionaryContext()}
                {isText && (isReplaceMode ? this.TextReplaceContext() : this.TextContext())}
                {isBoolean && this.BooleanContext()}
                {(isDateTime || isDate) && this.DateTimeContext()}
                {bottomBar()}
            </Paper>
        )
    }
}

export default withTheme(withConfirmationDialog(DimensionValuesTree))
