import React from "react";
import { Trans } from "@lingui/react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

import { Button, CircularProgress, Dialog, DialogTitle, Tooltip, Typography } from "@mui/material";

import QustionGroupTable from "newComponents/QuestionGroup";
import SingleQuestion from "newComponents/SingleQuestion";
import FileList from "newComponents/FileList";

import { QuizStorage } from "storages/QuizStorage";
import { GlobalStorage } from "storages/GlobalStorage";

import { localStorageManager } from "utils/localStorageManager";

const CAN_QUIT_FROM_QUIZ = !!process.env.REACT_APP_SURVEY_RESET;

function Quiz() {
  const [detailsDialogVisibility, setDetailsDialogVisibility] = React.useState(false);

  const completeText = React.useMemo(
    () =>
      process.env["REACT_APP_SURVEY_COMPLETE_QUIZ_MESSAGE_" + GlobalStorage.locale.toUpperCase()] ||
      process.env.REACT_APP_SURVEY_COMPLETE_QUIZ_MESSAGE ||
      "Спасибо за участие в опросе!",
    [],
  );

  const handleNextStep = React.useCallback(async () => {
    if (QuizStorage.quizStep !== QuizStorage.questions.length - 1) {
      QuizStorage.nextStep();
      localStorageManager.setQuizStep(QuizStorage.quizStep);
      localStorageManager.setAnswers(QuizStorage.answers);
      return;
    }
    await QuizStorage.completeQuiz();
  }, []);

  const handleBackButtonClick = React.useCallback(() => {
    QuizStorage.prevStep();
    localStorageManager.setQuizStep(QuizStorage.quizStep);
  }, []);

  const handleClearQuizButtonClick = React.useCallback(() => {
    QuizStorage.clearQuiz();
    localStorageManager.clearQuizData();
  }, []);

  const haveDetails = !QuizStorage.currentQuestion.isQuestionGroup && !!QuizStorage.currentQuestion.question.details;
  const image = QuizStorage.currentQuestion.isQuestionGroup
    ? QuizStorage.currentQuestion.questions[0].image
    : QuizStorage.currentQuestion.question.image;

  const files = QuizStorage.currentQuestion.isQuestionGroup
    ? QuizStorage.currentQuestion.questions[0].files
    : QuizStorage.currentQuestion.question.files;

  return (
    <Container largeWidth={QuizStorage.currentQuestion.isQuestionGroup}>
      <StepText variant="overline" display="block">
        <Trans id="quiz.quiz_number" /> {QuizStorage.quizStep + 1}
      </StepText>

      <TitleContainer>
        {haveDetails && (
          <Button variant="contained" color="primary" size="small" onClick={() => setDetailsDialogVisibility(true)}>
            ?
          </Button>
        )}
        {QuizStorage.currentQuestion.tooltip ? (
          <Tooltip title={QuizStorage.currentQuestion.tooltip}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: QuizStorage.currentQuestion.caption,
              }}
              variant="h6"
              style={{ fontWeight: 400 }}
            />
          </Tooltip>
        ) : (
          <Typography
            dangerouslySetInnerHTML={{
              __html: QuizStorage.currentQuestion.caption,
            }}
            variant="h6"
            style={{ fontWeight: 400 }}
          />
        )}
      </TitleContainer>

      {QuizStorage.currentQuestion.description && (
        <StepText
          variant="overline"
          display="block"
          canClick={haveDetails}
          onClick={() => setDetailsDialogVisibility(haveDetails)}
        >
          {QuizStorage.currentQuestion.description}
        </StepText>
      )}

      {image && <ImageContainer src={image} alt="" />}
      {files && <FileList files={files} />}

      <div style={{ marginTop: 20 }} />
      {QuizStorage.currentQuestion.isQuestionGroup ? <QustionGroupTable /> : <SingleQuestion />}

      <ButtonsContent>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleBackButtonClick}
          disabled={QuizStorage.quizStep === 0 || QuizStorage.loading}
        >
          <Trans id="buttons.back" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleNextStep}
          disabled={!QuizStorage.isAnswersValid || QuizStorage.loading}
        >
          {QuizStorage.quizStep === QuizStorage.questions.length - 1 || QuizStorage.isLastQuestion ? (
            <Trans id="buttons.submit" />
          ) : (
            <Trans id="buttons.next" />
          )}
          {QuizStorage.loading && <Loader size={16} />}
        </Button>
      </ButtonsContent>

      {CAN_QUIT_FROM_QUIZ && (
        <div style={{ display: "flex", margin: "10px 0" }}>
          <QuitButton
            size="small"
            color="primary"
            onClick={handleClearQuizButtonClick}
            disabled={QuizStorage.loading}
            style={{ textTransform: "unset" }}
          >
            <Trans id="quiz.cancel_walkthrough" />
          </QuitButton>
        </div>
      )}

      <Dialog open={detailsDialogVisibility} onClose={() => setDetailsDialogVisibility(false)}>
        <DetailsContainer
          dangerouslySetInnerHTML={{
            __html: !QuizStorage.currentQuestion.isQuestionGroup ? QuizStorage.currentQuestion.question.details! : "",
          }}
        />
      </Dialog>

      <Dialog open={QuizStorage.completeDialogVisibility} onClose={QuizStorage.clearQuiz}>
        <DialogTitle>{completeText}</DialogTitle>
        <div style={{ display: "flex", justifyContent: "center", paddingBottom: 10 }}>
          <Button variant="contained" color="primary" size="small" onClick={QuizStorage.clearQuiz}>
            <Trans id="buttons.go_home" />
          </Button>
        </div>
      </Dialog>
    </Container>
  );
}

const StepText = styled(Typography)<{ canClick?: boolean }>`
  color: #aaa;
  margin-top: 8px;
  margin-bottom: 4px;
  cursor: ${({ canClick }) => (canClick ? "pointer" : "auto")};
`;

const Container = styled("div")<{ largeWidth: boolean }>`
  max-width: ${({ largeWidth }) => (largeWidth ? "1000px" : "800px")};
  margin: auto;
  padding: 10px;
  position: relative;
  width: 100%;
`;

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    min-width: 32px;
    width: 32px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const DetailsContainer = styled("div")`
  padding: 10px;
`;

const ButtonsContent = styled("div")`
  margin: 10px auto 0;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  padding: 10px;
  background: white;
  bottom: 0;
  border-radius: 4px 4px 0 0;
  z-index: 2;
`;

const QuitButton = styled(Button)`
  text-transform: lowercase;
  margin-bottom: 10px;
`;

const Loader = styled(CircularProgress)`
  margin-left: 10px;
`;

const ImageContainer = styled("img")`
  margin-top: 12px;
  max-width: 100%;
`;

export default observer(Quiz);
