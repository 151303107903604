import React from 'react';
import { InputAdornment } from '@mui/material';
import { capitalize } from 'lodash';

import { ReactComponent as AllowIcon } from '@mdi/svg/svg/check.svg';
import { ReactComponent as CloseIcon } from '@mdi/svg/svg/close.svg';
import { Icon, ToolButton } from 'shared/ui/ToolBar';
import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles'

import moment from 'moment';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox'

import { OutlinedDiv, ActionInput, MultiActionInput, ActionDateInput, ActionDateTimeInput, ActionAutocompleteInput, SelectActionInput } from 'shared/ui'
import Grid from '@mui/material/Grid';

const gridProps = { alignItems: 'center' }

const inputProps = { variant: 'outlined', fullWidth: true, size: 'small' }
const dateTimeInputProps = { ...inputProps, style: { textAlign: 'right' } }

const fSwitch = (dataProps, text, prop) => (
  <Grid container item xs={12} {...gridProps}><Grid item xs={4}>
    <span>{text}</span>
  </Grid><Grid item xs={8}>
      <Switch
        color={'primary'}
        disabled={dataProps.disabled === true}
        checked={dataProps.value?.[prop]}
        onChange={dataProps.onChange ? dataProps.onChange.bind(null, prop) : dataProps.onChange_v2 ? dataProps.onChange_v2.bind(null, dataProps.value, prop) : null}
      />
    </Grid>
  </Grid>
)

const fButton = (dataProps, text, handler, icon, buttonText, tooltip) => {
  const control = (
    <OutlinedDiv {...dataProps} {...inputProps} label={text}>
      {ToolButton(icon, handler, dataProps.disabled !== true, null, null, null, buttonText)}
    </OutlinedDiv>
  )
  return text && dataProps.style !== 'compact' ?
  <Grid container item xs={12} {...gridProps}><Grid item xs={4}>
    <span>{text}</span>
  </Grid><Grid item xs={8}>
      {control}
    </Grid>
  </Grid>
    : control
}

const checkBoxStyles = theme => ({
  root: {
    '&$checked': {
      color: theme.palette.action.checkbox,
    },
  },
  checked: {},
 })

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const inGrid = (text, control, key) => (
  <Grid key={key} container item xs={12} {...gridProps}><Grid item xs={4}>
    <span>{text}</span>
  </Grid><Grid item xs={8}>
      {control}
    </Grid>
  </Grid>
)

const fCheckBox = (dataProps, text, prop, key) => {
  const control = (
    <CustomCheckbox
      key={key}
      disabled={dataProps.disabled === true}
      checked={dataProps.value?.[prop]}
      onChange={dataProps.onChange ? dataProps.onChange.bind(null, prop) : dataProps.onChange_v2 ? dataProps.onChange_v2.bind(null, dataProps.value, prop) : null}
    />
  )
  return text && dataProps.style !== 'compact' ? inGrid(text, control, key) : control
}

const fNumber = (dataProps, text, prop, placeholder, props, key) => {
  const control = <ActionInput key={key} {...inputProps} {...dataProps} {...props} v={prop} placeholder={placeholder} label={dataProps.style === 'compact' ? text : null} type='number' />
  return text && dataProps.style !== 'compact' ? inGrid(text, control, key) : control
}

const getSetDate = (name, isSet, i, event) => {
  if (isSet === 'set') i[name] = event
  else return i[name]
}

const getSetDateFmt = (name, isSet, i, event) => {
  if (isSet === 'set') i[name] = fmtSet(event)
  else return fmtGet(i[name])
}

const fmtSet = (date) => (date && moment(date).format('YYYY-MM-DD'))
const fmtGet = (date) => (!date || !date.length ? null : moment(date, 'YYYY-MM-DD'))

const fDate = (dataProps, text, prop, placeholder, props, key) => {
  const control = <ActionDateInput
    key={key} 
    {...dataProps} 
    {...props}
    placeholder={placeholder} 
    {...dateTimeInputProps} 
    v={prop} 
    views={undefined} 
    size={'small'} 
    format={'LL'} 
    label={dataProps.style === 'compact' ? text : null}
  />
  return text && dataProps.style !== 'compact' ? inGrid(text, control, key) : control
};

const fMemo = (dataProps, text, prop, placeholder, props, rows, key) => {
  const control = <ActionInput key={key} textFieldProps={{ multiline: true, rows: rows/* || 3*/ }} {...inputProps} {...dataProps} {...props} v={prop} placeholder={placeholder} label={dataProps.style === 'compact' ? text : null} />
  return text && dataProps.style !== 'compact' ? inGrid(text, control, key) : control
}

const fText = (dataProps, text, prop, placeholder, props, key) => {
  // dataProps после inputProps что-бы можно было перебить значения из inputProps
  const control = <ActionInput
    id={key}
    key={key}
    {...inputProps}
    {...dataProps}
    {...props}
    v={prop}
    placeholder={placeholder}
    label={dataProps.style === 'compact' ? text : null}
  />
  return text && dataProps.style !== 'compact' ? inGrid(text, control, key) : control
}

const fMultiSelect = (dataProps, text, prop, placeholder, props, key) => {
  return (
    <MultiActionInput
      id={key}
      key={key}
      {...inputProps}
      {...dataProps}
      {...props}
      v={prop}
      placeholder={placeholder}
      label={dataProps.style === 'compact' ? text : null}
    />
  )
}

const fSelect = (dataProps, text, prop, placeholder, props, key) => {
  return (
    <SelectActionInput
      id={key}
      key={key}
      {...inputProps}
      {...dataProps}
      {...props}
      v={prop}
      placeholder={placeholder}
      label={dataProps.style === 'compact' ? text : null}
    />
  )
}

const fBoolean = (dataProps, text, prop, placeholder, props, key) => {
  const value = !!dataProps.value[prop];
  const disabled = props.disabled === false;
  const control = <ActionInput
    id={key}
    key={key}
    {...inputProps}
    {...dataProps}
    {...props}
    disabled={!disabled}
    v={() => capitalize(value.toString())}
    label={dataProps.style === 'compact' ? text : null}
    endInputAdornment={(
      <InputAdornment position='end'>
        <Switch 
          color='primary'
          disabled={!disabled}
          checked={value}
          onChange={dataProps.onChange ? dataProps.onChange.bind(null, prop) : dataProps.onChange_v2 ? dataProps.onChange_v2.bind(null, dataProps.value, prop) : null}
        />
      </InputAdornment>
    )}
    placeholder={placeholder}
  />
  return text && dataProps.style !== 'compact' ? inGrid(text, control, key) : control
}

const fDateTime = (dataProps, text, prop, placeholder, props, key) => {
  const control = <ActionDateTimeInput
    id={key}
    key={key}
    {...inputProps}
    {...dataProps}
    v={prop}
    placeholder={placeholder}
    label={dataProps.style === 'compact' ? text : null}
    {...dateTimeInputProps}
    {...props}
    views={undefined}
    format={'L LT'}
  />
  return text && dataProps.style !== 'compact' ? inGrid(text, control, key) : control
}

const CheckedListItem = (props) => {
  const { label, selected } = props
  const theme = useTheme()
  return (
    <React.Fragment>
      {Icon(null, AllowIcon, '1em', selected === true ? theme.palette.success.main : 'transparent', { marginRight: '0.25em' })}
      <Typography noWrap>{label}</Typography>
    </React.Fragment>
  )
}

const ControlAutocomplete = (dataProps, text, select, placeholder, multiple, disableClearable, getSet, freeSolo, isTree = false, treeData) => (
  <ActionAutocompleteInput
    {...inputProps}
    {...dataProps}
    v={getSet}
    select={select}
    autocompleteProps={{
      isTree: isTree,
      treeData: treeData,
      clearOnBlur: false,
      placeholder: placeholder,
      multiple: multiple,
      freeSolo: freeSolo === true,
      disableCloseOnSelect: multiple,
      filterSelectedOptions: false,
      getOptionSelected: (option, value) => option.code === value?.code,
      getOptionLabel: (option) => option.name  || '',
      disableClearable: disableClearable,
      renderOption: (option, { selected }) => (<CheckedListItem label={option.name} selected={selected} />),
      renderTags: (value, getTagProps) =>
        value.map((option, index) => (
          option
          ? <Chip label={<Typography style={{ whiteSpace: 'normal' }}>{option.name}</Typography>} style={{ justifyContent: 'space-between', height: '100%', width: '100%' }} {...getTagProps({ index })} />
          : null
        )),
    }}
  />
)

const FAutoComplete = (dataProps, text, select, placeholder, multiple, disableClearable, getSet) => {
  return <Grid container item xs={12} {...gridProps}><Grid item xs={4}>
    <span>{text}</span>
  </Grid><Grid item xs={8}>
    {ControlAutocomplete(dataProps, text, select, placeholder, multiple, disableClearable, getSet)}
    </Grid>
  </Grid>
}

export { fButton, fBoolean, fSwitch, fCheckBox, fText, fMultiSelect, fSelect, fNumber, fMemo, fDate, fDateTime, FAutoComplete }
export { getSetDate, getSetDateFmt }
export { ControlAutocomplete }
