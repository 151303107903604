export const mainTheme = {
  label: "Light theme",
  palette: {
    type: "light",
    olap: {
      gridBackground: "#fff",
      dimCaptionBackground: "#cfd0d1",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      disabled: "rgba(0, 0, 0, 0.5)",
      current: "#0071e3",
    },
    action: {
      active: "#1d1d1f80",
      checkbox: "#0071e3",
      disabled: "rgba(0, 0, 0, 0.15)",
    },
    info: {
      contrastText: "#fff",
      dark: "#1976d2",
      light: "#64b5f6",
      main: "#2196f3",
    },
    primary: {
      contrastText: "#fff",
      dark: "rgb(23, 105, 172)",
      light: "rgb(77, 171, 247)",
      main: "#0071e3",
    },
    secondary: {
      contrastText: "#fff",
      dark: "rgb(171, 0, 60)",
      light: "rgb(247, 51, 120)",
      main: "#82BAFF",
    },
    success: {
      contrastText: "rgba(0, 0, 0, 0.87)",
      dark: "#388e3c",
      light: "#81c784",
      main: "#4caf50",
    },
    warning: {
      contrastText: "rgba(0, 0, 0, 0.87)",
      dark: "#f57c00",
      light: "#ffb74d",
      main: "#ff9800",
    },

    divider: "#D7D8D8",
    background: {
      paper: "#f4f5f6",
      secondary: "#fff",
    },
    menu: {
      icon: "#007BFF",
    },
    toolbar: {
      background: "rgba(0, 0, 0, 0.05)",
    },
    header: {
      contrastText: "#fff",
      dark: "rgb(23, 105, 172)",
      light: "rgb(77, 171, 247)",
      main: "#0071e3",
    },
    footer: {
      contrastText: "#fff",
      dark: "rgb(23, 105, 172)",
      light: "rgb(77, 171, 247)",
      main: "#aaa",
    },
  },
  typography: {
    htmlFontSize: 13,
    fontFamily: "Roboto, sans-serif",
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    text: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 13,
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    header: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 20,
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    dialogTitle: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 17,
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "left",
    },
    ul: {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      marginInlineEnd: 0,
      paddingInlineStart: "1.5em",
    },
    h1: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: "6em",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    h2: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: "3.75em",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    h3: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "3em",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    h4: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "2.125em",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    h5: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "1.5em",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    h6: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: "1.25em",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    subtitle1: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "1em",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    subtitle2: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "0.875em",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    body1: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    body2: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 13,
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    button: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "1em",
      lineHeight: "normal",
      letterSpacing: "normal",
      textTransform: "none",
    },
    caption: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "1.2em",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    code: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "1.3em",
      lineHeight: "normal",
      letterSpacing: "normal",
      margin: 0,
    },
    overline: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "1em",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
  },
};
