import React from "react";
import { i18n } from "@lingui/core";
import { observer } from "mobx-react-lite";
import { en, ru } from "make-plural/plurals";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SnackbarProvider } from "notistack";
import { I18nProvider } from "@lingui/react";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AvailableLocales, UserInterface } from "storages/GlobalStorage/types";
import { GlobalStorage } from "storages/GlobalStorage";

import { localStorageManager } from "utils/localStorageManager";
import { getUuidv4 } from "utils/uuid";

import { messages as ruMessages } from "locales/ru/messages";
import { messages as enMessages } from "locales/en/messages";

import { ConfirmationDialogProvider } from "shared/ui/ConfirmationDialog";

import "./index.css";
import { mainTheme } from "theme";
import { Provider } from "react-redux";

import store from "store";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "Home";
import Admin from "Admin";

i18n.loadLocaleData("ru", { plurals: ru });
i18n.loadLocaleData("en", { plurals: en });

i18n.load({
  ru: ruMessages,
  en: enMessages,
});

const envLocale = process.env.REACT_APP_SURVEY_BASE_LOCALE;
const BASE_LOCALE = envLocale === "ru" || envLocale === "en" ? envLocale : "ru";

export interface AppInterface {
  withHeader?: boolean;
  customHeader?: React.ReactNode;
  locale?: AvailableLocales;
  user?: UserInterface;
}

function App({ customHeader, locale = BASE_LOCALE, withHeader, user }: AppInterface) {
  const [theme] = React.useState(createTheme(mainTheme as any));

  React.useEffect(() => {
    const uuid = localStorageManager.uuid || getUuidv4();
    GlobalStorage.uuid = uuid;
    localStorageManager.setUuid(uuid);
  }, []);

  React.useEffect(() => {
    const newLocale = locale === "ru" || locale === "en" ? locale : BASE_LOCALE;
    GlobalStorage.locale = newLocale;
    i18n.activate(newLocale);
  }, [locale]);

  React.useEffect(() => {
    GlobalStorage.user = user || null;
  }, [user]);

  return (
    <I18nProvider i18n={i18n}>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <DndProvider backend={HTML5Backend}>
              <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: "left", vertical: "bottom" }}>
                <ConfirmationDialogProvider>
                  <BrowserRouter>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/admin" element={<Admin />} />
                    </Routes>
                  </BrowserRouter>
                </ConfirmationDialogProvider>
              </SnackbarProvider>
            </DndProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </I18nProvider>
  );
}

export default observer(App);
