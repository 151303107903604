/**
 * JavaScript BitArray - v0.2.0
 *
 * Licensed under the revised BSD License.
 * Copyright 2010-2012 Bram Stein
 * All rights reserved.
 */

/**
 * Creates a new empty BitArray with the given length or initialises the BitArray with the given hex representation.
 */
 class BitArray {
     constructor(size) {
         this.values = [];

         for (var i = 0; i < Math.ceil(size / 32); i += 1) {
             this.values[i] = 0;
         }

     }
    /**
     * Returns the total number of bits in this BitArray.
     */
    size() {
        return this.values.length * 32;
    }
    /**
     * Sets the bit at index to a value (boolean.)
     */
    set(index, value) {
        var i = Math.floor(index / 32);
        // Since "undefined | 1 << index" is equivalent to "0 | 1 << index" we do not need to initialise the array explicitly here.
        if (value) {
            this.values[i] |= 1 << index - i * 32;
        } else {
            this.values[i] &= ~(1 << index - i * 32);
        }
        return this;
    }
    /**
     * Toggles the bit at index. If the bit is on, it is turned off. Likewise, if the bit is off it is turned on.
     */
    toggle(index) {
        var i = Math.floor(index / 32);
        this.values[i] ^= 1 << index - i * 32;
        return this;
    }
    /**
     * Returns the value of the bit at index (boolean.)
     */
    get(index) {
        var i = Math.floor(index / 32);
        return !!(this.values[i] & (1 << index - i * 32));
    }
    /**
     * Resets the BitArray so that it is empty and can be re-used.
     */
    reset() {
        this.values = [];
        return this;
    }
    /**
     * Returns a copy of this BitArray.
     */
    copy() {
        var cp = new BitArray();
        cp.length = this.length;
        cp.values = [].concat(this.values);
        return cp;
    }
    /**
     * Returns true if this BitArray equals another. Two BitArrays are considered
     * equal if both have the same length and bit pattern.
     */
    equals(x) {
        return this.values.length === x.values.length &&
            this.values.every(function (value, index) {
                return value === x.values[index];
            });
    }
    /**
     * Returns the JSON representation of this BitArray.
     */
    toJSON() {
        return JSON.stringify(this.values);
    }
    /**
     * Returns a string representation of the BitArray with bits
     * in logical order.
     */
    toString() {
        return this.toArray().map(function (value) {
            return value ? '1' : '0';
        }).join('');
    }
    /**
     * Returns a string representation of the BitArray with bits
     * in mathemetical order.
     */
    toBinaryString() {
        return this.toArray().map(function (value) {
            return value ? '1' : '0';
        }).reverse().join('');
    }
    /**
     * Returns a hexadecimal string representation of the BitArray
     * with bits in logical order.
     */
    toHexString() {
        var result = [];

        for (var i = 0; i < this.values.length; i += 1) {
            result.push(('00000000' + (this.values[i] >>> 0).toString(16)).slice(-8));
        }
        return result.join('');
    }
    /**
     * Convert the BitArray to an Array of boolean values.
     */
    toArray() {
        var result = [];

        for (var i = 0; i < this.values.length * 32; i += 1) {
            result.push(this.get(i));
        }
        return result;
    }
    /**
     * Returns the total number of bits set to one in this BitArray.
     */
    count() {
        var total = 0;

        // If we remove the toggle method we could efficiently cache the number of bits without calculating it on the fly.
        this.values.forEach(function (x) {
            // See: http://bits.stephan-brumme.com/countBits.html for an explanation
            x = x - ((x >> 1) & 0x55555555);
            x = (x & 0x33333333) + ((x >> 2) & 0x33333333);
            x = x + (x >> 4);
            x &= 0xF0F0F0F;

            total += (x * 0x01010101) >> 24;
        });
        return total;
    }
    /**
     * Inverts this BitArray.
     */
    not() {
        this.values = this.values.map(function (v) {
            return ~v;
        });
        return this;
    }

    fill(f) {
        const v = f ? -1 : 0
        this.values = this.values.map(function () {
            return v
        })
        return this
    }
    /**
     * Bitwise OR on the values of this BitArray using BitArray x.
     */
    or(x) {
        if (this.values.length !== x.values.length) {
            throw new Error('Arguments must be of the same length.');
        }
        this.values = this.values.map(function (v, i) {
            return v | x.values[i];
        });
        return this;
    }
    /**
     * Bitwise AND on the values of this BitArray using BitArray x.
     */
    and(x) {
        if (this.values.length !== x.values.length) {
            throw new Error('Arguments must be of the same length.');
        }
        this.values = this.values.map(function (v, i) {
            return v & x.values[i];
        });
        return this;
    }
    /**
     * Bitwise XOR on the values of this BitArray using BitArray x.
     */
    xor(x) {
        if (this.values.length !== x.values.length) {
            throw new Error('Arguments must be of the same length.');
        }
        this.values = this.values.map(function (v, i) {
            return v ^ x.values[i];
        });
        return this;
    }
}

export default BitArray