import React from "react";
import { capitalize } from "lodash";
import { useSelector } from "react-redux";
import { Divider } from "@mui/material";
import { Trans } from "@lingui/react";
import { useTheme } from "@mui/material/styles";

import { ToolButton, StyledButtonGroup } from "shared/ui/ToolBar";
import { useConfirmationDialog } from "shared/ui/ConfirmationDialog";

import ItemDialog from "components/itemDialog";
import DataTableView from "components/itemView/DataTableView";

import { ReactComponent as IconReload } from "@mdi/svg/svg/reload.svg";
import { ReactComponent as AllFieldsIcon } from "@mdi/svg/svg/file-document-outline.svg";
import { ReactComponent as IconFilterRemove } from "@mdi/svg/svg/filter-remove-outline.svg";

export const TOOLBAR_DISABLE_CASES = {
  more_than_zero: "more_than_zero",
  equal_one: "equal_one",
};

function TableComponent(
  {
    value,
    onChange,
    schema,
    rerenderDeps,
    removeViewAllFieldsToolbutton,
    onFiltersChange,
    onOrderChange,
    saveSelectedRows,
    tags = [],
    onTagsChange,
    customTableDataGetter,
    onEditCell,
    onReload,
    tableDataHandler,
    filtersHandler,
    rowsHandler,
    reloadHandler,
  },
  tableRef,
) {
  const confirmationDialog = useConfirmationDialog();
  const theme = useTheme();
  console.log("useTheme:", theme);

  const api = useSelector((state) => state.API);
  const app = useSelector((state) => state.APP);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);

  const trDims = schema.data.trDims;
  const apiMethod = schema.data.apiMethod;
  const apiPayload = schema.data.apiPayload;

  const filtersFn = React.createRef();
  const reloadFn = React.createRef();
  const clearFiltersFn = React.createRef();

  const handleReload = () => reloadFn.current?.();
  const handleFilters = () => filtersFn.current?.() || [];

  React.useEffect(() => tableDataHandler?.(tableData), [tableData, tableDataHandler]);
  React.useEffect(() => rowsHandler?.(selectedRows), [selectedRows, rowsHandler]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => reloadHandler?.(() => handleReload), [reloadHandler]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => filtersHandler?.(() => handleFilters), [filtersHandler]);

  const loadTableData = (perPage, page) =>
    new Promise(async (resolve) => {
      try {
        const filter = filtersFn.current?.();
        const isDataAxis = (d) => ["data", "fixed"].includes(d.properties.axis);

        const fields = ["id", "*"].concat(trDims.filter((d) => isDataAxis(d)).map((d) => d.properties.name));

        const sort_order = trDims
          .filter((d) => isDataAxis(d))
          .filter((d) => ["DESC", "ASC"].includes(d.properties.sortOrder))
          .map((d) => [d.properties.name, d.properties.sortOrder]);
        if (sort_order.length === 0) sort_order.push(["id", "DESC"]);

        const data = customTableDataGetter
          ? await customTableDataGetter({ perPage, page })
          : await api.send(apiMethod, {
              page_size: perPage,
              page_number: page,
              sort_order,
              ...(apiPayload || {}),
              filter: [...filter, ...(apiPayload?.filter ? apiPayload.filter : [])],
            });

        if (!data || data.error) return resolve({ error: true, message: data.message });
        setTableData((prevData) => (page === 0 ? data : [...prevData, ...data]));

        const dataColumns = Object.keys(data?.[0] || {});
        const columns_name = dataColumns.length > 0 ? dataColumns : fields;
        const columns = columns_name.map((name) => ({ name }));

        const rows = (data?.length > 0 && data.map((r) => Object.values(r))) || [];

        for (let d of trDims) {
          const ndx = columns_name.findIndex((name) => name === d.properties.name);
          if (ndx === -1) continue;
          const dict = trDims.find((i) => i.id === d.id);
          if (!dict?.length) continue;
          const dictMap = dict.reduce((map, r) => {
            map[r[3]] = r[1]; // map[r.code] = r.name
            return map;
          }, {});
          rows.forEach((r) => (r[ndx] = dictMap[r[ndx]]));
        }

        const columnIndex = (d) => {
          const ndx = columns_name.findIndex((name) => name === d.properties.name);
          return ndx === -1 ? columns_name.push(d.properties.name) - 1 : ndx;
        };

        const columnsDim = trDims.filter((d) => isDataAxis(d));
        const fixedCols = columnsDim
          .filter((d) => d.properties.axis === "fixed")
          .sort((l, r) => (l.properties.position < r.properties.position ? -1 : 1))
          .map((d) => columnIndex(d));

        const indexes = columnsDim
          .filter((d) => d.properties.axis === "data")
          .sort((l, r) => (l.properties.position < r.properties.position ? -1 : 1))
          .map((d) => columnIndex(d));

        fixedCols.unshift(-2);

        const columnsIndex = {
          id: columns_name.findIndex((name) => name === "id"),
          indexes,
          fixedCols,
        };

        const resultSelectedRows = saveSelectedRows
          ? selectedRows.filter((id) => !!data.find((element) => element.id === id))
          : [];

        resolve({ rows, columns, selectedRows: resultSelectedRows, columnsIndex });
      } catch (e) {
        resolve({ error: e.message });
      }
    });

  const handleSelectedRowsChanged = (ids) => {
    setSelectedRows([...ids]);
    if (!onChange) return;
    onChange(
      value,
      schema.value,
      null,
      schema.data.returnRowsData ? ids.map((id) => tableData.find((data) => data.id === id)) : ids,
    );
  };

  const createToolbar = React.useCallback(
    (toolbar) =>
      toolbar.map(([icon, handler, disabledStatus, label], index) => {
        let isNotDisabled;
        switch (disabledStatus) {
          case TOOLBAR_DISABLE_CASES.equal_one: {
            isNotDisabled = selectedRows.length === 1;
            break;
          }
          case TOOLBAR_DISABLE_CASES.more_than_zero: {
            isNotDisabled = selectedRows.length > 0;
            break;
          }
          default:
            isNotDisabled =
              typeof disabledStatus === "function" ? disabledStatus({ tableData, rows: selectedRows }) : disabledStatus;
        }
        return ToolButton(
          icon,
          handler.bind(null, { tableData, rows: selectedRows, reload: handleReload }),
          isNotDisabled,
          label,
          index,
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows, tableData],
  );

  const navigationToolbar = React.useMemo(() => {
    if (!schema.data.navigationToolbar) return null;
    return createToolbar(schema.data.navigationToolbar);
  }, [createToolbar, schema.data.navigationToolbar]);

  const customToolbar = React.useMemo(() => {
    if (!schema.data.toolbar) return null;
    return createToolbar(schema.data.toolbar);
  }, [createToolbar, schema.data.toolbar]);

  const tableCurrent = React.useMemo(
    () => ({
      current: { id: schema.data.id, dataFn: loadTableData, type: "i18n" },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [...(rerenderDeps || [])],
  );

  const getSchemaType = React.useCallback((key, value) => {
    if (typeof value === "boolean") return "boolean";
    if (key.includes("date")) return "DateTime";
    return "text";
  }, []);

  const handleAllFieldsView = React.useCallback(async () => {
    const foundData = { ...tableData.find((data) => data.id === selectedRows[0]) };

    const dataKeys = Object.keys(foundData)
      .filter((key) => key !== "icon" && key !== "picture")
      .sort();
    const additionalBlocks = [];

    if (app === "model") {
      const tagsIndex = dataKeys.findIndex((key) => key === "tags");
      if (tagsIndex !== -1) {
        const tagOptions = (foundData.tags || []).map((tag) => ({ id: tag, name: tag }));
        additionalBlocks.push({
          value: "tags",
          label: "Tags",
          type: "multiSelect",
          data: {
            disabled: true,
            select: tagOptions,
          },
        });
        foundData.tags = tagOptions;
        dataKeys.splice(tagsIndex, 1);
      }

      const dataTypeIndex = dataKeys.findIndex((key) => key === "datatype_id");
      if (dataTypeIndex !== -1) {
        const result = await api.send("datatype/list", {
          filter: [[null, "id", "=", foundData.datatype_id, false, null, "AND"]],
        });
        if (result && result?.length) {
          foundData.dataTypeName = result[0]?.name || "";
          foundData.dataTypeCode = result[0]?.code || "";
          additionalBlocks.push({
            value: "",
            label: "",
            type: "MultiInput",
            data: [
              {
                value: "dataTypeName",
                label: "Datatype Name",
                type: "text",
                data: { disableClean: true, disabled: true, primaryText: true },
              },
              {
                value: "dataTypeCode",
                label: "Datatype Code",
                type: "text",
                data: { disableClean: true, disabled: true, primaryText: true },
              },
            ],
          });
          dataKeys.splice(dataTypeIndex, 1);
        }
      }

      const systemIndex = dataKeys.findIndex((key) => key === "system");
      const versionIndex = dataKeys.findIndex((key) => key === "version");
      if (systemIndex !== -1 && versionIndex !== -1) {
        additionalBlocks.push({
          value: "",
          label: "",
          type: "MultiInput",
          data: [
            {
              value: "system",
              label: "System",
              type: "boolean",
              data: { disabled: true },
            },
            {
              value: "version",
              label: "version",
              type: "text",
              data: { disableClean: true, disabled: true, primaryText: true },
            },
          ],
        });
        dataKeys.splice(systemIndex, 1);
        dataKeys.splice(systemIndex > versionIndex ? versionIndex + 1 : versionIndex - 1, 1);
      }
    }

    const imageBlocks = Object.keys(foundData)
      .filter((key) => key === "icon" || key === "picture")
      .map((key, i) => {
        const blockProps = [];
        for (let dataIndex = i * 3; dataIndex < i * 3 + 3; dataIndex++) {
          const foundDataKey = dataKeys[0];
          if (!foundDataKey) continue;
          blockProps.push({
            value: foundDataKey,
            label: capitalize(foundDataKey.split("_").join(" ")),
            type: getSchemaType(foundDataKey, foundData[foundDataKey]),
            data: { disableClean: true, disabled: true, primaryText: true },
          });
          dataKeys.splice(0, 1);
        }
        return {
          value: key,
          label: capitalize(key),
          type: "imageBlock",
          data: {
            disabled: true,
            withProps: blockProps,
          },
        };
      });

    const schema = {
      props: [
        ...imageBlocks,
        ...dataKeys.map((key) => ({
          value: key,
          label: capitalize(key.split("_").join(" ")),
          type: getSchemaType(key, foundData[key]),
          data: { disableClean: true, disabled: true, primaryText: true },
        })),
        ...additionalBlocks,
      ],
    };

    await confirmationDialog.getConfirmation({
      content: (onOk, onClose) => (
        <ItemDialog
          title={<Trans id="common.table.all_fields" />}
          titleIcon={AllFieldsIcon}
          schema={schema}
          prop={{ current: { properties: foundData } }}
          onOk={onOk}
          onClose={onClose}
        />
      ),
      type: "Dialog",
    });
  }, [tableData, app, api, confirmationDialog, selectedRows, getSchemaType]);

  const tableApi = React.useMemo(
    () => ({
      set: (id, properties) =>
        new Promise((resolve) => {
          (async () => {
            try {
              trDims.find((i) => i.id === id).properties = properties;
              resolve({});
            } catch (e) {
              resolve({ error: true });
            }
          })();
        }),
      dimensionValues: (id, page, pageSize, filter) =>
        new Promise((resolve) => {
          (async () => {
            try {
              const filterLowerCase = filter.toLowerCase();
              const result = trDims.find((i) => i.id === id);
              const res = {
                values: result
                  .filter((i) => !filter || filter.length === 0 || i[1].toLowerCase().includes(filterLowerCase))
                  .map((i) => {
                    return { value: i[1], key: i[0], index: i[2] };
                  }),
              };
              resolve(res);
            } catch (e) {
              resolve({ error: true });
            }
          })();
        }),
    }),
    [trDims],
  );

  return (
    <div
      ref={tableRef}
      style={{ flexGrow: 1, display: "flex", minHeight: "60vh", maxWidth: "100%", flexDirection: "column" }}
    >
      <StyledButtonGroup variant="outlined" size="small">
        {ToolButton(
          IconReload,
          () => {
            handleReload();
            onReload?.();
          },
          true,
          <Trans id="table.reload" />,
        )}
        <Divider key="-1" orientation="vertical" flexItem style={{ margin: "0 0.25em" }} />
        {navigationToolbar}
        {!!navigationToolbar && <Divider key="-3" orientation="vertical" flexItem style={{ margin: "0 0.25em" }} />}
        {!removeViewAllFieldsToolbutton &&
          ToolButton(AllFieldsIcon, handleAllFieldsView, selectedRows.length === 1, <Trans id="table.all_fields" />)}
        {!removeViewAllFieldsToolbutton && (
          <Divider key="-2" orientation="vertical" flexItem style={{ margin: "0 0.25em" }} />
        )}
        {customToolbar}
        {schema.data.toolbar?.length > 0 && (
          <Divider key="1" orientation="vertical" flexItem style={{ margin: "0 0.25em" }} />
        )}
        {ToolButton(
          IconFilterRemove,
          () => {
            clearFiltersFn.current?.();
            onFiltersChange?.([]);
          },
          true,
          <Trans id="table.reset_filters" />,
        )}
      </StyledButtonGroup>
      <DataTableView
        trDims={trDims}
        onEditorOk={onEditCell}
        filtersFn={filtersFn}
        reloadFn={reloadFn}
        tags={tags}
        onTagsChange={onTagsChange}
        onOrderChange={onOrderChange}
        onFiltersChange={onFiltersChange}
        clearFiltersFn={clearFiltersFn}
        onSelectedRowsChanged={handleSelectedRowsChanged}
        singleRowSelect={schema.data.singleRowSelect}
        disableMoveFiltersButton
        disableTableColumnHeaderIcons
        disableSearchAndReplaceButton
        dataItem={tableCurrent}
        api={tableApi}
      />
    </div>
  );
}

export default React.memo(React.forwardRef(TableComponent));
