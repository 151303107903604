import { makeObservable, action, observable, reaction, computed } from "mobx";

import { api } from "utils/api";
import { localStorageManager } from "utils/localStorageManager";

import { ListInterface, UserInterface, AvailableLocales, FolderInterface } from "./types";

const LOGIN_REQUIRED = !!process.env.REACT_APP_SURVEY_LOGIN_REQUIRED;

export const QUIEZZES_PER_PAGE = process.env.REACT_APP_SURVEY_QUIZZES_PER_PAGE
  ? parseInt(process.env.REACT_APP_SURVEY_QUIZZES_PER_PAGE)
  : 5;

class GlobalStorageClass {
  constructor() {
    makeObservable(this);
  }

  @observable
  quizzesPage = 1;

  @observable
  quizzesMaxPage = 1;

  @observable
  folders: Record<number, FolderInterface> = {};

  @observable
  mainFolder: (ListInterface | FolderInterface)[] = [];

  @observable
  currentFolder: null | number = null;

  @observable
  foldersHistory: number[] = [];

  @observable
  quizzes: ListInterface[] | null = null;

  @observable
  allQuizzes: ListInterface[] | null = null;

  @observable
  uuid = "";

  @observable
  canLoadQuiz = !LOGIN_REQUIRED;

  @observable
  user: UserInterface | null = null;

  @observable
  loginModalVisibility = false;

  @observable
  locale: AvailableLocales = "ru";

  @computed
  get isUserAdmin() {
    if (!this.user) return false;
    return this.user.roles.includes("admin");
  }

  @computed
  get currentFolderItems() {
    if (this.currentFolder === null) return this.mainFolder;
    return this.folders[this.currentFolder].items;
  }

  @computed
  get currentFolderName() {
    if (this.currentFolder === null) return null;
    return this.folders[this.currentFolder].name;
  }

  @action.bound
  setUser(user: UserInterface | null) {
    this.user = user;
  }

  @action.bound
  goToFolder(value: number) {
    this.currentFolder = value;
    this.foldersHistory.push(value);
  }

  @action.bound
  goBackToFolder() {
    if (this.foldersHistory.length === 1) {
      this.currentFolder = null;
      this.foldersHistory = [];
      return;
    }
    this.currentFolder = this.foldersHistory[this.foldersHistory.length - 2];
    this.foldersHistory.pop();
  }

  @action.bound
  changeLoginModalVisibility(value: boolean) {
    this.loginModalVisibility = value;
  }

  @action.bound
  async loadQuizzesData() {
    const response = await api.get<ListInterface[]>(`/list?lang=${this.locale}`);
    if ("error" in response) return;
    this.allQuizzes = response.map((quiz, id) => ({ ...quiz, completedByUser: false, id }));
    this.updateAvailableQuizes();
  }

  @action.bound
  updateAvailableQuizes() {
    if (!this.allQuizzes) return;

    localStorageManager.completedQuizzes.forEach(({ code }) => {
      const foundQuizIndex = this.allQuizzes!.findIndex((quiz) => quiz.code === code);
      if (foundQuizIndex === -1) return;
      this.allQuizzes![foundQuizIndex].completedByUser = true;
    });

    if (LOGIN_REQUIRED) {
      const roles = this.user?.roles || ["guest"];
      this.quizzes = this.isUserAdmin
        ? this.allQuizzes
        : this.allQuizzes.filter((quiz) =>
            quiz.enabled ? (quiz.roles ? quiz.roles.find((role) => roles.includes(role)) : true) : false,
          );
    } else {
      this.quizzes = this.allQuizzes.filter((quiz) => quiz.enabled);
    }

    this.quizzesPage = 1;
    this.quizzesMaxPage = Math.max(1, Math.ceil(this.quizzes.length / QUIEZZES_PER_PAGE));

    this.folders = {};
    this.mainFolder = [];
    this.foldersHistory = [];
    this.currentFolder = null;
    this.quizzes.forEach((quiz) => {
      if (!quiz.folders || quiz.folders.length === 0) {
        this.mainFolder.push(quiz);
        return;
      }

      quiz.folders.forEach((folder, index) => {
        if (!this.folders[folder.id]) this.folders[folder.id] = { ...folder, items: [] };
        if (quiz.folders.length - 1 !== index) return;
        this.folders[folder.id].items.push(quiz);
      });
    });

    Object.keys(this.folders).forEach((key: string) => {
      const folder = this.folders[+key];
      if (!folder.parent_id) {
        this.mainFolder.push(folder);
        return;
      }
      this.folders[folder.parent_id].items.push(folder);
    });

    Object.keys(this.folders).forEach((key) => {
      const folder = this.folders[+key as keyof typeof this.folders];
      folder.items = folder.items.sort(sortItems);
    });

    this.mainFolder = this.mainFolder.sort(sortItems);
  }
}

function sortItems(firstItem: FolderInterface | ListInterface, secondItem: FolderInterface | ListInterface) {
  if ("items" in firstItem && "items" in secondItem) return firstItem.name > secondItem.name ? 1 : -1;
  if ("items" in firstItem) return -1;
  if ("items" in secondItem) return 1;
  return 0;
}

export const GlobalStorage = new GlobalStorageClass();

reaction(
  () => GlobalStorage.user,
  () => GlobalStorage.updateAvailableQuizes(),
);
