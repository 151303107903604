import React, { Fragment, Component, useState, useEffect, useCallback } from 'react'
import { useForceUpdate } from 'shared/utils'

import SortableTree, { walk } from 'shared/ui/tree'
import { getNode, syncNode, NodeFromItem } from 'shared/ui/tree/utils'

const Tree = (props) => {
  const { filter, api, rootId, onClicked, onProps, onSelected, current, path } = props

  const [treeData, setTreeData] = useState([])
  const [selected, setSelected] = useState(null)
  const [searchId, setSearchId] = useState(null)
  const [pendingSelectedID, setPendingSelectedID] = useState(props.pendingSelectedID)
  const [pendingCurrent, setPendingCurrent] = useState(props.pendingCurrent)

  const forceUpdate = useForceUpdate()
  const updateTreeData = (treeData) => {
    setTreeData(treeData)
  }
  const getNodeKey = ({ node }) => node.id

  useEffect(() => {
    onSelected && onSelected(selected)
  }, [selected])

  useEffect(() => {
    setPendingSelectedID(props.pendingSelectedID)
  }, [props.pendingSelectedID])

  useEffect(() => {
    // setCurrent - work on mac
    // setPendingCurrent - work on Windows
    setCurrent(props.pendingCurrent)
    setPendingCurrent(props.pendingCurrent)
  }, [props.pendingCurrent])

  const handleTreeBack = React.useCallback(async () => {
    let found = findNode(current.current.parent);
    if (
      found?.node?.data?.current?.type === "software_product" ||
      found?.node?.data?.current?.type === "application" ||
      found?.node?.data?.current?.type === "module" ||
      found?.node?.data?.current?.type === "class"
    ) found = findNode(found.node.data.current.parent);
    await syncNode(props.api.get, found, treeData, rootId);
    props.onClicked && props.onClicked(null, found)
  }, [current, treeData]);

  useEffect(() => {
    window.addEventListener("TREE_BACK", handleTreeBack);
    return () => window.removeEventListener("TREE_BACK", handleTreeBack);
  }, [handleTreeBack]);
  
  const handleTreeDeep = React.useCallback(async ({ detail }) => {
    if (detail.__proto__ !== Array.prototype) return;
    for (let i = 0; i < detail.length; i++) {
      let found = findNode(detail[i]);
      await syncNode(props.api.get, found, treeData, rootId);
      if (i !== (detail.length - 1)) continue;
      props.onClicked && props.onClicked(null, found)
    };
  }, [current, treeData]);

  useEffect(() => {
    window.addEventListener("TREE_DEEP", handleTreeDeep);
    return () => window.removeEventListener("TREE_DEEP", handleTreeDeep);
  }, [handleTreeDeep]);

  const handleTreeUpdate = React.useCallback(async () => {
    delete api.childrens[current.current.id];
    const found = findNode(current.current.id)
    await syncNode(props.api.get, found, treeData, rootId);
    props.onClicked && props.onClicked(null, found)
  }, [current, treeData]);

  useEffect(() => {
    window.addEventListener("TREE_UPDATE", handleTreeUpdate);
    return () => window.removeEventListener("TREE_UPDATE", handleTreeUpdate);
  }, [handleTreeUpdate]);

  useEffect(() => {
    if (!treeData.length)
      return

    const pending = pendingSelectedID && findNode(pendingSelectedID)
    if (pending) {
      setSelected(pending.node)
      setPendingSelectedID(null)
    }
    if (pendingCurrent) {
      setCurrent(pendingCurrent)
      setPendingCurrent(null)
    }
  }, [treeData])

  useEffect(() => {
    if (rootId && api && api.itemFromHash?.(rootId)) {
      const item = NodeFromItem(api.get, api.itemFromHash(rootId), 0)
      setTreeData([item])
      props.onSelected && props.onSelected(item)
      return
    }

    const doFilter = (data) => {
      const filterLower = filter.toLowerCase()
      const d = data.filter(i => i.name().toLowerCase().includes(filterLower))
      setTreeData(d)
    }
    const fn = filter?.length > 0 ? doFilter : setTreeData
    api && getNode(api.get, null, rootId, fn)
    props.onSelected && props.onSelected(null)
  }, [rootId, api, filter])

  useEffect(() => {
    setCurrent(current)
  }, [current])

  useEffect(() => {
    switch (props.itemChanged?.type) {
      case 'item_create': {
        const copyData = [...treeData]

        const nodeInfo = findNode(props.itemChanged.parent)
        if (nodeInfo && typeof nodeInfo.node.children !== 'function') {
          props.api.item(props.itemChanged.id).then(res => {
            if (res.error)
              return
            ++nodeInfo.node.size
            if (!nodeInfo.node.children)
              nodeInfo.node.children = []
            nodeInfo.node.children.push(NodeFromItem(props.api.get, res))
            setTreeData(copyData)
          })
        } else if (props.itemChanged.parent === rootId) {
          props.api.item(props.itemChanged.id).then(res => {
            if (res.error)
              return
            copyData.push(NodeFromItem(props.api.get, res))
            setTreeData(copyData)
          })
        }
        break
      }
      case 'item_delete': {
        const copyData = [...treeData]
        const nodeInfo = findNode(props.itemChanged.id)
        if (nodeInfo) {
          if (nodeInfo.parentNode) {
            const ndx = nodeInfo.parentNode.children.indexOf(nodeInfo.node)
            if (ndx != -1) {
              nodeInfo.parentNode.children.splice(ndx, 1)
              --nodeInfo.parentNode.size
            }
            setTreeData(copyData)
          } else {
            const ndx = copyData.indexOf(nodeInfo.node)
            ndx != -1 && (copyData.splice(ndx, 1))
            setTreeData(copyData)
          }
        }
        break
      }
      case 'item_update':
        break
    }
  }, [props.itemChanged])


  const setCurrent = (id) => {
    if (!id) {
      setSelected(null)
      return
    }

    const expandPath = async (path) => {
      let found = null
      for (let i of path) {
        let nodeInfo = findNode(i.current?.id)
        if (nodeInfo)
          found = nodeInfo
        else if (found) {
          await syncNode(props.api.get, found, treeData, rootId)
          found.node.expanded = true
          nodeInfo = findNode(i.current?.id)
          found = nodeInfo
        }
      }
      setSearchId(found?.node?.id)
      props.onClicked && props.onClicked(null, found)
    }

    // для autoComplete { id: 0, name: 'name' }
    const currentID = id?.current?.id || id?.id

    if (currentID === selected?.data.current?.id)
      return

    let p = path
    if (!p && api?.path && currentID) {
      p = api.path(currentID)
    }

    if (p) {
      p.then(res => {
        const node = []
        res?.forEach && res.forEach((i, ndx, self) => node.push(i))
        expandPath(node)
      })
    } else {
      const found = findNode(currentID)
      setSearchId(found?.node?.id)
      props.onClicked && props.onClicked(null, found)
    }
  }

  const findNode = (id) => {
    let nodeInfo = null
    const callback = (info) => {
      if (info.node.id === id) {
        nodeInfo = info
        return false
      }
      return true
    }
    id != rootId && walk({ treeData, getNodeKey, callback, ignoreCollapsed: false })
    return nodeInfo
  }

  const searchIdMethod = (arg) => (arg.searchQuery === arg.node.id)

  const searchFinishCallback = (searchMatches) => {
    setSearchId(null)
    setSelected && setSelected(searchMatches[0]?.node)
  }

  const nodeClicked = (event, rowInfo) => {
    setSelected(rowInfo.node)
    onClicked && onClicked(event, rowInfo)
    props.onChange && props.onChange(rowInfo.node.data)
  }

  const nodeClickedProps = (event, rowInfo) => {
    onProps && onProps(event, rowInfo)
  }

  return (
    <SortableTree
      rowHeight={props.rowHeight || 26}
      treeData={treeData}
      onChange={updateTreeData}
      getNodeKey={getNodeKey}
      generateNodeProps={rowInfo => (
        {
          onClick: event => nodeClicked(event, rowInfo),
          onProps: onProps && rowInfo?.node?.id === "server" && (event => nodeClickedProps(event, rowInfo)),
          selected: selected?.id == rowInfo.node.id
        }
      )}
      searchMethod={searchIdMethod}
      searchQuery={searchId}
      searchFinishCallback={searchFinishCallback}
      searchFocusOffset={0}
    />
  )
}

export default Tree