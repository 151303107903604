import { AnswerInterface, QuizInterface, QuizPeriods } from "storages/QuizStorage/types";

const QUIZ_CODE_STORAGE_KEY = "quizCode";
const QUIZ_STEP_STORAGE_KEY = "quizStep";
const ANSWERS_STORAGE_KEY = "answers";
const UUID_STORAGE_KEY = "uuid";
const COMPLETED_QUIZZES_STORAGE_KEY = "completed";

interface CompletedQuizInterface {
  code: string;
  period: string;
  completeTime: string;
}

class LocalStorageManager {
  get quizCode() {
    return localStorage.getItem(QUIZ_CODE_STORAGE_KEY);
  }

  get quizStep() {
    const step = localStorage.getItem(QUIZ_STEP_STORAGE_KEY);
    return step === null ? null : +step;
  }

  get answers() {
    const storageAnswers = localStorage.getItem(ANSWERS_STORAGE_KEY);
    return storageAnswers === null ? null : (JSON.parse(storageAnswers) as Record<string, AnswerInterface>);
  }

  get uuid() {
    return localStorage.getItem(UUID_STORAGE_KEY);
  }

  get completedQuizzes() {
    const quizzes = localStorage.getItem(COMPLETED_QUIZZES_STORAGE_KEY);
    if (quizzes === null) return []; 
    const currentYear = new Date().getFullYear().toString();
    return (JSON.parse(quizzes) as CompletedQuizInterface[]).filter(quiz => {
      if (quiz.period === QuizPeriods.YEAR) return quiz.completeTime === currentYear;
      return false;
    }) ;
  }

  setQuizCode(quizCode: string) {
    localStorage.setItem(QUIZ_CODE_STORAGE_KEY, quizCode);
  }

  setQuizStep(quizStep: number) {
    localStorage.setItem(QUIZ_STEP_STORAGE_KEY, quizStep.toString());
  }

  setAnswers(answers: Record<string, AnswerInterface>) {
    localStorage.setItem(ANSWERS_STORAGE_KEY, JSON.stringify(answers));
  }

  setUuid(uuid: string) {
    localStorage.setItem(UUID_STORAGE_KEY, uuid);
  }

  completeQuiz(quiz: QuizInterface) {
    const quizzes = this.completedQuizzes;
    quizzes.push({
      code: quiz.code, 
      period: QuizPeriods.YEAR, 
      completeTime: new Date().getFullYear().toString()
    });
    localStorage.setItem(COMPLETED_QUIZZES_STORAGE_KEY, JSON.stringify(quizzes));
  }

  resetCompletedQuiz(quizCode: string) {
    const quizzes = this.completedQuizzes;
    const filteredQuizzes = quizzes.filter((quiz) => quiz.code !== quizCode);
    localStorage.setItem(COMPLETED_QUIZZES_STORAGE_KEY, JSON.stringify(filteredQuizzes));
  }

  clearQuizData() {
    localStorage.removeItem(QUIZ_CODE_STORAGE_KEY);
    localStorage.removeItem(QUIZ_STEP_STORAGE_KEY);
    localStorage.removeItem(ANSWERS_STORAGE_KEY);
  }
}

export const localStorageManager = new LocalStorageManager();
