/* eslint-disable react-hooks/exhaustive-deps */

import React, { useRef, useState, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useSelector } from "react-redux";

import FilterList from "./FilterList";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Popover from "@mui/material/Popover";

import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";

import { Icon } from "./../../../shared/ui/ToolBar";
import { projectItemImageComponent } from "./../../../shared/utils";

import { ReactComponent as FilterIcon } from "@mdi/svg/svg/filter-variant.svg";

const StyledPaper = withStyles(
  (theme) => ({
    root: {
      display: "flex",
      border: `1px solid ${theme.palette.divider}`,
      /*
    ${theme.palette.type === 'light'
        ? 'rgba(0, 0, 0, 0.25)'
        : 'rgba(255, 255, 255, 0.75)'
      }`,
*/
      alignItems: "center",
      backgroundColor: "transparent",
    },
  }),
  { withTheme: true },
)(Paper);

const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: "hidden",
    display: "flex",
    cursor: "grabbing",
    paddingRight: ".25em",
    paddingBottom: ".25em",
  },
  container_full: {
    overflowX: "hidden",
    display: "flex",
    width: "100%",
    cursor: "grabbing",
    padding: 0,
  },
  details: {
    display: "flex",
    userSelect: "none",
    WebkitUserSelect: "none",
    flexDirection: "column",
    justifyContent: "center",
    flexGrow: 1,
    padding: 0,
    overflowX: "hidden",
  },
}));

export const PopoverCustom = (props) => {
  const { Component, trigger } = props;
  return (
    <PopupState variant="popover" popupId={props.id + "popup"}>
      {(popupState) => (
        <React.Fragment>
          <span {...bindTrigger(popupState)} style={{ flexGrow: 1, overflowX: "hidden" }}>
            {trigger}
          </span>
          <Popover
            {...bindPopover(popupState)}
            PaperProps={{ style: { overflowY: "hidden" } }}
            autoFocus={true}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Component popupState={popupState} {...props} />
          </Popover>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export const FactButton = (props) => {
  const style = props.style || {};
  const id = props.id;
  const theme = useTheme();

  return null;
  /* 
  return (props.factsItem || null) && (
    <div style={styles.container}>
      <StyledPaper elevation={0} square={props.square === true} key={id} id={id} style={{ ...style }} >
        <div style={{ width: '3px', height: '100%', backgroundColor: 'transparent' }} />
        <div style={{ display: 'flex', flexGrow: 1, margin: '0.2em 0.2em', alignItems: 'center' }}>
          {projectItemImageComponent(props.factsItem, '2em', '0 0.5em 0 0', theme.palette.menu.icon)}
          <div style={styles.details} >
            <span style={{ width: 'min-content', whiteSpace: 'nowrap' }}>{props.factsItem?.properties?.title}</span>
          </div>
          <PopoverCustom {...props} content={FilterList} text={'Visible Facts'} icon={FilterIcon} />
        </div>
        <div style={{ width: '3px', height: '100%', backgroundColor: 'transparent' }} />
      </StyledPaper>
    </div>
  )
 */
};

export const DimButton = (props) => {
  const ref = useRef();
  const style = { ...props.style } || {};
  const id = props.id;
  const theme = useTheme();
  const [hoverId, setHoverId] = useState({ id: null });
  const current = useSelector((state) => state.current);
  const api = useSelector((state) => state.API);

  style.backgroundColor == undefined && (style.backgroundColor = theme.palette.toolbar.background);

  const [, drop] = useDrop({
    accept: "dim",
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.id;
      const hoverIndex = props.id;
      const hoverDim = props.dim;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        // сбрасываем если был
        if (item.hoverDim) {
          item.hoverIndex = null;
          item.hoverDim = null;
          item.hoverPos = null;
          props.onHover && props.onHover(null, null);
        }
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      const hoverPos = hoverClientX < hoverMiddleX;

      if (item.hoverIndex === hoverIndex && item.hoverPos === hoverPos) {
        return;
      }

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.hoverIndex = hoverIndex;
      item.hoverDim = hoverDim;
      item.hoverPos = hoverPos;

      if (!monitor.canDrop()) {
        return;
      }
      props.onHover && props.onHover(hoverIndex, hoverPos);
    },
    canDrop(item, monitor) {
      if (item.hoverDim?.properties.axis === "static") return false;
      if (
        item.dim?.properties.isLang === true &&
        !(item.hoverDim?.properties.axis === "data" || item.hoverDim?.properties.axis === "fixed")
      )
        return false;

      return true;
    },
    drop(self, monitor) {
      if (monitor.didDrop()) return;

      props.onDrop && props.onDrop(self.dim, self.hoverDim, self.hoverPos);
      return { moved: true };
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "dim",
    item: () => {
      console.log("START DRAG:", id);
      props.onStartDrag && props.onStartDrag(id);
      return { id, dim: props.dim };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: (monitor) => {
      return props.dim.properties.axis != "static";
    },
    end: (item, monitor) => {
      props.onDrop && props.onDrop();
    },
  });

  drag(drop(ref));
  // для самостоятельной перерисовки в клетки грида
  props.updatabled && (props.updatabled[id] = { id, hoverId, setHoverId });

  const lc = isDragging ? "#007bff" : hoverId.id === id && hoverId.pos === true ? "green" : "transparent";
  const rc = isDragging ? "#007bff" : hoverId.id === id && hoverId.pos === false ? "green" : "transparent";

  const handleDragStart = (event) => {
    const borderRadius = event.target.style.borderRadius;
    const borderColor = event.target.style.borderColor;
    event.target.style.borderRadius = 0;
    event.target.style.borderColor = "#007bff";
    requestAnimationFrame(() => {
      if (event?.target?.style) {
        event.target.style.borderRadius = borderRadius;
        event.target.style.borderColor = borderColor;
      }
    });
  };

  if (props.isDragState) style.borderColor = "#fd7e14";

  const order = props.allowChangeSortOrder === true && props.dim.properties.sortOrder;
  const colorDown = order === "DESC" ? theme.palette.action.active : "transparent";
  const colorUp = order === "ASC" ? theme.palette.action.active : "transparent";
  const hasFilter = props.dim.properties.textFilter?.operator && props.dim.properties.textFilter.operator !== "ANY";
  const upDownSize = "0.32em";

  const classes = useStyles();

  const Context = (
    <div style={{ display: "flex", flexGrow: 1, alignSelf: "stretch", overflowX: "hidden" }}>
      <div style={{ display: "flex", flexGrow: 1, padding: "0.2em", alignItems: "center", width: "100%" }}>
        {props.icon
          ? Icon(hasFilter ? "primary" : "action", props.icon, "24px")
          : projectItemImageComponent(props.dim, "24px", "0 0.5em 0 0", theme.palette.menu.icon)}
        <div className={classes.details}>
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: props.dim.properties.title?.length > 0 ? "0.25em" : 0,
            }}
          >
            {props.dim.properties.title}
            {props.dim.properties.additionalContent?.()}
          </span>
        </div>
      </div>
    </div>
  );

  const ContextWithUpDownSort = (
    <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "center", height: "100%" }}>
      {order && (
        <div
          style={{
            width: 0,
            height: 0,
            borderLeft: `${upDownSize} solid transparent`,
            borderRight: `${upDownSize} solid transparent`,
            borderTop: `${upDownSize} solid ${colorDown}`,
          }}
        />
      )}
      {Context}
      {order && (
        <div
          style={{
            width: 0,
            height: 0,
            borderLeft: `${upDownSize} solid transparent`,
            borderRight: `${upDownSize} solid transparent`,
            borderBottom: `${upDownSize} solid ${colorUp}`,
          }}
        />
      )}
    </div>
  );

  const ContextWithDragHoverIndicators = (
    <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
      <div style={{ width: "3px", height: "100%", backgroundColor: lc, flexShrink: 0 }} />
      <PopoverCustom
        {...props}
        trigger={ContextWithUpDownSort}
        Component={FilterList}
        onChangeOrder={props.onChangeOrder?.bind(null, props.dim)}
        onChangeFixed={props.onChangeFixed?.bind(null, props.dim)}
        onChangeFilterAxis={props.onChangeFilterAxis?.bind(null, props.dim)}
        current={current}
        requestApi={api}
        text={"Filter"}
        icon={FilterIcon}
      />
      <div style={{ width: "3px", height: "100%", backgroundColor: rc, flexShrink: 0 }} />
    </div>
  );

  return (
    <Paper square={true} elevation={0} className={props.full ? classes.container_full : classes.container}>
      <StyledPaper
        ref={ref}
        onDragStart={handleDragStart}
        elevation={0}
        square={props.square === true}
        key={id}
        id={id}
        style={{ ...style, zIndex: isDragging ? 2 : 1, borderRadius: 0, alignItems: "stretch" }}
      >
        {ContextWithDragHoverIndicators}
      </StyledPaper>
    </Paper>
  );
};
