import { QuizStorage } from ".";

import {
  AnswerInterface,
  QuestionInterface,
  ReferenceInterface,
  SubreferenceInterface,
  SurveyFormInterface,
} from "./types";

function applyMinMaxValue(question: QuestionInterface, value: string) {
  const haveMinLimit = typeof question.config?.min === "number";
  const haveMaxLimit = typeof question.config?.max === "number";
  if (!haveMinLimit && !haveMaxLimit) return value;
  let newValue = parseInt(value.replace(",", "."));
  if (isNaN(newValue)) return "";
  if (haveMinLimit) newValue = Math.max(question.config!.min!, newValue);
  if (haveMaxLimit) newValue = Math.min(question.config!.max!, newValue);
  return newValue.toString();
}

export function validateTextFieldValue(question: QuestionInterface, value: string) {
  if (question.config?.datatype === "integer") {
    const validatedArray = value.match(/[0-9]/g);
    return validatedArray ? applyMinMaxValue(question, validatedArray.join("")) : "";
  }

  if (question.config?.datatype === "float") {
    const validatedArray = value.match(/[0-9]|,/g);
    if (!validatedArray) return "";
    const joinedArray = validatedArray.join("");
    const delimetedArray = joinedArray.split(",");
    if (delimetedArray.length > 2) return applyMinMaxValue(question, delimetedArray[0] + "," + delimetedArray[1]);
    if (delimetedArray[1]?.length > 2)
      return applyMinMaxValue(question, delimetedArray[0] + "," + delimetedArray[1].slice(0, 2));
    return applyMinMaxValue(question, joinedArray);
  }

  return value;
}

export function isReferenceSelected(referenceCode: number, question: QuestionInterface) {
  const questionAnswers = QuizStorage.answers[question.code];
  return question.multiple_values
    ? !!questionAnswers.multipleAnswers.find((answer) => answer.code === referenceCode)
    : questionAnswers.answers?.code === referenceCode;
}

export function getTextFieldFormulaValue(question: QuestionInterface) {
  if (!question.config?.formula) return "0";
  const replacedFormula = question.config?.formula.replaceAll(/\[(.*?)\]/g, (match) => {
    const key = match.replaceAll(/\[|\]/g, "");
    const answer = QuizStorage.answers[key];
    if (!answer || !answer.answers?.value) return "0";
    return answer.answers.value.replace(",", ".");
  });

  try {
    // eslint-disable-next-line no-eval
    const value = eval(replacedFormula);

    if (question.config.datatype === "float") {
      if (!value.toString().includes(".")) return value;
      return (value as number).toFixed(2).replace(".", ",");
    }

    return value;
  } catch (error) {
    return "0";
  }
}

export function parseBoolean(value: any) {
  if (typeof value === "string") return value !== "false" && value !== "0";
  return Boolean(value);
}

export function validateQuestionRule(question: QuestionInterface) {
  if (!question.config?.rule) return true;
  const replacedFormula = question.config.rule.replaceAll(/\[(.*?)\]/g, (match) => {
    const key = match.replaceAll(/\[|\]/g, "");

    const question = QuizStorage.quiz?.Questionary.find(({ code }) => code === key);
    if (question?.config?.formula) return getTextFieldFormulaValue(question);

    const answer = QuizStorage.answers[key];
    if (!answer || !answer.answers) return "undefined";
    if ("value" in answer.answers && answer.answers.value !== null) return answer.answers.value || "undefined";
    return answer.answers.code.toString();
  });

  try {
    // eslint-disable-next-line no-eval
    return eval(replacedFormula.replaceAll(",", "."));
  } catch (error) {
    return true;
  }
}

export function validateQuestionCondition(question: QuestionInterface) {
  if (!question.config?.condition) return true;
  const replacedFormula = question.config.condition.replaceAll(/\[(.*?)\]/g, (match) => {
    const key = match.replaceAll(/\[|\]/g, "");

    const question = QuizStorage.quiz?.Questionary.find(({ code }) => code === key);
    if (question?.config?.formula) return getTextFieldFormulaValue(question);

    const answer = QuizStorage.answers[key];
    if (!answer || !answer.answers) return "undefined";
    if ("value" in answer.answers && answer.answers.value !== null) return answer.answers.value || "undefined";
    return answer.answers.code.toString();
  });

  try {
    // eslint-disable-next-line no-eval
    return eval(replacedFormula.replaceAll(",", "."));
  } catch (error) {
    return true;
  }
}

export function getTextFieldValue(reference: SubreferenceInterface, question: QuestionInterface) {
  const questionAnswers = QuizStorage.answers[question.code];

  if (question.multiple_values) {
    const foundAnswer = questionAnswers.multipleAnswers.find((answer) => answer.code === reference.code);
    if (!foundAnswer || foundAnswer.code !== reference.code || !foundAnswer.value) return "";
    if (question.config?.format) return question.config?.format.replace("#", foundAnswer.value);
    return foundAnswer.value;
  }

  if (questionAnswers.answers?.code !== reference.code || !questionAnswers.answers.value) return "";
  if (question.config?.format) return question.config?.format.replace("#", questionAnswers.answers.value);
  return questionAnswers.answers.value;
}

function getAnswersForQuestion(
  question: QuestionInterface,
  references: SubreferenceInterface[],
  questionAnswer: AnswerInterface,
) {
  if (question.multiple_values) {
    const foundOtherVariantIndex = questionAnswer.multipleAnswers.findIndex(
      (answer) => references.find((reference) => reference.code === answer.code)!.isOtherVariant,
    );

    if (foundOtherVariantIndex === -1) {
      return {
        other: null,
        answers: questionAnswer.multipleAnswers.map((answer) => (answer.value ? answer : answer.code)),
      };
    }

    const answers = [...questionAnswer.multipleAnswers];
    answers.splice(foundOtherVariantIndex, 1);

    return {
      other: questionAnswer.multipleAnswers[foundOtherVariantIndex].value,
      answers: answers.map((answer) => (answer.value ? answer : answer.code)),
    };
  }

  if (questionAnswer.answers === null) return { other: null, answers: null };

  const foundReference = references.find((reference) => reference.code === questionAnswer.answers!.code)!;
  if (foundReference.isOtherVariant || question.config?.datatype === "text") {
    const result = { other: questionAnswer.answers!.value, answers: questionAnswer.answers!.code };
    if (question.config?.datatype === "float") result.other = result.other?.replace(",", ".");
    return result;
  }

  return { other: null, answers: questionAnswer.answers!.code };
}

export function parseAnswers() {
  const answers: Record<string, any> = {};

  QuizStorage.questions.forEach((syntheticQuestion) => {
    if (syntheticQuestion.isQuestionGroup) {
      syntheticQuestion.allQuestions.forEach((question) => {
        const answerCode = question.code;
        const questionAnswer = QuizStorage.answers[answerCode];
        answers[answerCode] = getAnswersForQuestion(question, syntheticQuestion.answer.Reference, questionAnswer);
      });
      return;
    }

    const answerCode = syntheticQuestion.question.code;
    const questionAnswer = QuizStorage.answers[answerCode];

    if (syntheticQuestion.question.type) {
      answers[answerCode] = { other: null, answers: questionAnswer.otherTypeAnswers };
      return;
    }

    answers[answerCode] = getAnswersForQuestion(
      syntheticQuestion.question,
      syntheticQuestion.answer.Reference,
      questionAnswer,
    );
  });

  return answers;
}

export function parseFormAnswers({
  answer,
  answers,
  formResponse,
  question,
}: {
  question: QuestionInterface;
  answers: Record<string, AnswerInterface>;
  formResponse: SurveyFormInterface;
  answer: ReferenceInterface;
}) {
  const questionFormAnswers = formResponse.answers[question.code];
  if (!questionFormAnswers || (questionFormAnswers.answers === null && questionFormAnswers.other === null)) return;

  if (question.type || question.config?.datatype === "file") {
    if (typeof questionFormAnswers.answers !== "string") return;
    answers[question.code].otherTypeAnswers = questionFormAnswers.answers;
    return;
  }

  if (question.multiple_values) {
    if (Array.isArray(questionFormAnswers.answers)) {
      answers[question.code].multipleAnswers.push(
        ...questionFormAnswers.answers.map((value) => {
          if (typeof value === "object") return value;
          return { code: value };
        }),
      );
    }

    if (questionFormAnswers.other) {
      answers[question.code].multipleAnswers.push({
        value: questionFormAnswers.other,
        code: answer.Reference.length,
      });
    }
    return;
  }

  if (Array.isArray(questionFormAnswers.answers)) return;
  answers[question.code].answers = { code: questionFormAnswers.answers as number };

  if (!questionFormAnswers.other) return;
  const float = question.config?.datatype === "float";
  const value = float ? String(questionFormAnswers.other).replace(".", ",") : questionFormAnswers.other;
  answers[question.code].answers!.value = value;
}
