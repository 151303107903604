import React, { Fragment, Component, useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom';

import Paper from '@mui/material/Paper'
import { withTheme } from '@mui/styles'

import moment from 'moment';
import 'moment/locale/ru';

import { AutoSizer, InfiniteLoader } from 'react-virtualized';
import FixedSpanGrid from './FixedSpanGrid'

import { useForceUpdate } from 'shared/utils'
import { ContourBackdrop } from 'shared/ui'

import { connect } from 'react-redux'

/*
const HeaderContent = (props) => {
  const { style, cell, columnIndex, rowIndex, tableState } = props
  const { dummy, type } = cell

  const theme = useTheme()
  const borderColor = '#ccc'
  const backgroundColor = '#eee'

  return dummy ? <div style={{ display: 'none' }} /> : (
    <div
      onMouseOver={() => { }}
      onMouseOut={() => { }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: backgroundColor,
        justifyContent: 'center', alignItems: 'center',
        padding: '0.25em',
        lineHeight: 1.25,
        boxSizing: 'border-box',
        borderTop: 'none',//rowIndex ? 'none' : `1px solid ${borderColor}`,
        borderLeft: 'none',//columnIndex ? 'none' : `1px solid ${borderColor}`,
        borderBottom: `1px solid ${borderColor}`,
        borderRight: `1px solid ${borderColor}`,
        ...style,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ overflow: 'hidden' }}>
          {cell.name}
        </span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ fontSize: '0.75em' }}>
          {cell.name}
        </span>
      </div>
    </div>
  )
}

const CellContent = (props) => {
  const { style, cell, columnIndex, rowIndex, tableState } = props
  const dummy = false
  const theme = useTheme()
  const borderColor = '#ccc'

  const data = (cell) => {
    if (!cell)
      return '-'
    if (tableState.columns[columnIndex].dataTypeID == 1082) {
      return moment(cell).locale('ru').format('LL')
    }

    if (typeof cell === 'object')
      return JSON.stringify(cell)
    return cell
    //return JSON.stringify(tableState.columns[columnIndex])
  }

  return dummy ? <div style={{ display: 'none' }} /> : (
    <div
      onMouseOver={() => { }}
      onMouseOut={() => { }}
      style={{
        display: 'flex',
        justifyContent: 'left', alignItems: 'center',
        padding: '0.25em',
        lineHeight: 1.25,
        boxSizing: 'border-box',
        borderTop: 'none',//rowIndex ? 'none' : `1px solid ${borderColor}`,
        borderLeft: 'none',//columnIndex ? 'none' : `1px solid ${borderColor}`,
        borderBottom: `1px solid ${borderColor}`,
        borderRight: `1px solid ${borderColor}`,
        ...style,
      }}
    >
      <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}><span style={{ overflow: 'hidden' }}>{data(cell)}</span></div>
    </div>
  )
}

const cellRenderer = (data, tableState, { columnIndex, key, rowIndex, style }) => {
  //*
    const cell = data[rowIndex][columnIndex]
    const { rowspan, colspan } = cell
    if (rowspan + colspan > 2) {
      style = {...style}
      style.width = style.width * colspan
      style.height = style.height * rowspan
    }
  //
  if (rowIndex === 0) {
    const cell = tableState.columns[columnIndex]
    return <HeaderContent tableState={tableState} key={key} cell={cell} style={style} columnIndex={columnIndex} rowIndex={rowIndex} />
  }
  rowIndex--
  const cell = data[rowIndex][columnIndex]
  return <CellContent tableState={tableState} key={key} cell={cell} style={style} columnIndex={columnIndex} rowIndex={rowIndex} />
}
*/
const TableView = (props) => {
  const [source, setSource] = useState(null)
  const [page, setPage] = useState({ page: null, perPage: 50 })
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [editorData, setEditorData] = useState(null)

//  const forceUpdate = useForceUpdate()
  const setCurrent = async (current) => {
    if (current?.current?.id) {
      const api = props.api
      let databaseId = null
      let columns = null
      let sql = null
      let table = null
      let db = null
      switch (current.current.type) {
        case 'Table':
          databaseId = current.current.parent
          table = current
          columns = await api.get(current.current.id)
          break;
        case 'Query':
          databaseId = current.current.properties.database.id
          sql = current
          break
        case 'i18n':
          db = { current: { properties: { host: 'stat.world', port: 5432, user: 'Localizer', password: 'Microwave', database: 'Localizer' } } }
          sql = current
          columns = current.current.columns
          break
      }
      if (!db && databaseId)
        db = await api.item(databaseId)
      if (!db?.current)
        return

      setData(null)
      setError(null)
      setSource({ db, table, sql, columns })
    } else {
      setData(null)
      setSource(null)
      setError(null)
    }
  }

  useEffect(() => {
  }, [props.current])

  useEffect(() => {
    setPage({ page: source ? 0 : null, perPage: page.perPage })
  }, [source])

  useEffect(() => {
    if (!page || page.page === null)
      return
    const api = props.db
    const dataFn = source.sql?.current.dataFn ? source.sql?.current.dataFn(page.perPage, page.page) : api.data(source.db.current, source.table?.current, source.sql?.current.properties.sql, page.perPage, page.page)
    dataFn.then(async (result) => {
      result && (result.id = source.table?.current.id || source.sql?.current.id) // used for generate unique id tag
      if (result.error) {
        const text = result.resultSql ? result.error.message.substring(result.resultSql.length + 3)
          : result.message || result.error
        console.log('SQL error: ', text)
        setData(null)
        setError(text)
        props.setError(text)
        return
      }
      if (!result.columnsIndex) {
        result.columnsIndex = {
          id: null,
          indexes: result.columns.map((c, ndx) => ndx),
          fixedCols: []
        }
      }
      if (!result.columnsIndex.indexes) {
        result.columnsIndex.fixedCols = []
      }
      if (!result.columnsIndex.indexes) {
        result.columnsIndex.indexes = result.columns.map((c, ndx) => ndx).filter(c => !result.columnsIndex.fixedCols.includes(c))
      }

      if (!data) {
        if (props.onColumnsDef) {
          await props.onColumnsDef(result.columns)
        }

        source.columns && result.columns.forEach(column => {
          column.title = source.columns.find(sourceColumn => {
            return sourceColumn.current?.properties.name === column.name
          })?.current.properties.title
        })

        if (!result.selectedRows) result.selectedRows = []
        props.getIds && (props.getIds.current = () => (result.selectedRows))
        props.selectedRowsChanged?.(result.selectedRows)

        setError(null)
        setData(result)
      }
      else {
        const d = { ...data }
        d.rows = [...d.rows, ...result.rows]
        setData(d)
      }
    })
  }, [page])

  useEffect(() => {
    setCurrent(props.item)
  }, [props.item])

  const onSectionRendered = ({ startIndex, stopIndex }) => {
//    console.log('onSectionRendered: ', startIndex, stopIndex, 'wait: ', (page.page + 1) * page.perPage)
    if (stopIndex == (page.page + 1) * page.perPage) {
      const p = { ...page }
      p.page++
      setPage(p)
    }
  }

  const onClick = event => {
    let node = event.target
    while (node && (!node.attributes?.type?.value)) {
      node = node.parentElement
    }

    if (editorData && editorData.id !== node?.attributes.id?.value) setEditorData(null);
    if (!node?.attributes) return

    if (node.classList.contains("select_all_handler")) {
      if (data.selectedRows.length > 0) {
        setData(data => ({ ...data, selectedRows: [] }));
        props.selectedRowsChanged?.([]);
        return;
      }
      const selectedRows = data.rows.map(row => row[data.columnsIndex.id]);
      props.selectedRowsChanged?.(selectedRows);
      setData(data => ({ ...data, selectedRows }))
    }

    const type = node.attributes.type?.value
    const col = Number(node.attributes.colndx?.value)
    const row = Number(node.attributes.row?.value)
    if (type === 'editor' || isNaN(col) || isNaN(row)) return

    if (type === 'checkbox' && data.selectedRows) { // selected
      const id = data.rows[row][data.columnsIndex.id]
      const d = { ...data }
      const ndx = d.selectedRows.indexOf(id)
      ndx == -1 ? (props.singleRowSelect ? d.selectedRows = [id] : d.selectedRows.push(id)) : d.selectedRows.splice(ndx, 1)
      props.selectedRowsChanged?.(d.selectedRows)
      setData(d)
      return
    }

    if (!props.editorOk || type !== 'data') return
    if (window?.getSelection()?.toString().length !== 0) return

    const column = data.columns[col]
    if (column.data.properties.isEditable !== true) return
    if (props.isAllowEdit && props.isAllowEdit(type, column, data.rows[row]) !== true) return

    const editorOk = (value) => {
      if (data.rows[row][col] === value || value === undefined || value === null) return;
      data.rows[row][col] = value
      const id = data.rows[row][data.columnsIndex.id]
      props.editorOk && props.editorOk(id, data.rows[row], data.columns[col], value)
    }
    setEditorData({ id: node.id, type, col, rowId: data.rows[row][data.columnsIndex.id], customEditRenderer: column?.data?.properties?.customEditRenderer, row, data, editorOk, editorCancel: () => setEditorData(null) })
  }

  return data ? (
    <div onClick={onClick} style={{ ...{ display: 'flex', width: '100%', flexGrow: 1, padding: '1em 0em 0em 1em' }, ...props.style }}>
      <AutoSizer>
        {({ width, height }) => (
          <div style={{ width, height }}>
            <FixedSpanGrid
              editorData={editorData}
              cellRendererContext={props.cellRendererContext}
              withTypeName={props.withTypeName}
              data={data}
              tableState={data}
              width={width}
              height={height - 1}
              onSectionRendered={onSectionRendered}
            />
          </div>
        )}
      </AutoSizer>
    </div>
  ) : (
  error ? <span>{error}</span> : <ContourBackdrop />
  )
}

const mapStateToProps = function (state) {
  return {
    api: state.projectAPI,
    db: state.databaseAPI,
    changed: state.changed,
    current: state.current
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setError: (text) => dispatch({ type: 'SET_API_ERROR', value: { message: text } })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(TableView))
