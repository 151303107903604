import React from "react";
import { TableCell } from "@mui/material";

import { TooltipR } from "shared/ui/ToolBar";
import { QuestionInterface } from "storages/QuizStorage/types";

import { ReactComponent as ErrorIcon } from "@mdi/svg/svg/information-outline.svg";

interface QuestionTitleInterface {
  question: QuestionInterface;
  error: boolean;
}

function QuestionTitle({ question, error }: QuestionTitleInterface) {
  return (
    <TableCell component="th" scope="row" title={question.question_description}>
      <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
        <div dangerouslySetInnerHTML={{ __html: question.value }} />
        {error && (
          <TooltipR text={question.error_message}>
            <ErrorIcon fill="#d32f2f" />
          </TooltipR>
        )}
      </div>
    </TableCell>
  );
}

export default React.memo(QuestionTitle);
