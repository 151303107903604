import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react'
import { withTheme, makeStyles } from '@mui/styles'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { Trans } from '@lingui/react';
import clsx from 'clsx'
import { useTheme } from "@mui/material/styles";

import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/es';
import 'moment/locale/ar';

import { cellsRenderer } from './core/cells'
import { geometry, geometry_rh } from './core/geometry'

import { editorComponent } from 'components/itemDialog/components'

import { Icon, TooltipR } from 'shared/ui/ToolBar'
import { ReactComponent as IconCheckBoxOn } from '@mdi/svg/svg/checkbox-marked-outline.svg'
import { ReactComponent as IconCheckBoxOff } from '@mdi/svg/svg/checkbox-blank-outline.svg'
import { ReactComponent as IconRowNumber } from '@mdi/svg/svg/format-list-bulleted.svg'
import { ReactComponent as AcceptIcon } from '@mdi/svg/svg/check.svg'
import { ReactComponent as CloseIcon } from '@mdi/svg/svg/close.svg'

const useStyles = makeStyles(
  theme => ({
    common_edit: {
      alignItems: 'center',
      display: 'flex',
      padding: '0.2em 0.5em',
      boxSizing: 'border-box',
      border: `2px ${theme.palette.divider} solid`,
      boxShadow: `0 2px 6px 2px rgb(60 64 67 / 15%)`,
      zIndex: 10,
      '& .MuiInput-underline:before': {
        border: "0 !important"
      }
    },
    common: {
      display: 'flex',
      padding: 'calc(1px + 0.2em) calc(1px + 0.5em)', // 1px для рамки в 2px при редактирование
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.divider}`,
    },
    selectable: {
      cursor: "pointer"
    },
    haxis: {
      backgroundColor: theme.palette.toolbar.background,
      border: `1px solid ${theme.palette.divider}`,
    },
    vaxis: {
      backgroundColor: theme.palette.toolbar.background,
      border: `1px solid ${theme.palette.divider}`,
    },
    data: {
      backgroundColor: theme.palette.background.paper,
    },
    hdiv: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    vdiv: {
      height: '100%',
      width: '100%',
      display: 'flex',
      textAlign: 'end',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    idiv: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'top',
    },
    ispan: {
      overflow: 'hidden',
    },
    ispanData: {
      whiteSpace: 'nowrap'
    },
    ispanSmall: {
      fontSize: '1em',
      fontWeight: '300',
      whiteSpace: 'nowrap',
    },
    ispanBig: {
      fontSize: '1em',
      fontWeight: '500',
    },
    isspanWithIcon: {
      marginLeft: '0.2em',
    },
    ispanRight: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    resizeColumn: {
      display: 'flex',
      zIndex: 100
    },
    resizeHandler: {
      flexGrow: 1,
      backgroundColor: 'transparent',
      cursor: 'ew-resize',
    }
  }))

const ScrollArea = ({ classes, data, topLeft, visible, cellRenderer }) => {
  const [container, setContainer] = React.useState(null);
  const [top, setTop] = React.useState(37);

  const m = useMemo(() => cellsRenderer(null, classes, data, topLeft, visible, cellRenderer), [cellRenderer, classes, data, topLeft, visible])

  React.useEffect(() => {
    if (!container || !data) return;
    const firstCell = container.children[0];
    if (!firstCell || !firstCell.id.startsWith(data.id)) return;
    setTop(firstCell.style.height);
  }, [container, data]);

  if (!data) return null;

  return(
    <div ref={setContainer} style={{ width: 0, height: 0, top: 0, left: 0, position: 'sticky' }} >
      {m}
      {data.rows.length === 0 && <div style={{ position: "absolute", width: 200, top }}>No data to display</div>}
    </div>
  );
};

const EditorContent = React.memo(({ ndx, classes, keys, style, bindData, editorData }) => {
  const theme = useTheme();

  const [value, setValue] = useState({ value: editorData.data.rows[editorData.row][editorData.col] })

  const valueRef = useRef(value.value)

  const column = editorData.data.columns[editorData.col].data
  const type = column?.properties.type

  const i = { value: 'value', type: type === 'number' ? 'text': type, tooltip: null, data: { lines: null } }

  const handleChange_v2 = React.useCallback((object, v, event, val) => {
    const temp = val !== undefined ? val : event.target.value
    const value = temp === undefined ? null : temp
    valueRef.current = value
    setValue({ value })
  }, [])

  const handleOnEnter = React.useCallback(() => {
    editorData.editorOk && editorData.editorOk(valueRef.current)
    valueRef.current = null
    return false
  }, [editorData]);

  const handleOnCancel = React.useCallback(() => {
    valueRef.current = null
    editorData.editorCancel && editorData.editorCancel()
  }, [editorData]);

  const inputProps = React.useMemo(() => ({ 
    inputProps: { 
      style: { 
        padding: '0.25em' 
      } 
    }, 
    style: { 
      padding: '0.5em 0.25em' 
    }, 
    variant: 'standard', 
    fullWidth: true, 
    size: 'small', 
    inCell: true 
  }), []);

  const dataProps = React.useMemo(() => ({ 
    disabled: false, 
    onChange_v2: handleChange_v2, 
    onEnter: handleOnEnter, 
    onCancel: handleOnCancel, 
    value, 
    style: 'compact' 
  }), [handleChange_v2, handleOnCancel, handleOnEnter, value]);
  
  return (
    <div
      className={clsx(classes.common_edit, classes.data)}
      {...bindData}
      id={keys}
      key={keys}
      style={{ ...style, ...{ height: '100%', overflow: 'auto' } }}
    >
      {editorData.customEditRenderer
        ? editorData.customEditRenderer({i, ndx, inputProps, dataProps, handleChange_v2, value, theme}, editorData.rowId)
        : editorComponent(i, ndx, inputProps, dataProps, handleChange_v2, null, value, null, null, theme)}
    </div>
  )
});

const CellContent = React.memo(({ tableState, classes, keys, style, cell, head, rowIndex, bindData, fixed }) => {
  const theme = useTheme();
  const locale = useSelector(state => state.locale);

  const data = React.useMemo(() => {
     if (head?.data?.properties.customCellRenderer) {
      return (cell !== null && cell !== undefined) ? (
        <div>
          {head?.data.properties.customCellRenderer(cell, rowIndex, Object.fromEntries(tableState.columns.map(({ name }, i) => [name, tableState.rows[rowIndex][i]])), head)}
        </div>
      ) : null
    }
    if (head?.data?.properties.type === 'Boolean') {
      return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {!!cell ? <AcceptIcon style={{ fill: theme.palette.action.active }} /> : <CloseIcon style={{ fill: theme.palette.action.active }} />}
        </div>
      );
    }
    if (head?.data?.properties.type === 'Interval') {
      return cell ? `${cell.hours || 0}:${cell.minutes || 0}:${cell.seconds || 0}` : ''
    }
    if (head?.data?.properties.type === 'DateTime') {
      return cell ? moment(cell).locale(locale).format('lll') : ''
    }
    if (head?.data?.properties.type === 'Date') {
      return cell ? moment(cell).locale(locale).format('ll') : ''
    }
    if (head?.data?.properties.type === 'Image') {
      return cell 
        ? <img style={{ objectFit: "contain", width: "28px", height: "28px" }} src={cell} alt={cell} /> 
        : <div style={{ display: "flex", width: "28px", height: "28px" }} />
    }
    if (head?.dataTypeID === 1082) {
      return moment(cell).locale(locale).format('LL')
    }

    return !isNil(cell) ? cell instanceof Object === true ? cell.toString() : cell : ''
  }, [cell, head, locale, rowIndex, tableState, theme]);

  const alignRight = React.useMemo(() => {
    if (head?.data?.properties.type === "DateTime") return true;
    switch (head?.dataTypeID) {
      case 1082:
      case 20:
      case 21:
      case 23:
      case 700:
        return true
      default:
        return false;
    }
  }, [head]);

  const content = React.useMemo(() => (
    <div
      className={clsx(classes.common, fixed ? classes.vaxis : classes.data)}
      {...bindData}
      id={keys}
      key={keys}
      style={style}
    >
      <div className={classes.idiv}>
        <span className={alignRight ? classes.ispanRight : classes.ispan} style={{ whiteSpace: head?.data?.properties.type === 'DateTime' ? 'nowrap' : 'break-spaces' }}>
          {data}
        </span>
      </div>
    </div>
  ), [alignRight, bindData, classes, data, fixed, head, keys, style]);

  const tooltipMessage = head?.data?.properties?.tooltip;
  return tooltipMessage ? <TooltipR text={tooltipMessage} >{content}</TooltipR> : content;
});

const CellContentCheckBoxHeader = React.memo(({ classes, keys, style, bindData }) => (
  <TooltipR text={<Trans id="table.select_all" />} placement="bottom">
    <div
      className={clsx(classes.common, classes.selectable, classes.haxis, "select_all_handler")}
      {...bindData}
      id={keys}
      key={keys}
      style={style}
    >
      <div className={classes.vdiv}>
        <span className={classes.ispan}>
          {Icon('action', IconCheckBoxOn, '1.5em', null, { fontSize: '1em' })}
        </span>
      </div>
    </div>
  </TooltipR>
));

const CellContentRowNumberHeader = React.memo(({ classes, keys, style, bindData }) => (
  <div
    className={clsx(classes.common, classes.haxis)}
    {...bindData}
    id={keys}
    key={keys}
    style={style}
  >
    <div className={classes.vdiv}>
      <span className={classes.ispan}>
      {Icon('action', IconRowNumber, '1.5em', null, { fontSize: '1em' })}
      </span>
    </div>
  </div>
));

const HAxisContent = React.memo((props) => {
  const { classes, keys, style, head, bindData } = props
  return (
    <div
      className={clsx(classes.common, classes.haxis)}
      {...bindData}
      id={keys}
      key={keys}
      style={style}
    >
      <div className={classes.hdiv}>
        <span className={classes.ispanBig}>
          {head.title || head.name || '-'}
        </span>
        {head.title &&
          <span className={classes.ispanSmall}>
            {head.name || '-'}
          </span>
        }
        {props.withTypeName !== false &&
          <span className={classes.ispanSmall}>
            {`[${(head.typename || '-')}]`}
          </span>
        }
      </div>
    </div>
  )
});

const CellContentCheckBox = React.memo(({ classes, keys, style, id, columnIndex, bindData, tableState }) => {
  const theme = useTheme();

  const data = React.useMemo(() => {
    const isOn = columnIndex === 0 && tableState.selectedRows?.indexOf(id) !== -1
    return Icon('action', isOn ? IconCheckBoxOn : IconCheckBoxOff, '1.5em', theme.palette.action[isOn ? 'checkbox' : 'active'], { fontSize: '1em' })
  }, [columnIndex, id, tableState, theme]);

  const styles = React.useMemo(
    () => ({ ...style, ...(columnIndex === 0 ? { borderLeft: 'none', cursor: 'pointer' } : {}), ...{ padding: '0 0.5em' } }), 
    [columnIndex, style]
  );

  return (
    <div
      className={clsx(classes.common, classes.vaxis)}
      {...bindData}
      id={keys}
      key={keys}
      style={styles}
    >
      <div className={classes.vdiv}>
        <span className={classes.ispan}>
          {data}
        </span>
      </div>
    </div>
  )
});

const CellContentRowNumber = React.memo(({ classes, keys, style, columnIndex, rowIndex, bindData }) => {
  const styles = React.useMemo(
    () => ({ ...style, ...(columnIndex === 0 ? { borderLeft: 'none', cursor: 'pointer' } : {}) }), 
    [columnIndex, style]
  );

  return (
    <div
      className={clsx(classes.common, classes.vaxis)}
      {...bindData}
      id={keys}
      key={keys}
      style={styles}
    >
      <div className={classes.vdiv}>
        <span className={classes.ispan}>
          {rowIndex + 1}
        </span>
      </div>
    </div>
  )
});

const FixedSpanGrid = withTheme((props) => {
  const [isLoading, setLoading] = useState(true)

  const [data, setData] = useState(null)
  const [widths] = useState([])
  const [heights] = useState([])
  const [topLeft, setTopLeft] = useState({ top: 0, left: 0 })

  const visible = useRef({ column: 30, row: 30 })
  const classes = useStyles()

  useEffect(() => {
    const data = props.data

    data.fixedRowCount = 1
    data.fixedColCount = data.columnsIndex.fixedCols.length
    data.colCount = data?.columnsIndex.indexes.length
    data.rowCount = data?.rows?.length

    setData(data)
    setLoading(false)
  }, [props.data])

  const onResizeMouseUp = React.useCallback((bind, event) => {
    window.document.body.style.removeProperty('cursor')
    window.removeEventListener('mousemove', bind.onResizeMouseMove)
    window.removeEventListener('mouseup', bind.onResizeMouseUp)

    const { head, startX } = bind
    const z = event.clientX - startX
    if (!z) head.data.properties.width = undefined
  }, []);
  
  const onResizeMouseMove = React.useCallback((bind, event) => {
    if (event.buttons === 0) {
      onResizeMouseUp(bind, event)
      return
    }

    const { head, startX, startWidth } = bind
    const z = event.clientX - startX
    head.data.properties.width = startWidth + z

    setTopLeft({ ...topLeft })
  }, [onResizeMouseUp, topLeft]);
  
  const onResizeStart = React.useCallback((head, columnIndex, event) => {
    if (event.button !== 0) return;
    window.document.body.style.cursor = 'ew-resize';

    const bind = { head, columnIndex, startX: event.clientX, startWidth: widths[columnIndex] }

    bind.onResizeMouseMove = onResizeMouseMove.bind(null, bind)
    bind.onResizeMouseUp = onResizeMouseMove.bind(null, bind)
    window.addEventListener('mousemove', bind.onResizeMouseMove)
    window.addEventListener('mouseup', bind.onResizeMouseUp)
  }, [onResizeMouseMove, widths]);

  const ResizeHandlerContent = React.useCallback((props) => {
    const { classes, keys, style, head, columnIndex, bindData } = props
    if (!head) return null;
    return (
      <div
        className={classes.resizeColumn}
        {...bindData}
        id={keys}
        key={keys}
        style={style}
        onMouseDown={head?.data ? onResizeStart.bind(null, head, columnIndex) : null}
      >
        <div className={classes.resizeHandler} />
      </div>
    );
  }, [onResizeStart]);

  const columnInfo = React.useCallback((columnIndex) => {
    const fixedCol = data.fixedColCount || 0
    let columnNdx
    let fixed
    if (columnIndex >= fixedCol) {
      columnIndex -= fixedCol
      columnNdx = data.columnsIndex.indexes[columnIndex]
      fixed = false
    } else {
      columnNdx = data.columnsIndex.fixedCols[columnIndex]
      fixed = true
    }
    const head = data.columns[columnNdx]
    return { fixed, columnIndex, head, columnNdx }
  }, [data]);

  const cellRenderer = React.useCallback((coord, classes, tableState, { columnIndex, key, rowIndex, style, bindData }, update) => {
    let cell = null
    let id = null
    let Type = null
    let typeName = null

    const fixedRow = data.fixedRowCount || 0

    const { columnNdx, fixed, head } = columnInfo(columnIndex)

    if (key === props.editorData?.id) {
      typeName = 'editor'
      return <EditorContent key={key} ndx={key} tableState={data} classes={classes} bindData={{ ...bindData, ...{ row: rowIndex, col: columnIndex, type: typeName } }} keys={key} cell={cell} head={head} style={style} editorData={props.editorData} />
    }

    if (rowIndex === -1) {
      Type = ResizeHandlerContent
      typeName = 'resize'
      cell = head
    } else
      if (rowIndex < fixedRow) {
        switch (columnNdx) {
          case -2: {
            Type = CellContentCheckBoxHeader
            typeName = 'checkbox'
            break
          }
          case -3: {
            Type = CellContentRowNumberHeader
            typeName = 'rownumber'
            break
          }
          default: {
            Type = props.cellRendererContext?.HAxisContent || HAxisContent
            break
          }
        }
        typeName = 'ha'
        cell = head
      } else {
        rowIndex -= fixedRow
        switch (columnNdx) {
          case -2: {
            Type = CellContentCheckBox
            typeName = 'checkbox'
            break
          }
          case -3: {
            Type = CellContentRowNumber
            typeName = 'rownumber'
            break
          }
          default: {
            Type = props.cellRendererContext?.CellContent || CellContent
            typeName = 'data'
            cell = data.rows[rowIndex][columnNdx]
            break
          }
        }
        id = data.rows[rowIndex][data.columnsIndex.id]
      }

    return <Type fixed={fixed} key={key} withTypeName={props.withTypeName} tableState={data} classes={classes} bindData={{ ...bindData, ...{ row: rowIndex, col: columnIndex, type: typeName, colndx: columnNdx } }} keys={key} cell={cell} head={head} id={id} style={style} columnIndex={columnIndex} rowIndex={rowIndex} />
  }, [ResizeHandlerContent, columnInfo, data, props.cellRendererContext, props.editorData, props.withTypeName]);

  const measure_w = React.useCallback((xm, ym, y1, y2, x1, x2) => {
    x2 = Math.min(x2, xm)
    y2 = Math.min(y2, ym)
    for (let i = x1; i < x2; i++) {
      const columnIndex = i
      const { head } = columnInfo(columnIndex)
      if (head?.data?.properties.width) {
        widths[i] = head.data.properties.width + 1
      } else {
        for (let k = y1; k < y2; k++) {
          const rowIndex = k
          const key = `${data.id}:${columnIndex}-${rowIndex}`
          const node = window[key]
          if (node && node.style.width === "auto") {
            widths[i] = Math.max(widths[i] || 0, Math.ceil(node.offsetWidth) + 1)
          }
        }
      }
    }
  }, [columnInfo, data, widths]);

  const measure_setW = React.useCallback((xm, ym, y1, y2, x1, x2) => {
    x2 = Math.min(x2, xm)
    y2 = Math.min(y2, ym)
    for (let k = y1; k < y2; k++) {
      const rowIndex = k
      for (let i = x1; i < x2; i++) {
        const columnIndex = i
        const width = widths[columnIndex]

        const key = `${data.id}:${columnIndex}-${rowIndex}`
        const node = window[key]
        if (node && node.style.width === "auto") {
          let nodeWidth = width
          const spanx = Number(node.attributes.spanx.value)
          for (let x = 1; x < spanx; x++)
            nodeWidth += widths[columnIndex + x] - 1 || 0
          node.style.width = nodeWidth + 'px'
          node.style.maxWidth = null
          node.style.whiteSpace = null
        }
      }
    }
  }, [data, widths]);

  const measure_h = React.useCallback((xm, ym, y1, y2, x1, x2) => {
    x2 = Math.min(x2, xm)
    y2 = Math.min(y2, ym)
    for (let k = y1; k < y2; k++) {
      const rowIndex = k
      for (let i = x1; i < x2; i++) {
        const columnIndex = i
        const key = `${data.id}:${columnIndex}-${rowIndex}`
        const node = window[key]
        if (node && node.style.height === 'auto') {
          heights[k] = Math.max(heights[k] || 0, Math.ceil(node.offsetHeight))
        }
      }
    }
  }, [data, heights]);
  
  useLayoutEffect(() => {
    if (!data) return

    const top = topLeft.top
    const left = topLeft.left

    const hW = data.fixedRowCount || 0
    const vW = data.fixedColCount || 0

    const xm = vW + data?.colCount 
    const ym = hW + data?.rowCount 

    measure_w(xm, ym, 0, hW, 0, vW) // Fixed Horizontal axis
    measure_w(xm, ym, 0, hW, vW + left, vW + left + visible.current.column) // Horizontal axis
    measure_w(xm, ym, hW + top, hW + top + visible.current.row, 0, vW) // Vertical axis
    measure_w(xm, ym, hW + top, hW + top + visible.current.row, vW + left, vW + left + visible.current.column)

    measure_setW(xm, ym, 0, hW, 0, vW) // Fixed Horizontal axis
    measure_setW(xm, ym, 0, hW, vW + left, vW + left + visible.current.column) // Horizontal axis
    measure_setW(xm, ym, hW + top, hW + top + visible.current.row, 0, vW) // Vertical axis
    measure_setW(xm, ym, hW + top, hW + top + visible.current.row, vW + left, vW + left + visible.current.column)

    measure_h(xm, ym, 0, hW, 0, vW) // Fixed Horizontal axis
    measure_h(xm, ym, 0, hW, vW + left, vW + left + visible.current.column) // Horizontal axis
    measure_h(xm, ym, hW + top, hW + top + visible.current.row, 0, vW) // Vertical axis
    measure_h(xm, ym, hW + top, hW + top + visible.current.row, vW + left, vW + left + visible.current.column)

    const pos = { x: 0, y: 0, startX: 0, startY: 0 }
    for (let i = 0; i < vW; i++)
      pos.startX += widths[i] - 1
    for (let i = 0; i < hW; i++)
      pos.startY += heights[i] - 1

    let lastVisibleColumn = vW + left
    for (let w = pos.startX; lastVisibleColumn < xm && w < props.width; lastVisibleColumn++)
      w += widths[lastVisibleColumn] - 1

    let lastVisibleRow = hW + top
    for (let h = pos.startY; lastVisibleRow < ym && h < props.height; lastVisibleRow++)
      h += heights[lastVisibleRow] - 1

    // height for resize handlers
    heights[-1] = props.height

    // resize handlers
    geometry_rh(xm, ym, data, heights, widths, -1, 0, vW + left, vW + left + visible.current.column, pos, lastVisibleColumn, lastVisibleRow)

    // Horizontal axis
    pos.y = 0
    geometry(xm, ym, data, heights, widths, 0, hW, vW + left, vW + left + visible.current.column, pos, lastVisibleColumn, lastVisibleRow)

    // Fixed resize handlers
    pos.startX = 0
    pos.x = 0
    pos.y = 0
    geometry_rh(xm, ym, data, heights, widths, -1, 0, 0, vW, pos, lastVisibleColumn, lastVisibleRow)

    // FixedHorizontal axis
    pos.startX = 0
    pos.x = 0
    pos.y = 0
    geometry(xm, ym, data, heights, widths, 0, hW, 0, vW, pos, lastVisibleColumn, lastVisibleRow)

    // Vertical axis
    pos.startX = 0
    const dataPos = { x: 0, y: pos.y, startX: 0 }
    geometry(xm, ym, data, heights, widths, hW + top, hW + top + visible.current.row, 0, vW, pos, lastVisibleColumn, lastVisibleRow)
    // Facts
    dataPos.x = dataPos.startX = pos.x
    geometry(xm, ym, data, heights, widths, hW + top, hW + top + visible.current.row, vW + left, vW + left + visible.current.column, dataPos, lastVisibleColumn, lastVisibleRow)

    props.onSectionRendered && props.onSectionRendered({ startIndex: top, stopIndex: lastVisibleRow - hW })

    const update = visible.current.column < lastVisibleColumn - left || visible.current.row < lastVisibleRow - top
    visible.current = { column: lastVisibleColumn - left, row: lastVisibleRow - top }
    update && setTopLeft({ ...topLeft })
  }, [columnInfo, data, heights, measure_h, measure_setW, measure_w, props, topLeft, widths]);
  
  const handleTableScroll = React.useCallback((event) => {
    if (event.nativeEvent.target?.id !== `${data?.id}:scroller`) return;
    const top = Math.max(0, Math.floor(event.nativeEvent.target.scrollTop / 100));
    const left = Math.max(0, Math.floor(event.nativeEvent.target.scrollLeft / 100));
    if (topLeft.top === top && topLeft.left === left) return;
    setTopLeft({ top, left });
  }, [data, topLeft]);
  
  const containerStyles = React.useMemo(() => ({ 
    display: 'flex', 
    flexDirection: 'column', 
    width: props.width, 
    height: props.height 
  }), [props.height, props.width]);

  const tableCntainerStyles = React.useMemo(() => ({
    WebkitTransform: 'translate3d(0px, 0px, 0px)',
    display: 'flex',
    width: props.width + ((data?.colCount || 1) - 1) * 100,
    height: props.height + ((data?.rowCount || 1) - 1) * 100, fontSize: '13px'
  }), [data, props.height, props.width]);

  return (
    <div style={containerStyles} >
      {isLoading &&
        (
          <div style={{
            zIndex: 100,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            position: 'absolute',
            display: 'flex',
            height: props.height,
            width: props.width,
            alignItems: 'center',
            justifyContent: 'center'
          }}
          >
            <CircularProgress color='primary' size={'32px'} />
          </div>
        )
      }
      <div
        className={'hide-scrollbars'}
        id={`${data?.id}:scroller`}
        onScroll={handleTableScroll}
        style={{ display: 'block', height: '100%', width: '100%', overflow: 'auto' }}
      >
        <div id={`${data?.id}:sizer`} style={tableCntainerStyles}>
          <ScrollArea classes={classes} data={data} topLeft={topLeft} visible={visible} cellRenderer={cellRenderer} />
        </div>
      </div>
    </div>

  )
})

export default React.memo(FixedSpanGrid);
