import React, { useState, useRef } from 'react'
import Paper from '@mui/material/Paper'
import Draggable from 'react-draggable'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import { Trans } from '@lingui/react'
import cn from 'classnames'
import CircularProgress from '@mui/material/CircularProgress'

import clsx from 'clsx';

import { ReactComponent as IconCheck } from '@mdi/svg/svg/check.svg'
import { ReactComponent as IconCancel } from '@mdi/svg/svg/window-close.svg'

import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'

import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import VirtualAutocomplete from './VirtualAutocomplete'

import { Button, TooltipR } from './ToolBar'
import { projectItemIconComponent } from 'shared/utils'

import { Button as MuiButton, Chip } from '@mui/material'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import withTheme from '@mui/styles/withTheme';
import { useTheme } from '@mui/material/styles'

import Backdrop from '@mui/material/Backdrop'

import { Tab, Tabs } from 'shared/ui/TabBar'
import { ReactComponent as IconClose } from '@mdi/svg/svg/close.svg';
import { Icon } from 'shared/ui/ToolBar'
import styled from 'styled-components'

const useStyles = makeStyles(theme => ({
  tab: {
    maxWidth: 'none',
    backgroundColor: theme.palette.background,
    '&:hover $tabLabel $closeIndicator': {
      visibility: 'visible'
    }
  },
  tabLabel: {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    minHeight: 24,
    justifyContent: 'space-between',
    width: 'calc(100% - 1em)',
    margin: '0.25em 0.5em'
  },
  closeIndicator: {
    visibility: 'hidden'
  },
  closeIndicatorCurrent: {
    visibility: 'visible'
  }
}))

const styles = (theme) => ({
  inputRoot: {
    '&.Mui-focused $clearIndicatorDirty': {
      visibility: 'visible',
    },
    /* Avoid double tap issue on iOS */
    '@media (pointer: fine)': {
      '&:hover $clearIndicatorDirty': {
        visibility: 'visible',
      },
    },
  },
  primaryText: {
    color: theme.palette.text.primary + " !important"
  },
  dialogTitle: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,
    fontWeight: 400,
    fontSize: '1.3em',
    padding: '0.5em',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  input: {
    //    fontSize: `${theme.typography.fontSize}.px`, надо больше,и одинаковый во всех элементах ввода [autocomplete, ...]
    padding: '0.5em 0.25em',
    '& .MuiInputBase-root': {
    },
    '& .MuiFormHelperText-root': {
      fontSize: '0.85em'
    },
  },

  input_cell: {
    height: '100%',
    padding: '0',
    '& .MuiInputBase-root': {
      paddingTop: '0'
    },
    '& .MuiFormHelperText-root': {
      fontSize: '0.85em'
    }
  },

  clearIndicator: {
    marginRight: -2,
    padding: 4,
    visibility: 'hidden',
  },
  /* Styles applied to the clear indicator if the input is dirty. */
  clearIndicatorDirty: {},

})

const textFieldStyles = (theme) => ({
  root: {
    display: 'block',
    '& .MuiOutlinedInput-input': {
      "&:-webkit-autofill": {
        borderRadius: 0,
        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
      },
    },
    '& .MuiInputAdornment-root': {
      margin: 0
    },
    '& .MuiOutlinedInput-notchedOutline': {
      '& legend': {
        fontSize: '0.95em', // влияет на правое поле между текстом и рамкой
        marginInlineStart: 0,
        marginInlineEnd: 'auto'
      },
    },
    '& .MuiInputBase-input': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-root': {
      display: "flex",
      gap: 8,
      paddingLeft: "14px !important",
      paddingRight: "14px !important",
      '& fieldset': {
        borderColor: theme.palette.divider,
        borderWidth: '1px'
      },
      '&:hover fieldset': {
        borderColor: theme.palette.warning.main,
        borderWidth: '1px'
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.info.main,
        borderWidth: '1px'
      },
      '& .MuiAutocomplete-endAdornment': {
        insetInlineEnd: '9px',
        insetInlineStart: 'auto'
      }
    },

    '& .MuiAutocomplete-endAdornment': {
      top: 'unset'
    },

    '& .MuiInputLabel-root': {
      transform: 'translate(0, 0)',  // RTL - LTR +
      fontSize: '0.85em',
      insetInlineEnd: 'auto',
      insetInlineStart: '0',
      paddingInlineStart: '1.5em'
    },

    '& .MuiInput-underline': {
      marginTop: 0,
    },

    '& .MuiInput-underline:after': {
      borderBottom: '0px solid ' + theme.palette.success.dark,
    },

    '& .MuiInput-underline:before': {
      borderBottom: '0px dotted ' + theme.palette.text.hint,
    },

    '& .MuiInput-underline:hover:before': {
      borderBottom: '0px solid ' + theme.palette.success.dark,
    },
  },
})

const CustomChip = styled(Chip)`
  gap: 4px;
  padding: 0 8px;
  svg {
    margin: 0 !important;
  }
  span {
    padding: 0 !important;
  }
`;

const CustomDialogActions = styled(DialogActions)`
  gap: 8px;
  padding: 1em 2.5em;
  &>:not(:first-of-type) {
    margin: 0;
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: ${({ maxWidth }) => maxWidth || "40%"};
    min-height: ${({ minHeight }) => minHeight || "unset"};
    
    @media (max-width: 2550px) {
      max-width: ${({ maxWidth }) => maxWidth || "600px"};
    }
    
    @media (max-width: 768px) {
      margin: 10px;
      max-width: calc(100% - 20px);
      max-height: calc(100% - 20px);
    }
  }

  .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    
    p {
      flex-grow: 0 !important;
    }

    svg {
      width: 1.25vw !important;
      height: 1.25vw !important;

      @media (max-width: 2550px) {
        width: 1em !important;
        height: 1em !important;
      }
    }

    .MuiTypography-root {
      font-size: 0.7vw !important;
      padding: 0.4vw 0.25vw;
      
      @media (max-width: 2550px) {
        padding: 0;
        font-size: 17px !important;
      }
    }
  }
`;

const StyledSwitch = styled(Switch)`
  margin: 0.25vw 0;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0.5vw 1vw;

  @media (max-width: 2550px) {
    padding: 1.5em 2em;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const DialogTitleContainer = styled("div")`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

const StyledTab = withTheme(styled(Tab)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  margin: 0 !important;
  border-right: 0 !important;
`);

const CssTextField = withStyles(textFieldStyles, { withTheme: true })(TextField);

const InputComponent = ({ inputRef, ...other }) => <div style={{ height: '100%' }} {...other} />;

const OutlinedDiv = withTheme((props) => { // ({ children, label, tooltip }) => {
  const { children, label, disabled, theme, tooltip } = props

  return (
    <TooltipR text={tooltip}>
      <Paper
        style={{ display: 'flex', flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative', boxShadow: '0 1px 4px 0 rgb(0 0 0 / 37%)' }}
        disabled={disabled === true}
      >
        <div style={{ position: 'absolute', left: '1em', top: '-0.75em', padding: '0.25em 0.5em', backgroundColor: props.theme.palette.background.paper, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100%' }}>
          <div className={'MuiFormLabel-root'} style={{ transform: 'scale(0.85)', color: theme.palette.text.secondary }} >{label}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', marginTop: '1em', marginBottom: '0.5em' }} >
          {children}
        </div>
      </Paper>
    </TooltipR>
  )
})
/*
    <CssTextField
        required={required}
        fullWidth={props.fullWidth || false}
        variant={props.variant || 'outlined'}
        error={error === true}
        helperText={helperText}
        className={classes.input}
        label={label}
        style={style || {}}
        disabled={disabled === true}
        multiline
        InputLabelProps={{ shrink: true, style: { zIndex: 0 } }}
        InputProps={InputProps}
        inputProps={{ children: children }}
    />
*/

const ActionInput = withStyles(styles)((props) => {
  const { id, size, style, required, placeholder, textFieldProps, helperText, error, select, value, owner, v, Icon, label, type, classes, autoFocus, disabled, endInputAdornment, startInputAdornment, tooltip } = props;
  const isSelect = select != null
  const theme = useTheme()
  const val = (typeof v === 'function' ? v('get', value) : value[v]) || '';
  const onChange = props.onChange ? props.onChange.bind(owner, v) : props.onChange_v2 && props.onChange_v2.bind(owner, value, v)
  const [showPassword, setShowPassword] = useState(false)

  const inputRef = useRef()

  const SwitchComponent = React.forwardRef(function(options, ref) {
    const { inputRef, onBlur, onFocus, value, id, ...other } = options
    return (
      <Grid component="label" container alignItems="center" spacing={1} style={{ marginLeft: props.switchOffText ? '0.5em' : 0, marginRight: props.switchOnText ? '0.5em' : 0 }} >
        <Grid item>{props.switchOffText}</Grid>
        <Grid item>
          <StyledSwitch
// onBlur not work with onChange
// TODO: get if offf from TextField
//            onBlur={(e) => { console.log('ONBLUR') ; onBlur(e)}}
//            onFocus={(e) => { console.log('ONFOCUS') ; onFocus(e)}}
            inputRef={inputRef}
            onChange={
              (e) => {
                onChange({ target: { value: (props.values || [false, true])[e.target.checked ? 1 : 0] } })
              }
            }
            checked={value === (props.values || [false, true])[1]}
            key={id}
            id={id}
            disabled={disabled}
            color={'primary'}
          />
        </Grid>
        <Grid item>{props.switchOnText}</Grid>
      </Grid>
    )
  })

  const cleanBtn = () => (
    <TooltipR text={<Trans id="common.clear" />}>
      <IconButton
        tabIndex={-1}
        className={clsx(classes.clearIndicator, {
          [classes.clearIndicatorDirty]: (val + '1').length > 0,
        })}
        onClick={() => {
          const value = type === 'number' ? 0 : null
          onChange({ target: { value } }, value)
          inputRef.current && inputRef.current.focus()
        }
        }
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </TooltipR>
  )
  const showPasswordBtn = () => (
    <TooltipR text={showPassword ? <Trans id="common.hide" /> : <Trans id="common.show" />}>
      <IconButton
        className={clsx(classes.clearIndicator, {
          [classes.clearIndicatorDirty]: (val + '').length > 0,
        })}
        onClick={() => setShowPassword(!showPassword)
        }
      >
        {showPassword ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
      </IconButton>
    </TooltipR>
  )

  const InputProps = {
    className: cn(classes.inputRoot, props.primaryText && classes.primaryText),
    inputComponent: type === 'switch' ? SwitchComponent : undefined,
    endAdornment: endInputAdornment ||
      <InputAdornment position='end'>
        {type != 'switch' && !textFieldProps?.multiline && val?.length > 0 && !(props.inCell === true) && (!props.disableClean && !disabled) && cleanBtn()}
        {type === 'password' && showPasswordBtn()}
      </InputAdornment>,
    startAdornment: startInputAdornment ? startInputAdornment : (Icon ? <InputAdornment position='start'><Icon fill={disabled ? theme.palette.action.disabled : theme.palette.info.main} /></InputAdornment> : null)
  }

  if (props.inCell === true) {
    // autofocus почемуто дергает таблицу если ячейка находится ближе к низу экрана
    window.requestAnimationFrame(() => {
      document.getElementById('' + id)?.focus()
    })
  }

  return (
      <CssTextField
        key={'' + id}
        id={'' + id}
        inputRef={input => { inputRef.current = input } }
        onKeyDown={(event) => {
          if (event.key == 'Escape') {
            if (props.onCancel) {
              props.onCancel()
              event.preventDefault()
            }
          }
        }}
        onKeyPress={(event) => {
          if (!event.shiftKey && event.key == 'Enter') {
            if (props.onEnter && props.onEnter() === false) {
              event.preventDefault()
            }
          }
        }}

        onBlur={(event) => {
            if (props.inCell === true && props.onEnter) {
              props.onEnter()
            }
        }}

        {...textFieldProps}
        required={required}
        fullWidth={props.fullWidth === undefined ? true : props.fullWidth}
        variant={props.variant || 'outlined'}
        error={error === true}
        helperText={helperText}
        select={isSelect}
        autoFocus={autoFocus === true}
        autoComplete={props.autoComplete}
        rows={props.rows || 3}
        multiline={props.multiline || false}
        value={val}
        onChange={(event, value) => { onChange(event, type === 'number' ? event.target.valueAsNumber : value) }}
        className={props.inCell === true ? classes.input_cell : classes.input}
        label={label}
        InputProps={InputProps}
        InputLabelProps={{ shrink: true }}
        size={props.size || 'medium'}
        inputProps={{ type: (type === 'password' && showPassword ? 'text' : type), disabled, style: { textOverflow: 'ellipsis', padding: props.inCell === true ? 0 : undefined } }}
        disabled={disabled === true}
        placeholder={placeholder}
      >
        {isSelect && select.map((option) => (
          <MenuItem key={option.name} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </CssTextField>
  )
})

const MultiActionInput = withStyles(styles)((props) => {
  const { value, owner, v, label, disabled, withCustomOption = true, size, select } = props
  const [customOption, setCustomOption] = React.useState(null);
  const val = value[v] || [];

  return (
    <VirtualAutocomplete
      multiple
      disableClearable
      disableCloseOnSelect
      disableListWrap
      limitTags={7}
      options={[...(customOption ? [customOption] : []), ...(select || [])]}
      disabled={disabled}
      value={val}
      noOptionsText="No options"
      onChange={props.onChange ? props.onChange.bind(owner, v) : props.onChange_v2 ? props.onChange_v2.bind(owner, value, v) : null}
      onInputChange={(ev, value) => {
        withCustomOption && setCustomOption(value.trim() !== "" ? {id: value, name: value} : null);
        props.onInputChange?.call(owner, value, v, ev, value);
      }}
      style={{ width: "100%" }}
      getOptionLabel={(option) => option?.name || ''}
      isOptionEqualToValue={(option, value) => value.id === option.id}
      renderTags={(value, getTagProps) =>
        value.map(({ name }, index) => (
          <CustomChip size="small" label={name} {...getTagProps({ index })} style={{ margin: 0 }} />
        ))
      }
      renderInput={(opt) => (
        <CssTextField
          {...opt}
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          inputProps={{ ...opt.inputProps, ...props.inputProps }}
          InputProps={{ ...opt.InputProps, style: { gap: 3 } }}
          style={{ padding: "0.5em 0.25em" }}
          size={size || 'medium'}
          variant={props.variant || 'outlined'}
          label={label}
        />
      )}
    />
  )
})

const SelectActionInput = withStyles(styles)((props) => {
  const { value, owner, v, label, required, disabled, onlyIdInValue, size, select } = props
  const [customOption, setCustomOption] = React.useState(null);

  const val = value[v] || null;
  const options = [...(customOption ? [customOption] : []), ...(select || [])];

  return (
    <VirtualAutocomplete
      disableClearable
      disableListWrap
      options={options}
      disabled={disabled}
      value={onlyIdInValue ? options.find(({ id }) => val === id) || null : val}
      noOptionsText="No options"
      onChange={(event, option) => {
        const optionValue = onlyIdInValue ? option.id : option;
        if (props.onChange) return props.onChange.call(owner, v, null, optionValue);
        props.onChange_v2(value, v, null, optionValue);
      }}
      onInputChange={(ev, value) => {
        props.withCustomOption && setCustomOption(value !== "" ? {id: value, name: value} : null);
        props.onInputChange?.call(owner, value, v, ev, value);
      }}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={(option, value) => value.id === option.id}
      style={{ width: "100%" }}
      renderInput={(opt) => (
        <CssTextField
          {...opt}
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          inputProps={{ ...opt.inputProps, ...props.inputProps }}
          InputProps={{ ...opt.InputProps, style: { gap: 3 } }}
          style={{ padding: "0.5em 0.25em" }}
          size={size || 'medium'}
          variant={props.variant || 'outlined'}
          label={<>{label} {required ? " *" : ""}</>}
        />
      )}
    />
  )
})

const ActionDateInput = withStyles(styles)((props) => {
  const { fullWidth = true, required, value, views, owner, v, Icon, label, type, classes, autoFocus, autoSelectDate, disabled, size, format, placeholder } = props;
  const theme = useTheme()
  const InputProps = Icon ? { startAdornment: (<InputAdornment position="start"><Icon fill={disabled ? theme.palette.action.disabled : theme.palette.info.main} /></InputAdornment>) } : {}

  const createTextField = (opt) => {
    return <CssTextField
      {...opt}
      required={required}
      style={props.style || {}}
      size={size || 'medium'}
      variant="outlined"
      fullWidth={fullWidth}
      className={classes.input}
      InputLabelProps={{ shrink: true }}
      InputProps={{ ...opt.InputProps, ...InputProps }}
      inputProps={{ ...opt.inputProps, ...{ style: { padding: props.inCell === true ? 0 : undefined }, type: type, disabled } }}
    />;
  }

  let val = (typeof v === 'function' ? v('get', value) : value[v]) || null;
  const onChange = props.onChange ? props.onChange.bind(owner, v) : props.onChange_v2 && props.onChange_v2.bind(owner, value, v)

  if (val === undefined) {
    val = autoSelectDate ? new Date() : null
    onChange(null, val)
  }

  return (
    <TooltipR text={label || val}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          emptyLabel={placeholder}
          TextFieldComponent={(params) => createTextField(params)}
          autoFocus={autoFocus === true}
          disabled={disabled}
          format={format}
          views={views}
          label={label}
          value={val}
          autoOk={true}
          onChange={(value) => { onChange(null, value) }}
          style={{ padding: '0.5em' }}
          cancelLabel={<Trans id="common.cancel" />}
          renderInput={(params) => createTextField(params)}
          okLabel={<Trans id="common.select" />}
        />
      </LocalizationProvider>
    </TooltipR>
  )
});

const ActionDateTimeInput = withStyles(styles)((props) => {
  const { fullWidth = true, value, views, owner, v, Icon, label, type, autoSetValue = true, classes, autoFocus, disabled, size, format, placeholder } = props;
  const theme = useTheme()
  const InputProps = Icon ? { startAdornment: (<InputAdornment position="start"><Icon fill={disabled ? theme.palette.action.disabled : theme.palette.info.main} /></InputAdornment>) } : {}

  const createTextField = (opt) => {
    return <CssTextField
      {...opt}
      style={props.style || {}}
      size={size || 'medium'}
      fullWidth={fullWidth}
      variant={props.variant || 'outlined'}
      InputLabelProps={{ shrink: true }}
      className={props.inCell === true ? classes.input_cell : classes.input}
      InputProps={{ ...opt.InputProps, ...InputProps }}
      inputProps={{ ...opt.inputProps, ...{ style: { padding: props.inCell === true ? 0 : undefined }, type: type, disabled } }}
    />;
  }

  const onChange = props.onChange ? props.onChange.bind(owner, v) : props.onChange_v2 && props.onChange_v2.bind(owner, value, v)
  let val = (typeof v === 'function' ? v('get', value) : value[v])
  if (val === undefined) {
    val = autoSetValue ? new Date() : null
    window.requestAnimationFrame(()=> onChange(null, val))
  }
  const openFn = props.inCell === true ? { open: true } : {}
  return (
    <TooltipR text={label || val}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          ampm={false}
          emptyLabel={placeholder}
          TextFieldComponent={createTextField}
          autoFocus={autoFocus === true}
          disabled={disabled}
          format={format}
          views={views}
          label={label}
          value={val}
          autoOk={false}
          onAccept={() => props.onEnter?.()}
          onClose={() => props.onCancel?.()}
          onChange={(value) => { onChange(null, value) }}
          style={props.inCell === true ? {} : { padding: '0.5em' }}
          cancelLabel={<Trans id="common.cancel" />}
          okLabel={<Trans id="common.select" />}
          renderInput={createTextField}
          {...openFn}
        />
      </LocalizationProvider>
    </TooltipR>
  )
})

const ActionAutocompleteInput = withStyles(styles)((props) => {
  const { value, owner, v, label, disabled, size, select, autocompleteProps } = props
  const theme = useTheme()
  const val = () => {
    const data = (typeof v === 'function' ? v('get', value) : value && v && value[v]) || null
    return typeof data === 'string' && select ? select.find(i => i.code === data) : data
  }

  const createTextField = (opt) => {
    const isize = '1.5em'
    const imageStyle = { display: 'block', objectFit: 'contain', cursor: 'pointer', maxWidth: isize, maxHeight: isize, minWidth: isize, minHeight: isize, width: 'auto', height: 'auto', fill: theme.palette.menu.icon }
    const item = val()
    const Icon = item && projectItemIconComponent(item.current?.properties?.icon, imageStyle, item.current?.type || item.type /* for stored values */)

    const InputProps = Icon ? { startAdornment: (<InputAdornment position="start">{Icon}</InputAdornment>) } : {}
    return <CssTextField {...opt}
      disabled={disabled}
      placeholder={autocompleteProps?.placeholder || ""}
      InputLabelProps={{ shrink: true }}
      inputProps={{ ...opt.inputProps, ...props.inputProps, readOnly: props.readOnly === true }}
      InputProps={{ ...opt.InputProps, ...InputProps }}
      style={props.style || {}}
      size={size || 'medium'}
      variant={props.variant || 'outlined'}
      label={label}
    />
  }
  return (
    <TooltipR text={label}>
      <VirtualAutocomplete
        {...autocompleteProps}
        disableListWrap
        limitTags={7}
        options={select || []}
        disabled={disabled}
        value={val()}
        closeText={'Close list'}
        openText={'Open list'}
        noOptionsText={'No options'}
        onChange={props.onChange ? props.onChange.bind(owner, v) : props.onChange_v2 ? props.onChange_v2.bind(owner, value, v) : null}
        onInputChange={props.onInputChange ? props.onInputChange.bind(owner, value, v) : null}
        getOptionLabel={autocompleteProps?.getOptionLabel ? autocompleteProps.getOptionLabel : (option) => option.name || ''}
        renderInput={(params) => createTextField(params)}
      />
    </TooltipR>
  )
})

const ActionDialogTitle = withStyles(styles)((props) => {
  const { children, classes, titleIcon, onOk, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <DialogTitle className={classes.dialogTitle} {...other} >
      <DialogTitleContainer>
        {titleIcon && Icon("secondary", titleIcon)}
        <Typography id='draggable-dialog-title' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre', flexGrow: 1, ...theme.typography.dialogTitle }} >{children}</Typography>
      </DialogTitleContainer>
      {onClose ? (
        <TooltipR text={<Trans id="common.close" />} placement={'bottom'}>{Button(null, IconCancel, onClose, '1em', theme.palette.primary.contrastText)}</TooltipR>
      ) : null}
      {onOk ? (
        <TooltipR text={<Trans id="common.apply" />} placement={'bottom'}>{Button(null, IconCheck, onOk, '1em', theme.palette.primary.contrastText)}</TooltipR>
      ) : null}
    </DialogTitle>
  );
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const ActionDialog = withTheme((props) => {
  const { onSelect, onResult, tabs, validate, contentStyle, titleIcon, header, children, onClose, onOk, open, direction, paperStyle, withAction, background } = props;

  const theme = props.theme
  const flexDirection = direction || 'column';
  const paperStylePart = paperStyle || {};
  const PaperProps = { square: true, style: { ...paperStylePart, padding: '0em', border: `2px solid ${background || theme.palette.primary.main}` } }
  const dialogContentStyle = contentStyle || { padding: '1.5em 2em', display: 'flex', flexDirection: 'column' }
  const [selected, setSelected] = useState(null)

  onSelect && (onSelect.current = (item) => setSelected(item))

  const style = {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeight,
    fontSize: theme.typography.fontSize,
    lineHeight: theme.typography.lineHeight,
    letterSpacing: theme.typography.letterSpacing,
    margin: "auto",
    width: props.width
  }

  return (
    <StyledDialog
      style={style}
      maxWidth={props.maxWidth || 'sm'}
      fullWidth
      onClose={onClose}
      aria-labelledby="dialog-title"
      PaperProps={PaperProps}
      PaperComponent={PaperComponent}
      open={open}
    >
      <ActionDialogTitle titleIcon={titleIcon} style={{ cursor: 'move', backgroundColor: background || theme.palette.primary.main }} onOk={!withAction && onOk} onClose={!withAction && onClose}>{props.title || ' '}</ActionDialogTitle>
      { header &&
        <DialogActions style={{ justifyContent: 'start', paddingBottom: 0 }}>
          {header}
        </DialogActions>

      }
      { tabs}
      <StyledDialogContent dividers style={dialogContentStyle} className={'scroll--itunes'} >
        {children}
      </StyledDialogContent>

      {withAction &&
        <CustomDialogActions>
          {withAction[0] !== null && onClose && <MuiButton
            variant={'contained'}
            onClick={onClose}
            style={{ backgroundColor: theme.palette.action.disabledBackground, color: theme.palette.text.primary }}
          >
            {withAction[0] || <Trans id="common.cancel" />}
          </MuiButton>}
          {withAction[1] !== null && <MuiButton
            variant={'contained'}
            disabled={validate?.() || !onOk || (onSelect && selected == null)}
            onClick={() => {
              const result = onResult?.current && onResult.current()
              onOk(selected, result)
            }}
            color={'primary'}
          >
            {withAction[1] || <Trans id="common.apply" />}
          </MuiButton>}
        </CustomDialogActions>
      }
    </StyledDialog>
  );
});

const AlertDialog = withTheme((props) => {
  const { open, title, text, onClose, onOk } = props;
  const PaperProps = { square: true, style: { padding: '0em', border: `2px solid ${props.theme.palette.primary.main}` } }
  const contentStyle = { padding: '2em 3em' }

  const theme = useTheme();
  const style = {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeight,
    fontSize: theme.typography.fontSize,
    lineHeight: theme.typography.lineHeight,
    letterSpacing: theme.typography.letterSpacing,
  }
  return (
    <Dialog disableBackdropClick style={style} maxWidth={props.maxWidth || 'sm'} fullWidth={true} onClose={onClose} aria-labelledby="dialog-title" PaperProps={PaperProps} PaperComponent={PaperComponent} open={open}>
      <ActionDialogTitle style={{ cursor: 'move' }} >{title}</ActionDialogTitle>
      <DialogContent dividers style={contentStyle} className={'scroll--itunes'} >
        {text}
      </DialogContent>

      <DialogActions style={{ padding: '1em 3em', marginRight: '0.5em' }}>
        {onOk && <MuiButton variant="contained" onClick={onClose} style={{ backgroundColor: theme.palette.action.disabledBackground }}>
          <Trans id="common.cancel" />
          </MuiButton>
        }
        <MuiButton variant="contained" onClick={onOk || onClose} color="primary">
          <Trans id="common.confirm" />
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
});

const StyledButtonBig = withStyles(theme => ({
  root: {
    padding: '1em',
    lineHeight: 1,
    textTransform: 'none',
    '&.Mui-disabled': {
      textTransform: 'none',
    }
  },
  label: {
    width: 'auto',
    position: 'relative'
  }
}), { withTheme: true })(MuiButton);

const StyledProgress = withStyles(theme => ({
  root: {
    position: 'absolute',
    left: '-2em',
    width: '1.2em',
    height: '1.2em',
    color: theme.palette.action.active,
  },
}), { withTheme: true })(CircularProgress);

const ContourBackdrop = () => {
  const theme = useTheme()
  return (
    <Backdrop style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }} open={true}>
      <CircularProgress color={'primary'} />
    </Backdrop>
  )
}

const ContourDivBackdrop = ({ loading }) => {
  const theme = useTheme()
  return loading === true ? (
    <div style={{ display: 'flex', zIndex: theme.zIndex.drawer + 1, position: 'absolute', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: '#0006' }}>
      <CircularProgress />
    </div>
  ) : null
}

const ActionDialogProperty = withTheme((props) => {
  const { item, onSelect, onResult, tabs, contentStyle, header, children, onClose, onOk, withAction } = props

  const theme = props.theme
  const dialogContentStyle = contentStyle || { padding: '1em 0.5em', display: 'flex', flexDirection: 'column' }
  const [selected, setSelected] = useState(null)

  const classes = useStyles(theme)

  onSelect && (onSelect.current = (item) => setSelected(item))

  // Надо задать width: 0, иначе на панеле слева очень длинное Title расширяет диалог во всю длину текста
  return (
    <div style={{ display: 'flex', flexDirection: 'column', background: theme.palette.background.paper, flexGrow: 1, zIndex: 1, width: '0px', ...props.dialogStyle }}>
      <StyledTab
        selected={true}
        className={classes.tab}
        icon={props.titleIcon}
        label={
          <div className={classes.tabLabel}>
            <span style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', alignSelf: 'center' }}>{item?.current?.properties?.title || props.dialogTitle}</span>
            {props.closable !== false &&
              <TooltipR text={<Trans id="common.close" />}>
                <div className={classes.closeIndicatorCurrent} onClick={onClose?.bind(null, 0)}>
                  {Icon('action', IconClose, '0.5em')}
                </div>
              </TooltipR>
            }
          </div>
        }
        id={0}
        key={0}
      />
      {header &&
        <DialogActions style={{ justifyContent: 'start', paddingBottom: 0 }}>
          {header}
        </DialogActions>

      }
      {tabs}
      <DialogContent dividers style={{ ...dialogContentStyle, borderBottom: 0, overflowY: 'auto' }} className={'scroll--itunes'} >
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '0px',
            flexGrow: 1,
          }}
          noValidate
          autoComplete="on" >
          {children}
        </form>
      </DialogContent>

      {withAction &&
        <DialogActions style={{ justifyContent: 'center' }}>
          {false /*withAction[0] !== null*/ && <MuiButton
            variant={'contained'}
            onClick={onClose}
            style={{ backgroundColor: theme.palette.action.disabledBackground }}
          >
            {withAction[0] || <Trans id="common.cancel" />}
          </MuiButton>}
          {withAction[1] !== null && <MuiButton
            variant={'contained'}
            disabled={!onOk || (onSelect && selected == null)}
            onClick={() => {
              const result = onResult?.current && onResult.current()
              onOk(selected, result)
            }}
            color={'primary'}
          >
            {withAction[1] || <Trans id="common.apply" />}
          </MuiButton>}
        </DialogActions>
      }
    </div>
  );
})

export { ActionDialogProperty, ContourBackdrop, ContourDivBackdrop, CssTextField, StyledButtonBig, StyledProgress, MultiActionInput, SelectActionInput, ActionInput, ActionDialog, OutlinedDiv, ActionDateInput, ActionDateTimeInput, ActionAutocompleteInput, AlertDialog }
