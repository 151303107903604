import React from "react";
import cookie from "react-cookies";
import { Trans } from "@lingui/react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { GlobalStorage } from "storages/GlobalStorage";
import { QuizStorage } from "storages/QuizStorage";

import { localStorageManager } from "utils/localStorageManager";
import { Icon, ToolButton, TooltipR } from "shared/ui/ToolBar";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { ReactComponent as UserIcon } from "shared/icon/signIn.svg";
import { ReactComponent as IconLogout } from "@mdi/svg/svg/logout.svg";

const LOGIN_REQUIRED = !!process.env.REACT_APP_SURVEY_LOGIN_REQUIRED;
const APP_TITLE = process.env.REACT_APP_TITLE || "Федеральная служба государственной статистики"

function Header() {
  const handleLogout = () => {
    GlobalStorage.user = null;
    cookie.remove("token", { path: "/" });
    cookie.remove("login", { path: "/" });
    cookie.remove("password", { path: "/" });
    QuizStorage.clearQuiz();
    localStorageManager.clearQuizData();
  };

  const user = GlobalStorage.user;

  return (
    <AppBar position="static" style={{ boxShadow: "none" }} color="primary">
      <Toolbar style={{ fontSize: 14 }}>
        <Logo />
        <Title>{APP_TITLE}</Title>
        {user ? (
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <TooltipR text={user.login} placement="bottom">
                  <UserButtonContainer {...bindTrigger(popupState)}>
                    <div style={{ display: "flex" }}>
                      {user.icon ? (
                        user.icon.includes(".svg") ? (
                          <SVG src={user.icon} style={{ margin: 0 }} />
                        ) : (
                          <img src={user.icon} style={{ objectFit: "contain", width: "28px" }} alt="" />
                        )
                      ) : (
                        Icon(null, UserIcon, null, "inherit", {})
                      )}
                    </div>
                    <div>{user.displayname || user.login}</div>
                  </UserButtonContainer>
                </TooltipR>
                <Menu
                  {...bindMenu(popupState)}
                  autoFocus={false}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    value={0}
                    style={{ display: "flex", gap: 8 }}
                    onClick={() => {
                      popupState.close();
                      handleLogout();
                    }}
                  >
                    {Icon("primary", IconLogout)}
                    <Trans id="auth.logout_button" />
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        ) : LOGIN_REQUIRED ? (
          ToolButton(
            UserIcon,
            () => GlobalStorage.changeLoginModalVisibility(true),
            true,
            <Trans id="auth.login_button" />,
            "enter",
            "inherit",
            <Trans id="auth.login_button" />,
            "auto",
          )
        ) : null}
      </Toolbar>
    </AppBar>
  );
}

const Title = styled(Typography)`
  font-size: 14px;
  margin-right: auto;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Logo = styled("div")`
  background-image: url(/logo-color.png);
  background-size: 40px 50px;
  object-fit: contain;
  margin-right: 25px;
  min-width: 41px;
  width: 41px;
  height: 50px;

  @media (max-width: 768px) {
    margin-right: auto;
  }
`;

const UserButtonContainer = styled("div")`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  animation: show 0.75s;

  @keyframes show {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export default observer(Header);
