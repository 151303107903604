import React from "react";
import { Trans } from "@lingui/react";
import styled from "styled-components";
import { useAsyncFn } from "react-use";
import { observer } from "mobx-react-lite";

import { ReactComponent as ExportIcon } from "@mdi/svg/svg/file-export-outline.svg";
import { Button, CircularProgress, Dialog, DialogTitle } from "@mui/material";

import { GlobalStorage } from "storages/GlobalStorage";
import { QuizStorage } from "storages/QuizStorage";

import { localStorageManager } from "utils/localStorageManager";
import TableEditor, { TOOLBAR_DISABLE_CASES } from "shared/ui/TableEditor";

const CAN_RESET_QUIZ = !!process.env.REACT_APP_SURVEY_RESET;

const trDims = [
  ["icon", "", "Image", "Icon"],
  ["caption", "Наименование", "Memo"],
  ["enabled", "Доступен", "Boolean"],
  ["completedByUser", "Пройден", "Boolean"],
  ["short_name", "Аббревиатура", "Memo"],
  ["object", "Объект опроса", "Memo"],
  ["survey_unit", "Единица опроса", "Memo"],
  ["report_unit", "Отчетная единица", "Memo"],
  ["method", "Метод опроса", "Memo"],
  ["kind", "Вид опроса", "Memo"],
  ["type", "Тип опроса", "Memo"],
  ["form", "Форма опроса", "Memo"],
  ["organization", "Способ организации опроса", "Memo"],
  ["coverage", "Охват", "Memo"],
  ["geographical_coverage", "Географический охват", "Memo"],
  ["totality", "Генеральная совокупность", "Memo"],
  ["source", "Источник (форма стат наблюдения)", "Memo"],
  ["periodicity", "Периодичность", "Memo"],
  ["dimension", "Разрезность", "Memo"],
  ["document", "Утверждающий документ", "Memo"],
  ["responsible", "Ответственное лицо", "Memo"],
  ["foiv", "Организация ответственного", "Memo"],
  ["timeseries", "Временной ряд", "Memo"],
  ["description", "Краткое описание", "Memo"],
  ["related_indicators", "Связанные показатели", "Memo"],
  ["version", "Номер версии", "Memo"],
  ["startdate", "Дата начала", "Date"],
  ["enddate", "Дата конца", "Date"],
  ["wf_status", "Статус", "Memo"],
  ["is_last_viewpoint", "Признак последней версии", "Boolean"],
  ["last_update", "Дата последнего обновления", "Date"],
].map(([id, title, type, tooltip], i) => ({
  id,
  properties: {
    title,
    tooltip,
    name: id,
    axis: "data",
    position: 0,
    sortOrder: "none",
    filter: [],
    textFilter: {},
    type: type,
    isEditable: false,
  },
}));

const resetQuizText =
  process.env["REACT_APP_SURVEY_RESET_QUIZ_MODAL_TEXT_" + GlobalStorage.locale.toUpperCase()] ||
  process.env.REACT_APP_SURVEY_RESET_QUIZ_MODAL_TEXT ||
  "Вы уже заполняли анкету выбранного опроса!";

function QuizList() {
  const [selectedQuizCode, setSelectedQuizCode] = React.useState("");

  const [completedQuizModalVisibility, setCompletedQuizModalVisibility] = React.useState(false);
  const [errorQuizModalVisibility, setErrorQuizModalVisibility] = React.useState(false);

  const [{ loading: quizzesLoading }, asyncLoadQuizzesList] = useAsyncFn(GlobalStorage.loadQuizzesData);
  const [{ loading: initializeQuizLoading }, asyncInitializeQuiz] = useAsyncFn(
    React.useCallback(async ({ quizCode, quizStep, answers }) => {
      await QuizStorage.initializeSavedQuiz({ quizCode, quizStep, answers });
      if (QuizStorage.quiz) return;
      localStorageManager.clearQuizData();
      setErrorQuizModalVisibility(true);
    }, []),
  );

  const initializeApp = React.useCallback(async () => {
    const answers = localStorageManager.answers;
    const quizCode = localStorageManager.quizCode;
    const quizStep = localStorageManager.quizStep;

    if (quizCode) asyncInitializeQuiz({ quizCode, quizStep, answers });

    if (Array.isArray(GlobalStorage.quizzes) && GlobalStorage.quizzes.length > 0) return;
    asyncLoadQuizzesList();
  }, [asyncLoadQuizzesList, asyncInitializeQuiz]);

  React.useEffect(() => void initializeApp(), [initializeApp]);

  const [{ loading: quizLoading }, asyncLoadQuiz] = useAsyncFn(
    React.useCallback(async ({ tableData, rows }) => {
      const quiz = tableData.find(({ id }: any) => rows[0] === id);
      if (!quiz) return;
      const quizCode = quiz.code;

      if (!quiz.enabled) return;

      if (quiz.completedByUser) {
        setSelectedQuizCode(quizCode);
        setCompletedQuizModalVisibility(true);
        return;
      }

      await QuizStorage.loadQuiz(quizCode);
      if (!QuizStorage.quiz) {
        localStorageManager.clearQuizData();
        setErrorQuizModalVisibility(true);
        return;
      }
      localStorageManager.setQuizCode(quizCode);
    }, []),
  );

  const handleQuizReset = React.useCallback(() => {
    localStorageManager.resetCompletedQuiz(selectedQuizCode);
    setCompletedQuizModalVisibility(false);
  }, [selectedQuizCode]);

  const schema = {
    value: "roles",
    type: "Table",
    data: {
      id: "asdasdas",
      trDims,
      toolbar: [[ExportIcon, asyncLoadQuiz, TOOLBAR_DISABLE_CASES.equal_one, <Trans id="quizlist.view" />]],
    },
  };

  if (GlobalStorage.quizzes === null || quizLoading || initializeQuizLoading || quizzesLoading)
    return (
      <Container short>
        <CenteredContainer>
          <CircularProgress />
        </CenteredContainer>
      </Container>
    );

  return (
    <Container short={!GlobalStorage.isUserAdmin}>
      <TableEditor
        customTableDataGetter={() => GlobalStorage.quizzes}
        schema={schema}
        value={undefined}
        onChange={undefined}
        rerenderDeps={[GlobalStorage.quizzes]}
        removeViewAllFieldsToolbutton
        onFiltersChange={undefined}
        onOrderChange={undefined}
        saveSelectedRows={undefined}
        onTagsChange={undefined}
        onEditCell={undefined}
        onReload={undefined}
        tableDataHandler={undefined}
        filtersHandler={undefined}
        rowsHandler={undefined}
        reloadHandler={undefined}
      />

      <Dialog open={completedQuizModalVisibility} onClose={() => setCompletedQuizModalVisibility(false)}>
        <DialogTitle id="simple-dialog-title">{resetQuizText}</DialogTitle>
        {CAN_RESET_QUIZ && (
          <Button onClick={handleQuizReset} style={{ margin: "auto", marginBottom: 5 }}>
            <Trans id="buttons.reset" />
          </Button>
        )}
      </Dialog>

      <Dialog open={errorQuizModalVisibility} onClose={() => setErrorQuizModalVisibility(false)}>
        <DialogTitle id="simple-dialog-title">
          <Trans id="error.quiz" />
        </DialogTitle>
      </Dialog>
    </Container>
  );
}

const Container = styled("div")<{ short: boolean }>`
  flex: 1;
  width: 100%;
  margin: auto;

  & > div {
    height: 100%;
  }
`;

const CenteredContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export default observer(QuizList);
