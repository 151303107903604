import React from "react";
import { Trans } from "@lingui/react";
import styled from "styled-components";

import FileIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Button } from "@mui/material";

interface FileListInterface {
  files: string[];
}

function FileList({ files }: FileListInterface) {
  return (
    <FileListContainer>
      {files.map((filePath) => (
        <FileContainer key={filePath}>
          <FileTitleContainer>
            <FileIcon />
            {filePath}
          </FileTitleContainer>
          <Button href={filePath} download="file" variant="contained" color="primary" size="small">
            <Trans id="filelist.download" />
          </Button>
          <Button href={filePath} target="_blank" variant="contained" color="primary" size="small">
            <Trans id="filelist.view" />
          </Button>
        </FileContainer>
      ))}
    </FileListContainer>
  );
}

const FileListContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px 0;
`;

const FileContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FileTitleContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
`;

export default React.memo(FileList);
