import React from "react";
import LoginForm from "components/LoginForm";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Trans } from "@lingui/react";
import { useDispatch, useSelector } from "react-redux";

import Header from "newComponents/Header";

import QuizList from "Views/QuizList";
import Quiz from "Views/Quiz";

import { QuizStorage } from "storages/QuizStorage";
import { GlobalStorage } from "storages/GlobalStorage";
import { useConfirmationDialog } from "shared/ui/ConfirmationDialog";

function Home() {
  const confirmationDialog = useConfirmationDialog();
  const dispatch = useDispatch();

  const apiError = useSelector((state: any) => state.apiError);

  React.useEffect(() => {
    if (!apiError) return;
    const Show = async () => {
      console.log(apiError.message || apiError || "Internal error", apiError.message, apiError);
      await confirmationDialog.getConfirmation({
        title: <Trans id="common.error" />,
        text: `${apiError.message || apiError || "Internal error"}`,
        width: "sm",
        type: "Error",
      });
    };
    Show();
    dispatch({ type: "SET_API_ERROR", value: null });
  }, [dispatch, apiError, confirmationDialog]);

  return (
    <Container>
      <Header />
      <LoginForm />
      {QuizStorage.quiz ? <Quiz /> : <QuizList canLoadQuiz={GlobalStorage.canLoadQuiz} />}
    </Container>
  );
}

const Container = styled("div")`
  padding: 4px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default observer(Home);
