import React, { useState, useEffect } from "react";
import { Trans } from "@lingui/react";
import cookie from "react-cookies";
import { useDispatch } from "react-redux";
import { observer } from "mobx-react-lite";

import { ActionDialog, StyledButtonBig } from "shared/ui";
import { fText } from "shared/ui/Editors";

import { ReactComponent as IconLock } from "@mdi/svg/svg/lock-outline.svg";
import { ReactComponent as UserIcon } from "@mdi/svg/svg/account-circle-outline.svg";

import { GlobalStorage } from "storages/GlobalStorage";
import { api } from "utils/api";

const LOGIN_REQUIRED = !!process.env.REACT_APP_SURVEY_LOGIN_REQUIRED;

function LoginForm() {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = React.useState(true);
  const [value, setValue] = useState({
    login: null,
    password: null,
    rememberCredentials: true,
  });

  const checkToken = React.useCallback(
    async (token) => {
      if (!token) return false;
      setLoading(true);

      const response = await api.userInfo(token);
      setLoading(false);

      if (!response || response?.error) {
        dispatch({ type: "SET_API_ERROR", value: response?.message });
        return false;
      }

      if (!response.token) response.token = token;
      GlobalStorage.user = response;
      GlobalStorage.canLoadQuiz = true;
      GlobalStorage.changeLoginModalVisibility(false);
      return true;
    },
    [dispatch],
  );

  const handleLogin = React.useCallback(
    ({ login, password, rememberCredentials } = {}) => {
      if (!login || !password) return;
      setLoading(true);

      api.login(login, password).then(async (response) => {
        if (!response || response.error || !response.token) {
          dispatch({ type: "SET_API_ERROR", value: response?.message });
          GlobalStorage.canLoadQuiz = true;
          setLoading(false);
          return;
        }

        if (rememberCredentials) {
          cookie.save("token", response.token, { path: "/" });
          cookie.save("login", login, { path: "/" });
          cookie.save("password", password, { path: "/" });
        }

        GlobalStorage.user = response;
        GlobalStorage.canLoadQuiz = true;
        GlobalStorage.changeLoginModalVisibility(false);
        setLoading(false);
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (!LOGIN_REQUIRED) return;
    (async function () {
      const token = cookie.load("token", { path: "/" });
      const login = cookie.load("login", { path: "/" });
      const password = cookie.load("password", { path: "/" });

      if (await checkToken(token)) return;
      if (!login || !password) {
        setLoading(false);
        GlobalStorage.canLoadQuiz = true;
        return;
      }
      handleLogin({ login, password, rememberCredentials: true });
    })();
  }, [checkToken, handleLogin, setLoading]);

  const handleChange = (v, event) => {
    const i = { ...value };
    i[v] = event.target.value;
    setValue(i);
  };

  const handleClose = () => {
    GlobalStorage.changeLoginModalVisibility(false);
  };

  const dataProps = {
    disabled: false,
    onChange: handleChange,
    value,
    fullWidth: true,
    style: "compact",
    size: "medium",
  };

  if (!GlobalStorage.loginModalVisibility || !LOGIN_REQUIRED) return null;

  return (
    <ActionDialog open onClose={handleClose} title={<span>{document.title}</span>}>
      <span style={{ fontSize: "3em", fontWeight: 100, textAlign: "center" }}>
        <Trans id="auth.title" />
      </span>
      <div style={{ height: "1em" }} />
      {fText(
        dataProps,
        <Trans id="auth.login" />,
        "login",
        null,
        { type: "text", Icon: UserIcon, autoComplete: "username" },
        "login",
      )}
      {fText(
        dataProps,
        <Trans id="auth.password" />,
        "password",
        null,
        {
          type: "password",
          Icon: IconLock,
          autoComplete: "current-password",
        },
        "password",
      )}
      {fText(
        dataProps,
        <Trans id="auth.credentials" />,
        "rememberCredentials",
        null,
        { type: "switch", size: "small" },
        "remember",
      )}
      <div style={{ height: "1em" }} />
      <StyledButtonBig
        disabled={isLoading}
        size={"large"}
        color={"primary"}
        variant={"contained"}
        onClick={handleLogin.bind(this, value)}
      >
        <Trans id="auth.auth_button" />
      </StyledButtonBig>
    </ActionDialog>
  );
}

export default observer(LoginForm);
