import { ListInterface } from "storages/GlobalStorage/types";

export interface SurveyFormInterface {
  code: string;
  survey: string;
  answers: Record<
    string,
    { other: string | null; answers: number | number[] | ObjectTypeAnswerInterface[] | string | null }
  >;
}

export interface ConfigInterface {
  datatype?: "text" | "integer" | "float" | "file";
  delimiter?: string;
  format?: string;
  formula?: string;
  rule?: string;
  group?: "select";
  file_class?: string;
  file_object?: string;
  optional?: string;
  condition?: string;
  min?: number;
  max?: number;
}

export interface QuestionInterface {
  code: string;
  multiple_values: boolean;
  other_allowed: boolean;
  question: string;
  question_num: number;
  question_group?: number;
  condition?: number;
  parent_code?: string;
  image?: string;
  value: string;
  default_value?: string | number;
  question_description?: string;
  question_tooltip?: string;
  other_text?: string;
  error_message?: string;
  verification_formula?: string;
  hidden?: boolean;
  type?: "Image" | "Date" | "File";
  files?: string[];
  details?: string;
  config?: ConfigInterface;
}

export interface SubreferenceInterface {
  code: number;
  question_num?: number;
  input_type?: "Text";
  isOtherVariant?: boolean;
  value: string;
}

export interface ReferenceInterface {
  Reference: SubreferenceInterface[];
  question_groups?: boolean;
  caption: string;
  code: string;
}

type ReferencesInterface = Record<string, ReferenceInterface>;

export enum QuizPeriods {
  YEAR = "year",
}

export interface QuizInterface extends ListInterface {
  Questionary: QuestionInterface[];
  References: ReferencesInterface;
  period: QuizPeriods;
  roles?: string[];
}

export interface ObjectTypeAnswerInterface {
  code: number;
  value?: string;
}

export interface AnswerInterface {
  otherTypeAnswers: string | null;
  multipleAnswers: ObjectTypeAnswerInterface[];
  answers: ObjectTypeAnswerInterface | null;
}

export type SyntheticQuestionInterface = {
  answer: ReferenceInterface;
  caption: string;
  description: string | null;
  tooltip: string | null;
  code: string;
} & (
  | {
      isQuestionGroup: true;
      maxPages: number;
      page: number;
      allQuestions: QuestionInterface[];
      availableQuestions: QuestionInterface[];
      formulaQuestions: QuestionInterface[];
      filterQuestions: QuestionInterface[];
      questions: QuestionInterface[];
    }
  | {
      isQuestionGroup: false;
      question: QuestionInterface;
    }
);
