import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

const getPath = (state, node) => {
  const id = node && node.current.id;
  return id && state.API
    ? new Promise(function (resolve, reject) {
        state.API.path(id).then((res) => resolve(res));
      })
    : Promise.resolve();
};

function reducer(state = { propertiesData: null, locale: "ru", rtl: false, loadingsCount: 0, pages: [] }, action) {
  switch (action.type) {
    case "SET_APP":
      return { ...state, APP: action.value };
    case "SET_USER_DATA":
      return { ...state, userData: action.value };
    case "SET_API":
      return { ...state, API: action.value };
    case "SET_LOCALE":
      return { ...state, locale: action.value };
    case "SET_PROJECT_API":
      return { ...state, projectAPI: action.value };
    case "SET_TOKEN":
      return { ...state, token: action.value };
    case 'SET_RTL': 
      return { ...state, rtl: action.value };
    case "SET_PROJECT_ID":
      return { ...state, projectID: action.value };
    case "SET_CHANGED":
      return { ...state, changed: action.value };
    case "SET_PAGES":
      return { ...state, pages: action.value };
    case "INCREASE_LOADINGS_COUNT":
      return { ...state, loadingsCount: state.loadingsCount + 1 };
    case "DECREASE_LOADINGS_COUNT":
      return { ...state, loadingsCount: state.loadingsCount - 1 };
    case "SET_CURRENT":
      return {
        ...state,
        currentProps: action.value,
        current: action.value,
        path: getPath(state, action.value),
      };
    case "SET_CURRENT_PROPS":
      return { ...state, currentProps: action.value };
    case "SET_API_ERROR":
      return { ...state, apiError: action.value };
    case "SET_MESSAGE":
      return { ...state, message: action.value };
    case "SET_PROPERTIES_DATA":
      return { ...state, propertiesData: action.value };
    // temp
    case "SET_DATABASE_API":
      return { ...state, databaseAPI: action.value };

    case "ITEM_CHANGED":
      return { ...state, itemChanged: action.value };

    default:
      return state;
  }
}

const store = createStore(reducer, applyMiddleware(thunkMiddleware));

export default store;
