import { withStyles } from '@mui/styles';

import UiTabs from '@mui/material/Tabs';
import UiTab from '@mui/material/Tab';

export const Tabs = withStyles((theme) => ({
  root: {
    minHeight: 'unset',
    overflow: 'unset',
    backgroundColor: theme.palette.background.paper
  },
  indicator: {
    display: 'none',
  },
}))(UiTabs)

export const Tab = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fill: theme.palette.text.primary,
    padding: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `2px solid transparent`,
    textTransform: 'none',
    minWidth: '1em',
    minHeight: 0,
    '&:hover': {
      color: theme.palette.warning.dark,
      fill: theme.palette.warning.dark,
      opacity: 1,
    },
    '&:focus': {
    },
    '& > span': {
      display: 'flex',
      flexDirection: 'row',
      whiteSpace: 'nowrap',
    },
  },
  selected: {
    color: theme.palette.text.current,
    fill: theme.palette.text.current,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    textTransform: 'none',
    minWidth: '1em',
    minHeight: 0,
  },
}))((props) => <UiTab disableRipple {...props} />)
