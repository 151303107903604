import { action, computed, makeObservable, observable } from "mobx";

import { GlobalStorage } from "storages/GlobalStorage";

import { api } from "utils/api";
import { localStorageManager } from "utils/localStorageManager";

import {
  AnswerInterface,
  ObjectTypeAnswerInterface,
  QuestionInterface,
  QuizInterface,
  QuizPeriods,
  SurveyFormInterface,
  SyntheticQuestionInterface,
} from "./types";

import { parseAnswers, parseBoolean, parseFormAnswers, validateQuestionCondition, validateQuestionRule } from "./utils";

const LOGIN_REQUIRED = !!process.env.REACT_APP_SURVEY_LOGIN_REQUIRED;

export const MAX_QUESTIONS_PER_PAGE = process.env.REACT_APP_SURVEY_QUESTIONS_PER_PAGE_IN_GROUPS
  ? parseInt(process.env.REACT_APP_SURVEY_QUESTIONS_PER_PAGE_IN_GROUPS)
  : 10;

export const DEFAULT_ANSWERS: AnswerInterface = {
  answers: null,
  multipleAnswers: [],
  otherTypeAnswers: null,
};

class QuizStorageClass {
  constructor() {
    makeObservable(this);
  }

  @observable
  registry = "";

  @observable
  saveError = false;

  @observable
  userCode: string | null = null;

  @observable
  quizStep = 0;

  @observable
  quiz: QuizInterface | null = null;

  @observable
  answers: Record<string, AnswerInterface> = {};

  @observable
  questions: SyntheticQuestionInterface[] = [];

  @observable
  loading = false;

  @observable
  completeDialogVisibility = false;

  @observable
  isLastQuestion = false;

  @computed
  get currentQuestion() {
    return this.questions[this.quizStep];
  }

  private validateSingleAnswer(question: QuestionInterface) {
    if (parseBoolean(question.config?.optional)) return true;
    if (question.config?.rule) return validateQuestionRule(question);

    if (question.config?.condition) {
      const condition = validateQuestionCondition(question);
      if (!condition) return true;
    }

    const questionAnswers = this.answers[question.code];
    if (!questionAnswers) return true;

    if (question.type) return typeof questionAnswers.otherTypeAnswers === "string";

    if (question.multiple_values)
      return !!questionAnswers.multipleAnswers.find((answer) =>
        typeof answer.value === "string" ? answer.value.trim() !== "" : true,
      );

    if (!questionAnswers.answers) return false;
    const value = questionAnswers.answers.value;

    if (question.config?.datatype === "float") {
      if (typeof value !== "string") return true;
      return value.at(-1) !== "," && value.at(0) !== "," && value !== "";
    }

    return typeof value === "string" ? value.trim() !== "" : true;
  }

  @computed
  get isAnswersValid() {
    if (this.currentQuestion.isQuestionGroup) {
      const questionsList =
        this.currentQuestion.allQuestions[0]?.config?.group === "select"
          ? this.currentQuestion.filterQuestions
          : this.currentQuestion.availableQuestions;

      const foundQuestion = questionsList.find((question) => !this.validateSingleAnswer(question));
      if (foundQuestion) return false;

      return !this.currentQuestion.formulaQuestions.find((question) => !this.validateSingleAnswer(question));
    }

    return this.validateSingleAnswer(this.currentQuestion.question);
  }

  @action private checkQuestionCondition(question: QuestionInterface) {
    if (!question.config?.condition) return true;

    const result = validateQuestionCondition(question);
    if (result) return true;

    this.answers[question.code] = DEFAULT_ANSWERS;
    return false;
  }

  @action private checkSubquestionVisibility(question: QuestionInterface) {
    if (!question.parent_code) return true;

    const parentSyntheticQuestion = this.questions.find((quest) =>
      quest.isQuestionGroup
        ? quest.allQuestions.find((childQuest) => childQuest.code === question.parent_code)
        : quest.question.code === question.parent_code,
    );

    if (!parentSyntheticQuestion) return false;

    const parentQuestionVisibilityResult = this.checkQuestionVisibility(parentSyntheticQuestion);

    if (!question.condition) {
      if (!parentQuestionVisibilityResult) this.answers[question.code] = DEFAULT_ANSWERS;
      return parentQuestionVisibilityResult;
    }

    const parentAnswers = this.answers[question.parent_code];
    const parentQuestion = parentSyntheticQuestion.isQuestionGroup
      ? parentSyntheticQuestion.allQuestions.find((childQuest) => childQuest.code === question.parent_code)
      : parentSyntheticQuestion.question;

    if (
      parentQuestionVisibilityResult &&
      (parentQuestion!.multiple_values
        ? parentAnswers.multipleAnswers.find((answer) => answer.code === question.condition)
        : parentAnswers.answers?.code === question.condition)
    )
      return true;

    this.answers[question.code] = DEFAULT_ANSWERS;
    return false;
  }

  @action
  private checkQuestionVisibility(checkingQuestion: SyntheticQuestionInterface): boolean {
    if (checkingQuestion.isQuestionGroup) {
      let visibilityResult = false;
      const availableQuestions: QuestionInterface[] = [];

      checkingQuestion.allQuestions
        .filter((question) => this.checkQuestionCondition(question))
        .forEach((question) => {
          const checkResult = this.checkSubquestionVisibility(question);
          if (!checkResult) return;
          availableQuestions.push(question);
          visibilityResult = true;
        });

      checkingQuestion.availableQuestions = availableQuestions;

      if (checkingQuestion.allQuestions?.[0]?.config?.group === "select") {
        checkingQuestion.filterQuestions = checkingQuestion.filterQuestions.filter(
          (question) => !!availableQuestions.find(({ code }) => question.code === code),
        );
        checkingQuestion.page = 1;
        checkingQuestion.maxPages = Math.max(
          1,
          Math.ceil(checkingQuestion.filterQuestions.length / MAX_QUESTIONS_PER_PAGE),
        );
        return visibilityResult;
      }

      checkingQuestion.maxPages = Math.max(1, Math.ceil(availableQuestions.length / MAX_QUESTIONS_PER_PAGE));
      if (checkingQuestion.page > checkingQuestion.maxPages) checkingQuestion.page = checkingQuestion.maxPages;
      checkingQuestion.questions = checkingQuestion.availableQuestions.slice(
        (checkingQuestion.page - 1) * MAX_QUESTIONS_PER_PAGE,
        checkingQuestion.page * MAX_QUESTIONS_PER_PAGE,
      );
      return visibilityResult;
    }

    const result = this.checkQuestionCondition(checkingQuestion.question);
    if (!result) return false;

    return this.checkSubquestionVisibility(checkingQuestion.question);
  }

  @action.bound
  async getNextQuestion() {
    let questionIndex = this.quizStep;

    while (questionIndex < this.questions.length - 1) {
      questionIndex += 1;
      const question = this.questions[questionIndex];
      if (this.checkQuestionVisibility(question)) return question;
    }

    return null;
  }

  @action.bound
  async nextStep() {
    if (this.quizStep === this.questions.length - 1) {
      const currentQuestion = this.questions[this.quizStep];
      if (!this.checkQuestionVisibility(currentQuestion)) this.prevStep();
      await this.completeQuiz();
      return;
    }

    this.quizStep++;
    const futureQuestion = this.questions[this.quizStep];

    if (this.checkQuestionVisibility(futureQuestion)) return;
    this.nextStep();
  }

  @action.bound
  prevStep() {
    if (this.quizStep === 0) return;

    this.quizStep--;
    const prevQuestion = this.questions[this.quizStep];

    if (this.checkQuestionVisibility(prevQuestion)) return;
    this.prevStep();
  }

  @action.bound
  async onGroupQuestionPageChange(page: number) {
    if (!this.currentQuestion.isQuestionGroup) return;
    if (page < 0 || page > this.currentQuestion.maxPages) return;
    this.currentQuestion.questions = this.currentQuestion.availableQuestions.slice(
      (page - 1) * MAX_QUESTIONS_PER_PAGE,
      page * MAX_QUESTIONS_PER_PAGE,
    );
    this.currentQuestion.page = page;
  }

  @action.bound
  toggleAllFilters() {
    if (!this.currentQuestion.isQuestionGroup) return;

    if (this.currentQuestion.filterQuestions.length === this.currentQuestion.availableQuestions.length) {
      this.currentQuestion.filterQuestions = [];
      this.currentQuestion.page = 1;
      this.currentQuestion.maxPages = 1;
      return;
    }

    this.currentQuestion.filterQuestions = [...this.currentQuestion.availableQuestions];
    this.currentQuestion.page = 1;
    this.currentQuestion.maxPages = Math.max(
      1,
      Math.ceil(this.currentQuestion.filterQuestions.length / MAX_QUESTIONS_PER_PAGE),
    );
  }

  @action.bound
  onToggleOnGroupQuestionFilter(question: QuestionInterface) {
    if (!this.currentQuestion.isQuestionGroup) return;

    const foundIndex = this.currentQuestion.filterQuestions.findIndex(({ code }) => question.code === code);
    if (foundIndex !== -1) return;

    this.currentQuestion.filterQuestions.push(question);
    this.currentQuestion.page = 1;
    this.currentQuestion.maxPages = Math.max(
      1,
      Math.ceil(this.currentQuestion.filterQuestions.length / MAX_QUESTIONS_PER_PAGE),
    );
  }

  @action.bound
  onToggleGroupQuestionFilter(question: QuestionInterface) {
    if (!this.currentQuestion.isQuestionGroup) return;

    const foundIndex = this.currentQuestion.filterQuestions.findIndex(({ code }) => question.code === code);
    if (foundIndex !== -1) {
      this.currentQuestion.filterQuestions = this.currentQuestion.filterQuestions.filter(
        ({ code }) => code !== question.code,
      );
    } else {
      this.currentQuestion.filterQuestions.push(question);
    }

    this.currentQuestion.page = 1;
    this.currentQuestion.maxPages = Math.max(
      1,
      Math.ceil(this.currentQuestion.filterQuestions.length / MAX_QUESTIONS_PER_PAGE),
    );
  }

  @action.bound
  resetGroupFilter() {
    if (!this.currentQuestion.isQuestionGroup) return;
    this.currentQuestion.filterQuestions = [];
    this.currentQuestion.page = 1;
    this.currentQuestion.maxPages = 1;
  }

  @action
  selectOtherTypeAnswer(answer: string | null) {
    if (this.currentQuestion.isQuestionGroup) return;
    this.answers[this.currentQuestion.question.code].otherTypeAnswers = answer;
  }

  @action
  async selectAnswer(answer: number, question: QuestionInterface) {
    const objectAnswer: ObjectTypeAnswerInterface = { code: answer };

    const reference = this.currentQuestion.answer.Reference.find((reference) => reference.code === answer)!;
    if (reference.input_type) objectAnswer.value = "";

    if (question.multiple_values) {
      const answers = this.answers[question.code];
      const foundAnswerIndex = answers.multipleAnswers.findIndex((selectedAnswer) => selectedAnswer.code === answer);

      foundAnswerIndex === -1
        ? answers.multipleAnswers.push(objectAnswer)
        : answers.multipleAnswers.splice(foundAnswerIndex, 1);

      this.isLastQuestion = !(await this.getNextQuestion());
      return;
    }

    this.answers[question.code] = DEFAULT_ANSWERS;
    this.answers[question.code].answers = objectAnswer;
    this.isLastQuestion = !(await this.getNextQuestion());
  }

  @action
  changeTextAnswer(answer: ObjectTypeAnswerInterface, question: QuestionInterface) {
    const questionAnswer = this.answers[question.code];

    if (question.multiple_values) {
      const foundAnswerIndex = questionAnswer.multipleAnswers.findIndex(
        (selectedAnswer) => selectedAnswer.code === answer.code,
      );

      if (this.currentQuestion.isQuestionGroup && answer.value?.trim() === "") {
        questionAnswer.multipleAnswers.splice(foundAnswerIndex, 1);
        return;
      }

      foundAnswerIndex === -1
        ? questionAnswer.multipleAnswers.push(answer)
        : (questionAnswer.multipleAnswers[foundAnswerIndex].value = answer.value);

      return;
    }

    if (this.currentQuestion.isQuestionGroup && answer.value?.trim() === "") {
      questionAnswer.answers = null;
      return;
    }

    questionAnswer.answers = answer;
  }

  @action.bound
  clearQuiz() {
    this.quiz = null;
    this.userCode = null;
    this.answers = {};
    this.quizStep = 0;
    this.saveError = false;
    this.isLastQuestion = false;
    this.completeDialogVisibility = false;
  }

  @action.bound
  async initializeSavedQuiz({
    answers,
    quizCode,
    quizStep,
  }: {
    quizCode: string;
    quizStep: number | null;
    answers: Record<string, AnswerInterface> | null;
  }) {
    try {
      const quiz = await this.getQuiz(quizCode);
      if (quiz === null) return;

      if (typeof answers === "object" && answers !== null) {
        const entries = Object.entries(answers);
        if (!Array.isArray(entries[0][1].multipleAnswers)) {
          this.quizStep = 0;
          this.quiz = quiz;
          return;
        }

        entries.map(([key, value]) => (this.answers[key] = value));
      }

      this.quizStep = quizStep || 0;
      this.checkQuestionVisibility(this.questions[this.quizStep]);
      this.isLastQuestion = !(await this.getNextQuestion());
      this.quiz = quiz;
    } catch (error) {
      this.clearQuiz();
    }
  }

  @action
  async loadQuiz(quizCode: string, onFailure?: () => void) {
    try {
      this.quiz = await this.getQuiz(quizCode, onFailure);
    } catch (error) {
      console.log(error);
      this.clearQuiz();
    }
  }

  @action
  async getQuiz(quizCode: string, onFailure?: () => void) {
    const payload: Record<string, any> = { token: GlobalStorage.user?.token };

    if (this.registry === "survey_registry") {
      payload.survey = quizCode;
    } else {
      payload.code = quizCode;
    }

    const formResponse = await api.post<SurveyFormInterface>("/form", payload);
    const formError = "error" in formResponse;
    if (!formError) this.userCode = formResponse.code;

    const queries = new URLSearchParams();
    queries.append("code", formError ? quizCode : formResponse.survey);
    queries.append("lang", GlobalStorage.locale);
    if (GlobalStorage.user) queries.append("token", GlobalStorage.user.token);

    const response = await api.get<QuizInterface>("/load?" + queries.toString());
    if ("error" in response) {
      onFailure?.();
      return null;
    }

    if (LOGIN_REQUIRED && Array.isArray(response.roles)) {
      const isRoleInclude = !!response.roles.find((role) =>
        GlobalStorage.user ? GlobalStorage.user.roles.includes(role) : role === "guest",
      );
      if (!GlobalStorage.isUserAdmin && !isRoleInclude) throw new Error();
    }

    const questionNumbers: number[] = [];
    response.Questionary.forEach(
      (quest) => !questionNumbers.includes(+quest.question_num) && questionNumbers.push(+quest.question_num),
    );

    const sortedQuestionNumbers = questionNumbers.sort((a, b) => a - b);

    const answers: Record<string, AnswerInterface> = {};
    this.questions = sortedQuestionNumbers.map((questNumber) => {
      const filteredQuestions = response.Questionary.filter((quest) => quest.question_num === questNumber);
      const formulaQuestions = filteredQuestions.filter((quest) => !!quest.config?.formula);
      const commonQuestions = filteredQuestions.filter((quest) => !quest.config?.formula);
      const isQuestionGroup = commonQuestions.length > 1 || typeof commonQuestions[0].question_group === "number";

      const firstQuestion = commonQuestions[0];
      const answer = response.References[firstQuestion.question];

      if (!answer) throw new Error();

      answer.Reference = answer.Reference.map((reference) => ({ ...reference, code: +reference.code }));
      if (firstQuestion.other_allowed)
        answer.Reference.push({
          code: answer.Reference.length + 1,
          value: firstQuestion.other_text || "",
          input_type: "Text",
          isOtherVariant: true,
        });

      commonQuestions.forEach((question) => {
        answers[question.code] = { ...DEFAULT_ANSWERS };
        if (formError) return;
        parseFormAnswers({ answer, answers, formResponse, question });
      });

      const question: Record<string, any> = {
        answer,
        isQuestionGroup,
        code: firstQuestion.code,
      };

      if (isQuestionGroup) {
        question.caption = answer.caption;
        question.tooltip = null;
        question.description = null;
        question.questions = commonQuestions.slice(0, MAX_QUESTIONS_PER_PAGE);
        question.formulaQuestions = formulaQuestions;
        question.allQuestions = commonQuestions;
        question.availableQuestions = commonQuestions;
        question.filterQuestions = [];
        question.page = 1;
        question.maxPages = firstQuestion.config?.group
          ? 1
          : Math.ceil(commonQuestions.length / MAX_QUESTIONS_PER_PAGE);
      } else {
        question.caption = firstQuestion.value;
        question.tooltip = firstQuestion.question_tooltip || null;
        question.description = firstQuestion.question_description || null;
        question.question = firstQuestion;
        if (firstQuestion.config?.datatype === "file") question.question.type = "File";
      }

      return question as SyntheticQuestionInterface;
    });

    this.answers = answers;
    if (!response.period) response.period = QuizPeriods.YEAR;
    return response;
  }

  @action.bound
  async saveQuiz() {
    const payloadData: Record<any, any> = {
      answers: parseAnswers(),
      survey: this.quiz!.code,
      code: this.registry,
      respondent: this.userCode || GlobalStorage.uuid,
    };

    if (GlobalStorage.user) {
      payloadData.login = GlobalStorage.user.login;
      payloadData.token = GlobalStorage.user.token;
    }

    const response = await api.post<{ ok: boolean }>("/save", payloadData);
    if ("error" in response || !response.ok) this.saveError = true;
  }

  @action.bound
  async completeQuiz() {
    this.loading = true;

    this.questions.forEach((question) => this.checkQuestionVisibility(question));

    const payloadData: Record<any, any> = {
      answers: parseAnswers(),
      survey: this.quiz!.code,
      code: this.registry,
      respondent: this.userCode || GlobalStorage.uuid,
      final: true,
    };

    if (GlobalStorage.user) {
      payloadData.login = GlobalStorage.user.login;
      payloadData.token = GlobalStorage.user.token;
    }

    await api.post("/save", payloadData);

    this.completeDialogVisibility = true;
    this.loading = false;
    localStorageManager.completeQuiz(this.quiz!);
    localStorageManager.clearQuizData();

    if (!GlobalStorage.allQuizzes) return;
    const foundQuizIndex = GlobalStorage.allQuizzes.findIndex(({ code }) => this.quiz!.code === code);
    if (foundQuizIndex === -1) return;
    GlobalStorage.allQuizzes[foundQuizIndex].completedByUser = true;
  }
}

export const QuizStorage = new QuizStorageClass();
