import React from "react";
import { Trans } from "@lingui/react";
import styled from "styled-components";
import { useAsyncFn } from "react-use";
import { observer } from "mobx-react-lite";

import InboxIcon from "@mui/icons-material/Inbox";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";

import { GlobalStorage } from "storages/GlobalStorage";
import { QuizStorage } from "storages/QuizStorage";

import { localStorageManager } from "utils/localStorageManager";

const LOGIN_REQUIRED = !!process.env.REACT_APP_SURVEY_LOGIN_REQUIRED;
const CAN_RESET_QUIZ = !!process.env.REACT_APP_SURVEY_RESET;

const resetQuizText =
  process.env["REACT_APP_SURVEY_RESET_QUIZ_MODAL_TEXT_" + GlobalStorage.locale.toUpperCase()] ||
  process.env.REACT_APP_SURVEY_RESET_QUIZ_MODAL_TEXT ||
  "Вы уже заполняли анкету выбранного опроса!";

const emptyQuizListPlaceholder =
  process.env["REACT_APP_SURVEY_EMPTY_QUIZZES_PLACEHOLDER_" + GlobalStorage.locale.toUpperCase()] ||
  process.env.REACT_APP_SURVEY_EMPTY_QUIZZES_PLACEHOLDER;

interface QuizListInterface {
  canLoadQuiz: boolean;
  code?: string;
}

function QuizList({ canLoadQuiz, code }: QuizListInterface) {
  const [selectedQuizCode, setSelectedQuizCode] = React.useState("");

  const [completedQuizModalVisibility, setCompletedQuizModalVisibility] = React.useState(false);
  const [errorQuizModalVisibility, setErrorQuizModalVisibility] = React.useState(false);

  const [{ loading: quizzesLoading }, asyncLoadQuizzesList] = useAsyncFn(GlobalStorage.loadQuizzesData);
  const [{ loading: initializeQuizLoading }, asyncInitializeQuiz] = useAsyncFn(
    React.useCallback(async ({ quizCode, quizStep, answers }) => {
      await QuizStorage.initializeSavedQuiz({ quizCode, quizStep, answers });
      if (QuizStorage.quiz) return;
      localStorageManager.clearQuizData();
      setErrorQuizModalVisibility(true);
    }, []),
  );

  const initializeApp = React.useCallback(async () => {
    if (LOGIN_REQUIRED && !canLoadQuiz) return;
    const answers = localStorageManager.answers;
    const quizCode = localStorageManager.quizCode;
    const quizStep = localStorageManager.quizStep;

    if (quizCode) asyncInitializeQuiz({ quizCode, quizStep, answers });

    if (Array.isArray(GlobalStorage.quizzes) && GlobalStorage.quizzes.length > 0) return;
    asyncLoadQuizzesList();
  }, [canLoadQuiz, asyncInitializeQuiz, asyncLoadQuizzesList]);

  React.useEffect(() => void initializeApp(), [initializeApp]);

  const [{ loading: quizLoading }, asyncLoadQuiz] = useAsyncFn(
    React.useCallback(async (quizCode: string, isQuizCompleted: boolean) => {
      if (isQuizCompleted) {
        setSelectedQuizCode(quizCode);
        setCompletedQuizModalVisibility(true);
        return;
      }

      await QuizStorage.loadQuiz(quizCode);
      if (!QuizStorage.quiz) {
        localStorageManager.clearQuizData();
        setErrorQuizModalVisibility(true);
        return;
      }
      localStorageManager.setQuizCode(quizCode);
    }, []),
  );

  React.useEffect(() => {
    if (!code) return;
    asyncLoadQuiz(code, false);
  }, [asyncLoadQuiz, code]);

  const handleQuizReset = React.useCallback(() => {
    localStorageManager.resetCompletedQuiz(selectedQuizCode);
    setCompletedQuizModalVisibility(false);
  }, [selectedQuizCode]);

  if (GlobalStorage.quizzes === null || quizLoading || initializeQuizLoading || quizzesLoading)
    return (
      <Container>
        <CenteredContainer>
          <CircularProgress />
        </CenteredContainer>
      </Container>
    );

  return (
    <Container>
      <QuizListContainer elevation={3} variant="outlined">
        {GlobalStorage.currentFolderItems.length === 0 ? (
          <CenteredContainer>
            <Typography>{emptyQuizListPlaceholder}</Typography>
          </CenteredContainer>
        ) : (
          <>
            {GlobalStorage.currentFolder !== null && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button variant="contained" onClick={GlobalStorage.goBackToFolder} style={{ margin: 10 }}>
                  Назад
                </Button>
                {GlobalStorage.currentFolderName}
              </div>
            )}
            <List component="nav">
              {
                /* GlobalStorage.quizzes!.slice(
              (GlobalStorage.quizzesPage - 1) * QUIEZZES_PER_PAGE,
              GlobalStorage.quizzesPage * QUIEZZES_PER_PAGE,
            ) */ GlobalStorage.currentFolderItems.map((item, index) => {
                  if ("name" in item)
                    return (
                      <ListItem key={index} button onClick={() => GlobalStorage.goToFolder(item.id)}>
                        <ListItemIcon>
                          {item.icon ? <QuizIcon src={item.icon} alt="quiz-icon" /> : <FolderIcon />}
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                      </ListItem>
                    );

                  const isQuizCompleted = !!localStorageManager.completedQuizzes.find(({ code }) => code === item.code);
                  return (
                    <ListItem
                      key={index}
                      button
                      disabled={!item.enabled}
                      onClick={() => asyncLoadQuiz(item.code, isQuizCompleted)}
                    >
                      <ListItemIcon>
                        {item.icon ? <QuizIcon src={item.icon} alt="quiz-icon" /> : <InboxIcon />}
                      </ListItemIcon>
                      <ListItemText primary={item.caption} />
                      {isQuizCompleted && <DoneAllIcon />}
                    </ListItem>
                  );
                })
              }
            </List>
          </>
        )}
      </QuizListContainer>
      {/* <BaseContainer>
        <Pagination
          code={GlobalStorage.quizzesPage.toString()}
          maxPage={GlobalStorage.quizzesMaxPage}
          onPageChange={(page) => (GlobalStorage.quizzesPage = page)}
          page={GlobalStorage.quizzesPage}
        />
      </BaseContainer> */}

      <Dialog open={completedQuizModalVisibility} onClose={() => setCompletedQuizModalVisibility(false)}>
        <DialogTitle id="simple-dialog-title">{resetQuizText}</DialogTitle>
        {CAN_RESET_QUIZ && (
          <Button variant="contained" onClick={handleQuizReset} style={{ margin: "auto", marginBottom: 5 }}>
            <Trans id="buttons.reset" />
          </Button>
        )}
      </Dialog>

      <Dialog open={errorQuizModalVisibility} onClose={() => setErrorQuizModalVisibility(false)}>
        <DialogTitle id="simple-dialog-title">
          <Trans id="error.quiz" />
        </DialogTitle>
      </Dialog>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 100px;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 0 10px;
  }
`;

const QuizListContainer = styled(Paper)`
  max-width: 640px;
  margin: auto;
  background: white;
`;

// const BaseContainer = styled("div")`
//   max-width: 640px;
//   margin: auto;
// `;

const CenteredContainer = styled.div`
  margin: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const QuizIcon = styled("img")`
  max-width: 24px;
  max-height: 24px;
`;

export default observer(QuizList);
