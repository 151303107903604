import { useState } from 'react'
import { Icon } from 'shared/ui/ToolBar'
import SVG from 'react-inlinesvg'

import { ReactComponent as IconProject } from '@mdi/svg/svg/notebook-outline.svg'
import { ReactComponent as IconDatabase } from '@mdi/svg/svg/database.svg'
import { ReactComponent as IconTable } from '@mdi/svg/svg/file-table-box-outline.svg'
import { ReactComponent as IconColumn } from '@mdi/svg/svg/focus-field.svg'
import { ReactComponent as IconFolder } from '@mdi/svg/svg/folder-outline.svg'
import { ReactComponent as IconQuery } from '@mdi/svg/svg/beaker-outline.svg'
import { ReactComponent as IconReport } from '@mdi/svg/svg/book-outline.svg'
import { ReactComponent as IconMap } from '@mdi/svg/svg/earth.svg'
import { ReactComponent as IconDnD } from '@mdi/svg/svg/select-place.svg'
import { ReactComponent as IconLink } from '@mdi/svg/svg/folder-network-outline.svg'
import { ReactComponent as IconCube } from '@mdi/svg/svg/cube-outline.svg'
import { ReactComponent as IconSlice } from '@mdi/svg/svg/circle-slice-2.svg'
import { ReactComponent as IconGrid } from '@mdi/svg/svg/file-table-box-outline.svg'
import { ReactComponent as IconChart } from '@mdi/svg/svg/chart-box-outline.svg'
import { ReactComponent as IconMapping } from '@mdi/svg/svg/puzzle-outline.svg'
import { ReactComponent as IconNULL } from '@mdi/svg/svg/focus-field.svg'

import { ReactComponent as IconProduct } from '@mdi/svg/svg/briefcase-variant-outline.svg'
import { ReactComponent as IconServer } from '@mdi/svg/svg/web.svg'
import { ReactComponent as IconApp } from '@mdi/svg/svg/application-brackets-outline.svg'
import { ReactComponent as IconClass } from '@mdi/svg/svg/memory.svg'

export const useForceUpdate = () => {
  const [, setValue] = useState(0) // integer state
  return () => setValue(value => ++value) // update the state to force render
}

export const isPathAbsolute = (path) => (/^(?:\/|[a-z]+:\/\/)/.test(path))

const iconFromComponent = (component, style) => (Icon('action', component, null, null, { ...style, fontSize: '1em' }))

const defaultIcon = (type) => {
  return {
    Database: IconDatabase,
    QueryColumn: IconColumn,
    Column: IconColumn,
    Table: IconTable,
    Query: IconQuery,
    Project: IconProject,
    Transformations: IconFolder,
    Folder: IconFolder,
    Databases: IconFolder,
    Queries: IconFolder,
    Mappings: IconFolder,
    Classifiers: IconFolder,
    Reports: IconFolder,
    Report: IconReport,
    Link: IconLink,
    osm_node: IconMap,
    DnD: IconDnD,
    Cube: IconCube,
    Dims: IconFolder,
    Dim: IconColumn,
    Facts: IconFolder,
    Fact: IconColumn,
    Slices: IconFolder,
    Slice: IconSlice,
    Components: IconFolder,
    Component: IconGrid,
    Chart: IconChart,
    Map: IconMap,
    Mapping: IconMapping,

    tr: IconFolder,
    trLogs: IconFolder,
    codes: IconFolder,

    properties: IconFolder,
    operations: IconFolder,
    objects: IconFolder,
    software_products: IconProduct,
    software_product: IconProduct,
    software_product_table: IconApp,
    application: IconApp,
    module_table: IconClass,
    class: IconClass,
    server: IconServer,
  }[type]
}

export const projectItemImage = (type) => {
  const icon = {
    Database: '015-server',
    QueryColumn: '023-website',
    Column: '023-website',
    Table: '023-website',
    Folder: '001-folder',
    Query: '019-coding',
    Project: '040-briefcase',
    Databases: '015-server',
    Transformations: '029-processing',
    Queries: '019-coding',
    Reports: '040-briefcase',
    Report: '005-layout',
    Mappings: '001-folder',
    Classifiers: '001-folder',
    osm_node: '012-earth',
    Cube: '025-cube',
    Dims: '001-folder',
    Facts: '029-abacus',//001-folder',
    Slices: '001-folder',
    Slice: '027-layers',
    Components: '001-folder',
    Component: '023-website',
    Dim: '023-website',
    Fact: '023-website',
    Mapping: '023-testing',

    i18n: '023-testing'

  }[type]
  return icon && `/typeImages/${icon}.svg`
}

export const projectItemIconComponent = (value, style, iconType) => {
  let icon = <span />
  let path = value?.url

  if (value && typeof value === 'object' && path === undefined)
    return iconFromComponent(value, style)

    if (path && path.length) {
//    if (!isPathAbsolute(path))
//      path = `/uploads/${path}`

    if (path.includes('.svg')) {
      icon = <div style={{ display: 'inline-block', margin: style.margin, width: style.width, height: style.height }}><SVG src={path} style={{ ...style, margin: 0 }} /></div>
    } else {
      icon = <img src={path} style={{ ...style, objectFit: 'contain' }} alt={''} />
    }
  } else if (iconType)
    icon = projectItemIconComponent(defaultIcon(iconType) || IconNULL, style)
  return icon
}

export const projectItemImageComponent = (item, size, margin, color, useImage = true) => {
  const imageStyle = { display: 'block', objectFit: 'contain', margin, cursor: 'pointer', maxWidth: size, maxHeight: size, minWidth: size, minHeight: size, width: size, height: size, fill: color }
  const value = useImage && (item.properties.image || projectItemImage(item.type)) || item.properties.icon
  return item && projectItemIconComponent(value, imageStyle, item.type)
}