/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Trans } from "@lingui/react";
import clsx from "clsx";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import { Chip } from "@mui/material";

import TableView from "components/itemView/TableView";
import FilterList from "components/HyperGrid/DimensionCard/FilterList";
import { DimButton, PopoverCustom } from "components/HyperGrid/DimensionCard";

import { ReactComponent as FAxisIcon } from "@mdi/svg/svg/focus-field.svg";
import { ReactComponent as ColumnIcon } from "@mdi/svg/svg/book-outline.svg";
import { ReactComponent as ArrowLeft } from "@mdi/svg/svg/arrow-left.svg";
import { ReactComponent as ArrowRight } from "@mdi/svg/svg/arrow-right.svg";

import { Icon } from "shared/ui/ToolBar";

const CustomChip = styled(Chip)`
  gap: 4px;
  svg {
    margin: 0 !important;
  }
`;

const FilterTagsContainer = styled("div")`
  display: flex;
  gap: 0.25em;
  padding-bottom: 0.35em;
  max-width: 100%;

  .MuiChip-root {
    height: 26px;
    padding: 0 12px;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .MuiChip-label {
    font-size: 13px;
    padding: 0;
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin: 0 -6px 0 4px;
  }
`;

const FilterSelectedTagsContainer = styled("div")`
  display: flex;
  gap: 0.25em;
`;

const FilterSelectedTagsResizeContainer = styled("div")`
  display: flex;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const INITIAL_TAGS_STATE = {
  leftButtonIsHidden: true,
  leftButtonIsDisabled: true,
  rightButtonIsHidden: true,
  rightButtonIsDisabled: true,
};

const DataTableView = (props) => {
  const rtl = useSelector((state) => state.rtl);
  const [dataItem, setDataItem] = useState();
  const [isDragState, setDragState] = useState(false);
  const [updatabled] = useState({});
  const [tagsVisibility, setTagsVisibility] = useState(true);

  const [selectedTagsState, setSelectedTagsState] = useState(INITIAL_TAGS_STATE);
  const [selectedTagsReseizeRef, setSelectedTagsReseizeRef] = useState(null);
  const [selectedTagsRef, setSelectedTagsContainerRef] = useState(null);

  const {
    getIds,
    trDims,
    tags = [],
    api,
    isAllowEdit,
    onTagsChange,
    onEditorOk,
    singleRowSelect,
    onSelectedRowsChanged,
    reloadFn,
    clearFiltersFn,
    disableColumnsDef,
    filtersFn,
    getSelected,
  } = props;

  const reload = () => setDataItem((dataItem) => ({ ...dataItem }));

  React.useEffect(() => reload(), [rtl]);

  const clearFilters = () => {
    trDims.forEach((d) => {
      if (d.properties.clearableFilter === false) return;
      d.properties.filter && (d.properties.filter = []);
      d.properties.textFilter && (d.properties.textFilter = {});
    });
    props?.onFiltersChange?.([]);
    reload();
  };

  useEffect(() => {
    setDataItem(props.dataItem);
  }, [props.dataItem]);

  const handleScrollTagsLeft = React.useCallback(() => {
    const step = Math.floor(selectedTagsReseizeRef.offsetWidth * 0.75);
    const value = selectedTagsReseizeRef.scrollLeft > step ? selectedTagsReseizeRef.scrollLeft - step : 0;
    selectedTagsReseizeRef.scrollTo({
      left: value,
      behavior: "smooth",
    });

    const maxLeftScroll = selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth;
    const leftButtonIsDisabled = value === 0;
    const rightButtonIsDisabled = value === maxLeftScroll - 1;
    setSelectedTagsState((state) => ({
      ...state,
      leftButtonIsDisabled,
      rightButtonIsDisabled,
    }));
  }, [selectedTagsRef, selectedTagsReseizeRef]);

  const handleScrollTagsRight = React.useCallback(() => {
    const step = Math.floor(selectedTagsReseizeRef.offsetWidth * 0.75);
    const maxLeftScroll = selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth;
    const leftScrollSpace = maxLeftScroll - 1 - selectedTagsReseizeRef.scrollLeft;
    const value = leftScrollSpace > step ? selectedTagsReseizeRef.scrollLeft + step : maxLeftScroll;
    selectedTagsReseizeRef.scrollTo({
      left: value,
      behavior: "smooth",
    });

    const leftButtonIsDisabled = value === 0;
    const rightButtonIsDisabled = value === maxLeftScroll;
    setSelectedTagsState((state) => ({
      ...state,
      leftButtonIsDisabled,
      rightButtonIsDisabled,
    }));
  }, [selectedTagsRef, selectedTagsReseizeRef]);

  useEffect(() => {
    if (!selectedTagsRef) return;

    const resizeObserver = new ResizeObserver(() => {
      const haveHiddenContent = selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth > 0;
      setSelectedTagsState(
        haveHiddenContent
          ? {
              leftButtonIsHidden: false,
              leftButtonIsDisabled: true,
              rightButtonIsHidden: false,
              rightButtonIsDisabled: false,
            }
          : INITIAL_TAGS_STATE,
      );
    });

    const unobserve = () => resizeObserver.unobserve(selectedTagsRef);
    resizeObserver.observe(selectedTagsRef);
    const unobserveResize = () => resizeObserver.unobserve(selectedTagsReseizeRef);
    resizeObserver.observe(selectedTagsReseizeRef);

    const haveHiddenContent = selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth > 0;
    setSelectedTagsState(
      haveHiddenContent
        ? {
            leftButtonIsHidden: false,
            leftButtonIsDisabled: true,
            rightButtonIsHidden: false,
            rightButtonIsDisabled: false,
          }
        : INITIAL_TAGS_STATE,
    );

    return () => {
      unobserve();
      unobserveResize();
    };
  }, [selectedTagsRef, selectedTagsReseizeRef]);

  const handleSortOrderChanged = (dim, order) => {
    dim.properties.sortOrder = order;
    reload();

    if (!props.onOrderChange) return;
    const sortOrder = trDims
      .filter((d) => ["data", "fixed"].includes(d.properties.axis))
      .filter((d) => ["DESC", "ASC"].includes(d.properties.sortOrder))
      .map((d) => [d.properties.name, d.properties.sortOrder]);
    props.onOrderChange(sortOrder);
  };

  const handleFiltersChanged = () => {
    reload();
    if (!props.onFiltersChange) return;
    props.onFiltersChange(filters(), trDims);
  };

  const removeFromAxis = (dim) => {
    const { axis, position } = dim.properties;
    trDims.forEach((d) => {
      if (d.properties.axis === axis) if (d.properties.position > position) --d.properties.position;
    });
    dim.properties.axis = "none";
    dim.properties.position = -1;
  };

  const appendToAxis = (dim, axis) => {
    const last =
      trDims.filter((d) => d.properties.axis === axis).reduce((max, d) => Math.max(max, d.properties.position), 0) + 1;
    dim.properties.axis = axis;
    dim.properties.position = last;
  };

  const prependToAxis = (dim, axis) => {
    trDims.filter((d) => d.properties.axis === axis).forEach((d) => d.properties.position++);
    dim.properties.axis = axis;
    dim.properties.position = 0;
  };

  const insertToAxis = (dim, axis, position) => {
    trDims
      .filter((d) => d.properties.axis === axis)
      .forEach((d) => d.properties.position >= position && d.properties.position++);
    dim.properties.axis = axis;
    dim.properties.position = position;
  };

  const handleFixedChanged = (dim, isFixed) => {
    removeFromAxis(dim);
    isFixed ? appendToAxis(dim, "fixed") : prependToAxis(dim, "data");
    reload();
  };

  const handleHover = (id, pos) => {
    console.log("handleHover: ", id);
    const h = { id, pos };
    Object.values(updatabled).forEach((i) => {
      // обновляем только старый и новый hover
      if (i.id === i.hoverId.id || i.id === h.id) i.setHoverId?.(h);
    });
  };

  const handleDrop = (dim, dimTo, isBefore) => {
    const h = { id: null };
    Object.values(updatabled).forEach((i) => {
      // обновляем только старый hover
      if (i.id === i.hoverId.id) i.setHoverId?.(h);
    });

    setDragState(false);

    if (dim && dimTo) {
      console.log("handleDrop: ", dim, dimTo, isBefore);
      removeFromAxis(dim);
      insertToAxis(dim, dimTo.properties.axis, dimTo.properties.position + (isBefore ? 0 : 1));
      reload();
    } else {
      console.log("STOP DRAG");
    }
  };

  const handleStartDrag = (id) => {
    console.log("START DRAG");
    // delay for display placeholders
    requestAnimationFrame(() => setDragState(true));
  };

  const dimProps = {
    api,
    disableMoveFiltersButton: props.disableMoveFiltersButton,
    disableSearchAndReplaceButton: props.disableSearchAndReplaceButton,
    icon: props.disableTableColumnHeaderIcons ? "" : null,
    onApplyFilter: handleFiltersChanged,
    isDragState: isDragState,
    onHover: handleHover,
    onDrop: handleDrop,
    onStartDrag: handleStartDrag,
    onChangeOrder: handleSortOrderChanged,
    onChangeFixed: handleFixedChanged,
    updatabled,
  };

  const HAxisContent = (args) => {
    const { classes, keys, style, head, bindData } = args;
    if (!head)
      return (
        <div
          className={clsx(classes.common, classes.haxis)}
          {...bindData}
          id={keys}
          key={keys}
          onMouseOver={() => {}}
          onMouseOut={() => {}}
          style={style}
        ></div>
      );

    const dim = head.data;
    return (
      <div
        className={clsx(classes.common, classes.haxis)}
        {...bindData}
        id={keys}
        key={keys}
        onMouseOver={() => {}}
        onMouseOut={() => {}}
        style={dim ? { ...style, ...{ padding: 0 } } : style}
      >
        {dim ? (
          <DimButton
            allowChangeSortOrder={true}
            additionalFilters={props.additionalFilters}
            icon={dim.properties.iconComponent || ColumnIcon}
            dim={dim}
            id={`1d${dim.id}`}
            key={dim.id}
            {...dimProps}
            full={true}
            style={{ width: "100%", border: "none" }}
          />
        ) : (
          <div className={classes.hdiv}>
            <span className={classes.ispanBig}>{head.title || head.name || "-"}</span>
            {head.title && <span className={classes.ispanSmall}>{head.name || "-"}</span>}
          </div>
        )}
      </div>
    );
  };

  const onColumnsDef = async (columns) => {
    if (disableColumnsDef) return;
    columns.forEach((c) => (c.data = trDims.find((d) => d.properties.name === c.name)));
  };

  const handleTagRemove = (tagCode) => {
    const newTags = [...tags];
    const foundTagIndex = newTags.findIndex((tag) => tag.code === tagCode);
    if (foundTagIndex === -1) return;
    newTags[foundTagIndex].selected = false;
    onTagsChange?.(newTags);
  };

  const handleTagChange = (tagCodes) => {
    const newTags = tags.map((tag) => {
      const isSelected = tagCodes.includes(tag.code);
      return { ...tag, selected: isSelected };
    });
    onTagsChange?.(newTags);
  };

  const filters = () => {
    const namedDims = trDims.filter((d) => true).filter((d) => d.properties.name != null);
    const filter = [];
    for (let d of namedDims) {
      switch (d.properties.type) {
        case "Boolean": {
          const { value } = d.properties.textFilter || {};
          props.additionalFilters?.applyFilters?.(d, filter);
          if (value === undefined || value === null) break;
          filter.push([null, d.properties.name, "=", value?.id ? "true" : "false", false, null, "AND"]);
          break;
        }
        case "Memo": {
          const { text, cs, operator } = d.properties.textFilter || {};
          props.additionalFilters?.applyFilters?.(d, filter);

          switch (operator) {
            case null:
            case undefined:
            case "ANY":
              break;
            case "=":
            case "<>":
            case ">":
            case ">=":
            case "<":
            case "<=":
            case "IS NULL":
            case "IS NOT NULL":
              filter.push([null, d.properties.name, operator, text, !cs, null, "AND"]);
              break;
            case "LIKE":
              filter.push([null, d.properties.name, "LIKE", "%" + text + "%", !cs, null, "AND"]);
              break;
            case "NOT LIKE":
              filter.push([null, d.properties.name, "NOT LIKE", "%" + text + "%", !cs, null, "AND"]);
              break;
            case "% LIKE":
              filter.push([null, d.properties.name, "LIKE", "%" + text, !cs, null, "AND"]);
              break;
            case "NOT % LIKE":
              filter.push([null, d.properties.name, "NOT LIKE", "%" + text, !cs, null, "AND"]);
              break;
            case "LIKE %":
              filter.push([null, d.properties.name, "LIKE", text + "%", !cs, null, "AND"]);
              break;
            case "NOT LIKE %":
              filter.push([null, d.properties.name, "NOT LIKE", text + "%", !cs, null, "AND"]);
              break;
            default:
              break;
          }
          break;
        }
        case "DateTime": {
          const { start, end, operator } = d.properties.textFilter || {};
          const startValue = start?.__proto__ === Date.prototype ? start.toISOString() : start;
          const endValue = end?.__proto__ === Date.prototype ? end.toISOString() : end;
          switch (operator) {
            case null:
            case undefined:
            case "ANY":
              break;
            case "=":
            case "<>":
            case ">":
            case ">=":
            case "<":
            case "<=":
            case "IS NULL":
            case "IS NOT NULL":
              filter.push([null, d.properties.name, operator, startValue, false, null, "AND"]);
              break;
            case "BETWEEN":
              filter.push([null, d.properties.name, ">", startValue, false, null, "AND"]);
              filter.push([null, d.properties.name, "<", endValue, false, null, "AND"]);
              break;
            case "NOT BETWEEN":
              filter.push([null, d.properties.name, "<", startValue, false, null, "AND"]);
              filter.push([null, d.properties.name, ">", endValue, false, null, "AND"]);
              break;
            default:
              break;
          }
          break;
        }
        case "Dictionary": {
          const f = getSelected(d.id, true);
          if (f?.length > 0) {
            // [ null, "context_id", "IN",[ 37, 150, 154, 78 ], false, ")", "AND" ]
            filter.push([null, d.properties.name, "IN", f, false, null, "AND"]);
          }
          break;
        }
        default:
          break;
      }
    }
    return filter;
  };

  reloadFn && (reloadFn.current = reload);
  clearFiltersFn && (clearFiltersFn.current = clearFilters);
  filtersFn && (filtersFn.current = filters);

  const selectedTags = tags.filter((tag) => tag.selected);

  return (
    <Paper
      square={true}
      style={{
        display: "flex",
        padding: "1em",
        width: "100%",
        boxShadow: "none",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <div key={"filter_axis"} style={{ display: "flex", flexDirection: "row" }}>
        {trDims
          .filter((i) => i.properties.axis === "static")
          .sort((l, r) => (l.properties.position < r.properties.position ? -1 : 1))
          .map((dim, ndx) => {
            return (
              <DimButton
                allowChangeSortOrder={false}
                icon={dim.properties.iconComponent || FAxisIcon}
                dim={dim}
                id={`1s${dim.id}`}
                key={dim.id}
                {...dimProps}
              />
            );
          })}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
            marginRight: "1em",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {trDims
            .filter((i) => i.properties.axis === "filter")
            .sort((l, r) => (l.properties.position < r.properties.position ? -1 : 1))
            .map((dim) => {
              return (
                <DimButton
                  allowChangeSortOrder={false}
                  icon={dim.properties.iconComponent || FAxisIcon}
                  dim={dim}
                  id={`1f${dim.id}`}
                  key={dim.id}
                  {...dimProps}
                />
              );
            })}
        </div>
      </div>
      {tags.length !== 0 && (
        <FilterTagsContainer>
          <div>
            <PopoverCustom
              {...props}
              dim={{
                id: "tags",
                properties: {
                  title: "Добавить",
                  name: "tags",
                  type: "Dictionary",
                  table: "tags",
                  axis: "filter",
                  position: 0,
                  sortOrder: "none",
                  filter: [],
                },
              }}
              treeData={tags.map(({ label, code, selected }) => ({
                name: () => label,
                icon: () => null,
                actual: () => true,
                noChildren: true,
                id: code,
                selected,
              }))}
              isTags
              toggleSelectedTagsVisibility={() => setTagsVisibility((v) => !v)}
              trigger={
                <CustomChip
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => {}}
                  variant="outlined"
                  label={<Trans id="table.select_tags" />}
                />
              }
              Component={FilterList}
              onTagsChange={handleTagChange}
              hideTopBar
            />
          </div>
          {tagsVisibility && (
            <>
              {!selectedTagsState.leftButtonIsHidden && (
                <CustomChip
                  style={{ backgroundColor: "transparent" }}
                  variant="outlined"
                  disabled={selectedTagsState.leftButtonIsDisabled}
                  onClick={handleScrollTagsLeft}
                  label={Icon("action", ArrowLeft, 18, null, { margin: 0 })}
                />
              )}
              <FilterSelectedTagsResizeContainer ref={setSelectedTagsReseizeRef}>
                <FilterSelectedTagsContainer ref={setSelectedTagsContainerRef}>
                  {selectedTags.map(({ label, code }) => (
                    <CustomChip key={code} variant="outlined" onDelete={() => handleTagRemove(code)} label={label} />
                  ))}
                </FilterSelectedTagsContainer>
              </FilterSelectedTagsResizeContainer>
              {!selectedTagsState.rightButtonIsHidden && (
                <CustomChip
                  style={{ backgroundColor: "transparent" }}
                  variant="outlined"
                  disabled={selectedTagsState.rightButtonIsDisabled}
                  onClick={handleScrollTagsRight}
                  label={Icon("action", ArrowRight, 18, null, { margin: 0 })}
                />
              )}
            </>
          )}
        </FilterTagsContainer>
      )}
      <TableView
        getIds={getIds}
        withTypeName={false}
        style={{ padding: null }}
        item={dataItem}
        singleRowSelect={singleRowSelect}
        onColumnsDef={onColumnsDef}
        cellRendererContext={{ HAxisContent }}
        isAllowEdit={isAllowEdit}
        editorOk={onEditorOk}
        selectedRowsChanged={onSelectedRowsChanged}
      />
    </Paper>
  );
};

export default DataTableView;
