import React from "react";
import LoginForm from "components/LoginForm";
import styled from "styled-components";
import Header from "newComponents/Header";

import QuizListAdmin from "Views/QuizListAdmin";
import Quiz from "Views/Quiz";

import { QuizStorage } from "storages/QuizStorage";
import { observer } from "mobx-react-lite";
import { GlobalStorage } from "storages/GlobalStorage";

function Home() {
  return (
    <Container>
      <Header />
      <LoginForm />
      {GlobalStorage.isUserAdmin ? QuizStorage.quiz ? <Quiz /> : <QuizListAdmin /> : null}
    </Container>
  );
}

const Container = styled("div")`
  padding: 4px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default observer(Home);
