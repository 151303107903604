/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react'
import UUID from 'uuid-js'
import withTheme from '@mui/styles/withTheme';

import { cellsRenderer } from './core/cells'
import { geometry } from './core/geometry'

const View = withTheme((props) => {
  const [data, setData] = useState(null)
  const [topLeft, setTopLeft] = useState({ top: 0, left: 0 })

  const visible = useRef({ column: 30, row: 30 })
  const widths = useRef([])
  const heights = useRef([])
  const scrollTo = useRef(null)
  const [update, setUpdate] = useState({})

  useEffect(() => {
    const data = {}
    data.rows = props.data
    data.uniqueId = UUID.create(4).hex

    data.colCount = 1
    data.rowCount = data?.rows?.length
    setData(data)
  }, [props.data])

  useEffect(() => {
    if (!props.scrollToId) {
      return
    }

    const ndx = props.data.findIndex(i => i.node.id === props.scrollToId)
    const n = window[`${data?.uniqueId}:scroller`]
    if (!n) {
      return
    }
    if (ndx < topLeft.top) {
      n.scrollTop = ndx * 100
    }
    if (ndx >= topLeft.top + visible.current.row) {
      scrollTo.current = ndx
    }
  }, [props.scrollToId])

  useEffect(() => {
    return () => {
    }
  }, [])

  const cellRenderer = (coord, classes, tableState, { columnIndex, key, rowIndex, style, bindData }, update) => {
    return props.rowRenderer({ onImageLoad: () => { setUpdate({}) }, key, index: rowIndex, style })
  }

  const ScrollArea = (props) => {
    const { style } = props
    const forceUpdate = null
    const classes = {}
    const m = useMemo(() => {
      return cellsRenderer(props.size.width, forceUpdate, classes, data, topLeft, visible, cellRenderer)
    },
      [data, topLeft, props.size.width]
    )

    return data ? (
      <div style={style}>
        {m}
      </div>
    ) : null
  }

  useLayoutEffect(() => {
    if (!data) {
      return
    }

    const { clientHeight, clientWidth } = window[`${data?.uniqueId}:scroller`]

    const top = topLeft.top
    const left = topLeft.left

    const xm = data?.colCount
    const ym = data?.rowCount

    const measure_reset_h = (y1, y2, x1, x2) => {
      x2 = Math.min(x2, xm)
      y2 = Math.min(y2, ym)
      for (let k = y1; k < y2; k++) {
        const rowIndex = k
        for (let i = x1; i < x2; i++) {
          const columnIndex = i
          const key = `${data.uniqueId}:${columnIndex}-${rowIndex}`
          const node = window[key]
          if (node) {
            node.style.height = 'auto'
          }
        }
      }
    }

    const measure_h = (y1, y2, x1, x2) => {
      x2 = Math.min(x2, xm)
      y2 = Math.min(y2, ym)
      for (let k = y1; k < y2; k++) {
        const rowIndex = k
        for (let i = x1; i < x2; i++) {
          const columnIndex = i
          const key = `${data.uniqueId}:${columnIndex}-${rowIndex}`
          const node = window[key]
          if (node) {
            heights.current[k] = Math.max(heights.current[k] || 0, Math.ceil(node.offsetHeight))
          }
        }
      }
    }

    const maxColNum = 1
    // количество строк которое мы будем показывать
    const maxRowNum = Math.min(ym - 1, top + visible.current.row) + 1

    heights.current = []
    measure_reset_h(top, maxRowNum, left, maxColNum)
    measure_h(top, maxRowNum, left, maxColNum)

    let pos = { x: 0, y: 0 }

    let lastVisibleRow = top
    let fullVisibleRow = top - 1
    for (let h = pos.y; lastVisibleRow < ym && h < clientHeight; lastVisibleRow++) {
      h += heights.current[lastVisibleRow] - 1
      if (h < clientHeight && fullVisibleRow < maxRowNum) {
        fullVisibleRow++
      }
    }

    geometry(xm, ym, data, heights.current, widths.current, top, maxRowNum, left, maxColNum, pos)

    props.onSectionRendered && props.onSectionRendered({ startIndex: top, stopIndex: lastVisibleRow })

    const update = visible.current.row < lastVisibleRow - top
    visible.current = { row: lastVisibleRow - top }
    if (update) {
      setTopLeft({ ...topLeft })
    } else if (scrollTo.current !== null) {
      const ndx = scrollTo.current
      const n = window[`${data?.uniqueId}:scroller`]
      if (ndx > fullVisibleRow) {
        n.scrollTop += (ndx - fullVisibleRow + 1) * 100
      } else {
        scrollTo.current = null
      }
    }
  })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: props.width, height: props.height }} >
      <div
        className={'hide-scrollbars'}
        id={`${data?.uniqueId}:scroller`}
        onScroll={(event) => {
          if (!(event.nativeEvent.target?.id === `${data?.uniqueId}:scroller`))
            return
          const pos = {
            top: Math.max(0, Math.floor(event.nativeEvent.target.scrollTop / 100)),
            left: Math.max(0, Math.floor(event.nativeEvent.target.scrollLeft / 100))
          }
          if (topLeft.top !== pos.top || topLeft.left !== pos.left) {
            setTopLeft(pos)
          }
        }}
        style={{ display: 'block', height: '100%', width: '100%', overflow: 'auto'/*, scrollBehavior: 'smooth'*/ }}
      >
        <div
          id={`${data?.uniqueId}:sizer`}
          style={{
            WebkitTransform: 'translate3d(0px, 0px, 0px)',
            display: 'flex',
            width: props.width + ((data?.colCount || 1) - 1) * 100,
            height: props.height + ((data?.rowCount || 1) - 1) * 100, fontSize: '13px'
          }}
        >
          {ScrollArea({ size: { width: props.width, height: props.height }, style: { width: 0, height: 0, top: 0, left: 0, position: 'sticky' } })}
        </div>
      </div>
    </div>

  )
})

export default View